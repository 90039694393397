@import '../../tooling.sass';

.collapse-bar {
    border-radius: 3px;
    color: #333;
    cursor: pointer;
    display: block;
    width: 100%; }

.collapse-bar-title {
    font-size: 1.5rem;
    font-weight: 600;

    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        color: get-color(primary);
        transition: .4s; } }

.collapse-container {
    padding: 20px 10px; }

.collapse-bar.is-open {
    .collapse-bar-title {
        svg {
            transform: rotateX(180deg);
            transform-style: preserve-3d; } } }
