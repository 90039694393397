.plano-cadastro-mobile {
    display: flex;
    overflow-x: auto;
    @media (min-width: 1200px) {
        display: none; }
    .item-cadastro {
        width: 100%;
        min-width: 240px;
        margin-bottom: 20px;
        margin-right: 20px; } }
