.container-valores {
    height: 240px;
    .titulo {
        text-align: center;
        color: #355180;
        margin-bottom: 20px;
        .valor {
            font-weight: bold;
            font-size: 24px; } } }



.listagem-quadrados {
    margin-top: 10px;
    @media (min-width:768px) {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    .quadrado-item {
        margin-top: 10px;
        height: 80px;
        background-color: #355180;
        color: white;
        text-align: center;
        padding: 10px;
        @media (min-width: 768px) {
            width: 32%; }
        .titulo {
            color: white;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: bold; }
        .valor {
            font-weight: bold;
            font-size: 15px; } } }
