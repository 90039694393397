.SpinnerFull {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    visibility: hidden;
    transition: .4s;

    &.is-active {
        opacity: 1;
        visibility: visible;

        .SpinnerFull-box {
            transform: translate3d(0, 0, 0); } } }

.SpinnerFull-bg {
    background-color: rgba(#000, .2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 31; }

.SpinnerFull-box {
    background-color: #ffffff;
    position: relative;
    z-index: 32;
    padding: 3rem 5rem;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(#000, .1);
    transform: translate3d(0, -2.5rem, 0);
    transition: .4s; }
