@import '../../tooling.sass';

$_spacing: .8rem;

.CadastroRepasse-gallery-listing {
    display: flex;
    flex-wrap: wrap;

    margin-left: -$_spacing;
    margin-right: -$_spacing; }

.CadastroRepasse-gallery-item {
    border-radius: 6px;

    margin: $_spacing;
    width: calc(100% / 2 - (#{$_spacing} * 2));

    position: relative;
    display: flex;
    overflow: visible;

    &:last-of-type {
        .CadastroRepasse-gallery-item-content-container {
            border: 1px solid get-color(blue);
            background-color: mix(#fff, get-color(blue), 80%); } }

    @include media(600px) {
        width: calc(100% / 5 - (#{$_spacing} * 2)); }
    @include media(800px) {
        width: calc(100% / 7 - (#{$_spacing} * 2)); }

    &.is-active {
        .CadastroRepasse-gallery-item-content-container {
            border: 1px solid get-color(blue);
            background-color: mix(#fff, get-color(blue), 80%); } } }


.CadastroRepasse-gallery-item-content-container {
    border: 1px solid #d8d8d8;
    overflow: hidden;
    border-radius: 6px;

    transition: .2s;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        border: 1px solid get-color(blue);
        background-color: mix(#fff, get-color(blue), 80%); } }

.CadastroRepasse-gallery-item-photo {
    display: flex;
    align-items: center;
    justify-content: center; }

.CadastroRepasse-gallery-add-photo {
    width: 2.5rem; }

.CadastroRepasse-gallery-item-close {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;

    display: flex;
    align-items: baseline;
    justify-content: center;
    padding: 0;

    color: #fff;
    background-color: get-color(danger);
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;

    transform: translate(50%, -50%);
    border: 2px solid get-color(danger);

    &:hover {
        background-color: #fff;
        color: get-color(danger); }

    svg {
        display: block; } }

.CadastroRepasse-gallery-input {
    position: absolute;
    height: 0.1px;
    width: 0.1px;
    left: -9999px; }

.observacao {
    color: get-color(grey);
    display: block;
    font-size: 1.35rem; }
