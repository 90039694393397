@import '../../tooling.sass';

.Breadcrumbs {
    display: flex;
    font-size: 1.4rem;

    @include media(800px) {
        font-size: 1.6rem; }

    .titulo-breadcrumb {
        color: #2B4F81;

        &.atual {
            font-weight: bold;
            color: #2b4f81; }

        &:hover {
            text-decoration: underline; } }

    .separador {
        color: #999;
        margin-left: 1rem;
        margin-right: 1rem; } }
