.IconText {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }

.IconText-icon-container {
    margin-bottom: 1rem;
    height: 2.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    img,
    svg {
        display: block; } }

.IconText-text {
    color: #555;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2; }
