.container-mensagens {
    padding: 20px;
    padding-top: 30px;
    overflow: auto;
    height: 300px;
    background-color: #fff;
    @media (min-width: 1200px) {
        height: 430px; }
    .tag-dia {
        text-align: center;
        font-size: 12px;
        color: #ababab;
        margin-bottom: 30px; }
    .audio-item {
        width: 220px;
        border-radius: 4px;
        margin-bottom: 0;
        background-color: #f0f0f0;
        padding-right: 20px; } }
