.components-container {
    border-top: 1px solid #818181;
    display: block;
    margin-top: 20px;
    padding: 50px 20px;
    width: 100%;
    .component-example {
        background-color: #f8f8f8;
        display: block;
        margin: 50px auto;
        padding: 30px;
        width: 100%;
        .component-example-title, .component-example-description {
            display: block;
            margin-bottom: 30px;
            width: 100%; }
        .components-example-item {
            background-color: #fff;
            box-shadow: inset 0px 0px 4px rgba(0,0,0,0.1);
            display: block;
            padding: 20px;
            margin-bottom: 30px; }
        .example-item-row {
            display: flex;
            flex-flow: row wrap; }
        .w-3 {
            display: block;
            margin-right: 30px;
            width: 30%; } } }

