@import '../../tooling.sass';
.TurboTab-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 26rem;
    margin: auto;
    margin-top: 2rem;

    @include media(800px) {
        margin-top: 4rem; } }

.TurboTab-image {
    margin-bottom: 2rem;
    width: 4rem;
    display: block;
    filter: saturate(0.0);
    @include media(800px) {
        width: 6.5rem; } }

.TurboTab-text {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 500;
    color: get-color(text-muted); }

.modal-turbo-content {
    text-align: center;
    .texto-desc {
        font-size: 14px;
        width: 70%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px; }
    .image-turbo {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: -20px; }
    .flex-desc-container {
        @media (min-width: 1200px) {
            display: flex;
            justify-content: space-between;
            width: 400px;
            margin: auto; }
        .desc-item {
            margin-bottom: 40px;
            &:last-child {
                margin-left: 0; }
            .titulo-desc-item {
                color: #37474f;
                font-weight: bold;
                font-size: 28px; }
            .conteudo-desc-item {
                font-size: 14px;
                text-align: center;
                width: 150px;
                font-weight: 500;
                color: #6c6c6c;
                margin: auto; } } } }

.TurboReach-container {
    width: 110%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    font-weight: bold;
    color: #335180;
    font-size: 22px;
    justify-content: center;
    .icone {
        width: 20%;
        margin-right: 1rem; }
    .Turbo-titulo {
        font-weight: normal;
        font-size: 14px; }
    .Turbo-value {
        text-align: center; }
    .Turbo-bold {
        display: flex;
        text-align: center;
        flex-direction: row;
        justify-content: space-between; } }

.TurboReach-Outside {
    margin-top: 60px;
    margin-left: -30px; }
