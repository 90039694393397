.titulo {
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        padding-bottom: 35px;
        align-items: center;
        height: 75px; }
    .nome-usuario {
        color: #3B4A52;
        font-weight: 500;
        font-size: 26px; }
    .info {
        display: flex;
        font-size: 14px;
        align-items: center;
        .proposta {
            margin-left: 5px;
            margin-right: 35px;
            color: #3b4a52;
            font-weight: 500; }
        .icone {
            color: #1E88E5;
            margin-left: 20px; } } }
