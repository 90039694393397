.StatusLabel {
    font-size: 1.4rem;
    min-width: 180px;
    text-transform: uppercase;
    padding: 4px 12px;
    border: 1px solid currentColor;
    border-radius: 4px;
    line-height: 0.7rem;
    display: inline-block;

    &.is-lite {
        border: 0;
        padding: 0; }

    &.status-code {
        padding: 0.45em 0.25em;
        text-align: center;
        font-weight: 700;
        border-radius: 20px;

        &.status-1 {
            background-color: #76c14d;
            color: #fff; }
        &.status-2 {
            background-color: #dec53a;
            color: #000; }
        &.status-3 {
            background-color: #2b4f82;
            color: #fff; }
        &.status-4 {
            color: #cd3e3e;
            color: #000; }
        &.status-5 {
            background-color: #6e6f72;
            color: #fff; }
        &.status-6 {
            color: #dec53a;
            color: #000; }
        &.status-7 {
            color: #000000; }
        &.status-8 {
            color: #000000; }
        &.status-9 {
            background-color: #76c14d;
            color: #fff; } } }
