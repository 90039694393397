@import '../../../../tooling.sass';

.main-header-menu {
    position: absolute;
    top: 6.9rem;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    background-color: get-color(primary-darker);

    @include media(800px) {
        position: relative;
        top: 0;
        width: auto;
        background-color: transparent; }

    ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px;
        height: 120vh;
        overflow: hidden;
        text-align: left;

        @include media(800px) {
            flex-direction: row;
            align-items: center;
            padding: 0;
            height: unset; } }

    li {
        color: #fff;
        cursor: pointer;
        font-size: 1.8rem;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1;

        @include media(800px, max) {
            margin: 1rem 0; }

        @include media(800px) {
            font-size: 1.5rem;
            margin-right: 1.5rem;
            font-weight: 600;
            letter-spacing: 0.03em;

            &:last-of-type {
                margin-right: 0; } }

        @include media(1000px) {
            font-size: 1.6rem;
            margin-right: 2rem; } }

    a {
        display: block;
        color: #fff;
        padding: .5rem;
        font-weight: 600;
        transition: .2s;

        &:hover {
            opacity: 0.7; } }

    .button {
        font-size: inherit;
        padding: 1rem;
        text-align: center;
        border-radius: .5rem; } }


.menu-controls-mobile-appearance {
    display: block;
    @media (min-width: 1200px) {
        display: none; } }
