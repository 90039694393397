@import '../../tooling.sass';

.CadastroRepasse-image-upload-big {
    .image-upload-label {
        color: get-color(primary);
        border: 2px dashed get-color(primary);
        border-radius: 7px;
        padding: 1rem;
        font-weight: 500;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        width: 100%;
        max-width: 32rem;
        margin: auto;
        line-height: 1.2;

        cursor: pointer;

        @include media(800px) {
            padding: 4rem 2rem; }

        &:hover {
            border-style: solid; } }

    .image-upload-input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1; }

    .image-upload-icon {
        width: 3rem;
        margin-bottom: 2rem; }

    .image-upload-title {
        font-size: 1.6rem;
        margin-bottom: 1.5rem; }

    .image-upload-subtitle {
        font-size: 1.4rem; } }


.CadastroRepasse-options-list {
    @include media(600px) {
        columns: 2; }
    @include media(800px) {
        columns: 3; } }

.CadastroRepasse-form-container {
    .label {
        font-size: 1.4rem;
        color: get-color(primary); } }

.LogoFipe {
    width: 3rem;
    object-fit: contain;
    margin-left: .5rem;
    margin-top: 0.5rem;
    margin-bottom: -2rem; }

.PorcentagemFipe {
    margin-top: 2rem; }
