@import '../../tooling.sass';

.DetalhesVeiculoTab {
    .mini-title {
        margin-bottom: 2rem; } }

.DetalhesVeiculoTab-item {
    margin-bottom: 1.5rem;

    @include media(600px) {
        margin-bottom: 3rem; }

    &:last-of-type {
        margin-bottom: 0; } }

.DetalhesVeiculoTab-item-title {
    color: get-color(primary-dark);
    font-size: 1.4rem;
    font-weight: 500;

    @include media(600px) {
        margin-bottom: 0.7rem;
        font-size: 1.3rem; } }

.DetalhesVeiculoTab-content {
    color: rgba(#000, .6);
    font-size: 1.6rem;
    font-weight: 500;

    ul,
    li {
        list-style: disc inside; }

    ul {
        @include media(600px) {
            column-count: 2; }
        @include media(800px) {
            column-count: 3; } } }


.DetalhesVeiculoTab-listing-obrigatorio {
    margin-bottom: 2rem;

    @include media(700px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0; } }
