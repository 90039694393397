.mensagem-item-container {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    margin-right: auto;
    margin-bottom: 20px;
    &.right {
        margin-left: auto;
        flex-direction: row-reverse;
        .foto-usuario {
            margin-right: 0;
            margin-left: 10px; }
        .horario-mensagem {
            margin-left: 0;
            margin-right: 5px; }
        .conteudo-mensagem {
            background-color: #1E88E5;
            color: #fff;
            border-color: #1E88E5; } }
    .foto-usuario {
        width: 20px;
        margin-right: 10px;
        overflow: hidden;
        @media (min-width: 768px) {
            width: 30px; }
        img {
            margin: 0 auto;
            height: auto;
            width: 150%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%;
            object-fit: cover; } }
    .conteudo-mensagem {
        padding: 10px;
        background-color: #d8d8d8;
        width: 250px;
        border-radius: 6px;
        border: 1px solid #ababab;
        font-size: 14px; }
    .horario-mensagem {
        margin-left: 5px;
        font-size: 8px; } }
