.action {
    display: flex;
    // flex-flow: row wrap
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    color: #333;
    transition: .2s ease-in-out;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        opacity: 0.7; }

    &.action-link {
        font-weight: 600;
        color: #335180;
        font-size: 1.6rem;

        .text {
            font-size: 1.6rem; } }

    .icon {
        + .text {
            margin-left: 8px; } }

    .text {
        font-size: 1.4rem;
        color: #335180;
        line-height: 1.2;

        + .icon {
            margin-left: 8px; } } }
