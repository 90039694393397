.container-finish-cadastro {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .foto {
        margin-bottom: 20px; }
    .subtitulo-cadastro {
        margin-bottom: 30px; } }
