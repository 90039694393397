@import '../../tooling.sass';

.RepasseItemHome {
    border-bottom: 1px solid #d8d8d8;
    padding: 2rem 0;

    @include media(1200px) {
        display: flex;
        border-bottom: 0;
        padding-bottom: 0; }

    &:first-of-type {
        @include media(700px, max) {
            padding-top: 0; }
        @include media(1200px) {
            padding-top: 0; } }

    &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;

        @include media(1200px) {
            margin-bottom: 0; } }

    .RepasseInfo {
        @include media(1200px, max) {
            margin-bottom: 2rem; }

        @include media(1200px) {
            width: calc(24rem + 4rem); } }


    .RepasseValores {
        padding-bottom: 2rem;

        @include media(1200px) {
            border-bottom: 0;
            padding-bottom: 0;
            border-right: 1px solid #d8d8d8;
            padding-right: 4rem;
            width: calc(24rem + 4rem); } }

    .InteressadosContainer {
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 1rem;
        max-width: 36rem;

        @include media(1200px) {
            margin-top: 0;
            margin-bottom: 0;
            width: calc(40rem + 4rem);
            padding-left: 4rem;
            padding-right: 2rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; } } }


.RepasseItemHome-image-container {
    margin-bottom: 20px;

    @include media(1200px) {
        width: 20rem;
        height: 20rem;
        margin-right: 3rem;
        margin-bottom: 0; }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
