.plano-cadastro-mobile-item {
    width: 100%;
    text-align: center;
    padding: 10px;
    border: 1px solid #37474f;
    border-radius: 6px;
    .titulo-plano {
        color: #37474f;
        font-weight: 600;
        font-size: 14px; }
    .valor-plano {
        font-size: 22px;
        font-weight: bold;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 10px;
        .tag-mes {
            font-size: 14px; } }
    .desc-item {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        text-align: left;
        .titulo-item {
            font-weight: bold; }
        .span-consulta {
            font-size: 8px; } } }
