@import '../../tooling.sass';

.header-page {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 10px;

    @media (min-width: 767px) {
        margin-bottom: 25px; } }

.header-page-actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    .header-page-action {
        margin-right: 20px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        @media (min-width: 767px) {
            margin-right: 30px;
            margin-bottom: 0; }

        &:last-child {
            margin-right: 0; }

        .spinner {
            margin-right: 1rem; } } }

.repasse-resume {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    padding: 20px;

    .repasse-resume-main-info {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: flex-start;

        @media (min-width: 900px) {
            width: calc(100% - 21rem);
            flex-wrap: nowrap; } }

    .repasse-image {
        width: 100%;
        margin-bottom: 1.5rem;

        img {
            display: block; }

        @media (min-width: 900px) {
            margin-bottom: 0;
            margin-right: 3rem;
            width: 11rem; } }

    .repasse-content-info {
        margin-bottom: 2rem;

        @media (min-width: 900px) {
            margin-right: 3rem;
            margin-bottom: 0;
            width: 21rem; }

        .icon-info {
            margin-bottom: 0;

            .icon-text {
                font-size: 14px; } } }

    .repasse-other-info {
        margin-bottom: 2rem;

        @media (min-width: 900px) {
            margin-bottom: 0;
            width: 23rem;
            padding-right: 2rem; }

        .StatusLabel {
            margin-bottom: 1.5rem; } }

    .repasse-telefone-info {
        @media (min-width: 900px) {
            width: 20rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 3rem; }

        .ActionButton {
            width: 100%; } }

    .repasse-resume-values {
        width: 100%;

        @media (min-width: 900px) {
            width: 21rem; } }

    .Seller {
        margin-bottom: 1.5rem; } }

// Swiper
.ModalGaleria-SwiperGallery {
    .swiper-pagination {
        background-color: rgba(#000, .6);
        color: #fff;
        font-size: 1.5rem;
        line-height: 1;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 6rem;
        margin: auto;
        padding: 0.6rem 0;
        border-radius: 3px 3px 0 0;

        left: 0;
        right: 0;
        bottom: 0; }

    .swiper-button-prev,
    .swiper-button-next {
        background-image: none;
        background-color: rgba(#000, .5);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6rem;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;

        @include media(800px) {
            width: 3.5rem;
            height: 7rem; }

        img {
            width: 1.5rem;

            @include media(800px) {
                width: 1.7rem; } } }

    .swiper-button-next {
        right: 0;
        border-radius: 5px 0 0 5px; }

    .swiper-button-prev {
        left: 0;
        border-radius: 0 5px 5px 0; } }


// .ModalGaleria-SwiperGallery-item

.ModalGaleria-SwiperGallery-image {
    width: 100%; }

.ModalGaleria-SwiperThumbnail {
    margin-top: 1rem;

    .swiper-button-prev,
    .swiper-button-next {
        background-image: none;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        height: 101%;

        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;

        @include media(800px) {
            width: 4rem; }

        img {
            width: 1.2rem;

            @include media(800px) {
                width: 1.7rem; } } }

    .swiper-button-next {
        right: 0; }

    .swiper-button-prev {
        left: 0; } }

.ModalGaleria-SwiperThumbnail-item {
    cursor: pointer;
    border: 3px solid transparent;
    border-radius: 4px;

    &.swiper-slide-active {
        border-color: get-color(blue); } }


.RepasseTab-Informacoes {
    padding: 2rem;

    @include media(1100px) {
        display: flex;
        flex-wrap: wrap; }

    .DetalhesVeiculoTab {
        padding-bottom: 3rem;
        border-bottom: 1px solid #d8d8d8;

        @include media(1100px) {
            border-bottom: 0;
            // border-right: 1px solid #d8d8d8
            padding-right: 5rem;
            padding-bottom: 0;
            width: calc(100% - 35rem); } }

    .TurboTab {
        margin-top: 3rem;
        margin-bottom: 3rem;

        padding-bottom: 3rem;
        border-bottom: 1px solid #d8d8d8;

        @include media(1100px) {
            border-bottom: 0;
            margin-top: 0;
            padding-left: 3rem;
            padding-right: 2rem;
            padding-bottom: 0;
            width: 35rem;
            border-left: 1px solid #d8d8d8; } } }



// * GaleriaFotosTab *****

.GaleriaFotosTab {
    width: 100%;

    @include media(1100px) {
        margin-top: 5rem; }

    .mini-title {
        margin-bottom: 2rem; } }


.GaleriaFotos-listing {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem; }

.GaleriaFotos-item {
    width: calc(100% / 2);
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 1rem;
    cursor: pointer;

    @include media(600px) {
        width: calc(100% / 3); }
    @include media(800px) {
        width: calc(100% / 5); }
    @include media(1000px) {
        width: calc(100% / 7); }
    @include media(1200px) {
        width: calc(100% / 10); } }
