.container-vendas {
    .linha-topo-vendas {
        padding: 20px;
        padding-bottom: 0;
        padding-top: 0;
        font-size: 14px;
        margin-bottom: 20px;
        @media (min-width: 768px) {
            display: flex;
            justify-content: space-between; }
        .filtro-select {
            width: 60px; } } }
