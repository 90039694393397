@import '../../tooling.sass';

.InteressadosContainer.no-turbo {
    .InteressadosContainer-text {
        opacity: 0.5; }

    .button {
        border: 2px solid #e4eaed !important;
        color: #74909e !important;

        small {
            opacity: 0.5; } } }

.InteressadosContainer-data {
    color: #355180;

    @include media(370px) {
        display: flex;
        justify-content: center; } }

.InteressadosContainer-data-item {
    text-align: center;
    color: #355180;
    margin-top: 1rem;
    margin-bottom: 2rem;

    @include media(370px) {
        padding: 0 1rem;
        margin-bottom: 0; } }

.InteressadosContainer-number {
    font-weight: bold;
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 0.7rem; }

.InteressadosContainer-icon-container {
    height: 3rem;
    margin-bottom: 0.7rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain; } }

.InteressadosContainer-text {
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: 500; }

.InteressadosContainer-button-container {
    width: 100%;
    margin-top: 20px; }
