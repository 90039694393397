@import '../../tooling.sass';

.BotaoCarregarMais-container {
    // margin: 2rem auto
    text-align: center;

    &.small {

        .BotaoCarregarMais {
            width: auto;
            padding-left: 3rem;
            padding-right: 3rem; } }

    .BotaoCarregarMais {
        background-color: get-color(primary);
        color: #fff;

        display: inline-block;
        width: 90%;
        border-radius: 3rem;
        padding: .7rem 1rem;

        font-weight: 600;
        font-size: 1.4rem;

        transition: 0.3s;
        cursor: pointer;

        &:hover {
            color: get-color(primary);
            background-color: #fff; } } }
