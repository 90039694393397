.cadastro-usuario-quatro {
    .area-cadastro {
        margin-bottom: 60px;
        .info-item {
            margin-bottom: 15px;
            @media (min-width: 1200px) {
                width: 24%;
                margin-right: 1%; }
            .info-titulo {
                color: #456997;
                font-size: 12px; }
            .info-conteudo {
                font-size: 14px; } } }
    .icon-pencil {
        color: #0F94D3;
        font-size: 16px;
        margin-left: 10px; }
    .subtitulo {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 10px;
        letter-spacing: 2px; }
    .topo {
        display: flex;
        align-items: center;
        .foto {
            overflow: hidden;
            position: relative;
            width: 80px;
            height: 80px;
            border: 2px solid get-color(primary);
            margin-right: 10px;
            img {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-color: #e8e8e8; } }
        .info-lateral {
            .tipo {
                font-size: 12px; }
            .nome {
                font-weight: 500;
                font-size: 18px; } } }
    .linha-info {
        margin-top: 20px;
        margin-bottom: 20px;
        @media (min-width: 1200px) {
            width: 70%;
            display: flex;
            flex-wrap: wrap; } } }
