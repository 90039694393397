@import '../../tooling.sass';

.PageLogin {
    padding: 2rem 0;

    min-height: 100vh;

    .button {
        font-size: 1.4rem; } }

.PageLogin-card {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(#000, .1);
    border-radius: 5px;
    padding: 3rem 2rem;
    max-width: 42rem;
    margin: auto; }

.PageLogin-logo {
    display: block;
    margin: auto;
    padding: 0 1rem; }

.PageLogin-step-title {
    text-align: center;
    font-weight: 500;
    font-size: 2rem;
    margin: 4rem 0 2rem;
    color: get-color(text); }

.PageLogin-step-subtitle {
    color: get-color(text-muted);
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 1.4rem;
    line-height: 1.3; }

.PageLogin-form {
    .forgot-password {
        text-align: center;
        font-size: 1.4rem;
        color: get-color(primary);
        text-decoration: underline;
        display: block;
        margin-top: 1rem;
        margin-bottom: 1rem;
        line-height: 1;
        cursor: pointer; }

    .checkbox-group {
        display: flex;
        align-items: center; } }

.PageLogin-register-container {
    margin-top: 2.5rem;
    padding-top: 2.5rem;
    border-top: .1rem solid #ddd;

    @include media(1200px) {
        margin-top: 3rem;
        padding-top: 3rem; } }

.PageLogin-register-title {
    color: get-color(text);
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;

    margin: auto;
    margin-bottom: 1.5rem;
    max-width: 25rem;

    @include media(1200px) {
        margin-bottom: 2rem; } }

.PageLogin-resend-code {
    color: get-color(text-muted);
    font-size: 1.4rem;

    margin-top: 1.5rem;
    line-height: 1.2;
    text-align: center;

    button {
        color: get-color(blue); } }

.Modal-forgot-password {
    text-align: center;

    .Modal-content {
        margin: 2rem 0 0; } }

.Modal-forgot-password__text {
    margin-bottom: 3rem;
    line-height: 1.2;
    font-size: 1.6rem;
    color: get-color(text-muted);
    font-weight: 600; }
