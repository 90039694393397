@import '../../tooling.sass';

.ExtratoDash {
    background-image: linear-gradient(to right, #36bbf6, #0e85e8);
    color: rgba(#fff, .8);
    margin: 2rem -2rem; }

.ExtratoDash-container {
    max-width: 110rem;
    margin: auto;
    padding: 3rem 2rem;

    display: flex;
    flex-direction: column;

    @include media(700px, max) {
        align-items: center; }

    @include media(700px) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline; }
    @include media(1000px) {
        padding: 2.5rem 2rem;
        justify-content: center; } }

.ExtratoDash-item {
    text-align: center;

    margin-bottom: 2rem;

    &:last-of-type {
        margin-bottom: 0; }

    @include media(700px) {
        width: calc(100% / 3);
        padding: 0 2rem; }
    @include media(1000px) {
        max-width: 20rem;
        width: calc(100% / 5);
        margin-bottom: 0; } }

.ExtratoDash-value {
    font-size: 2.8rem;
    font-weight: bold; }

.ExtratoDash-name {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.3; }

// .ExtratoList

.ExtratoRow {
    display: flex;
    flex-direction: column;

    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;

    @include media(800px) {
        flex-direction: row;
        align-items: center;
        margin-bottom: 1.2rem;
        padding: 0 1rem 1.2rem; } }

.ExtratoRow-item {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1rem;

    @include media(800px) {
        margin-bottom: 0; }

    &:last-of-type {
        margin-bottom: 0; }

    &:nth-of-type(1) {
        @include media(800px) {
            width: 15rem;
            margin-right: 2rem; } }
    &:nth-of-type(2) {
        @include media(800px) {
            width: 30rem;
            margin-right: 2rem; } }
    &:nth-of-type(3) {
        @include media(800px) {
            margin-left: auto; } } }

.ExtratoRow-name {
    line-height: 1.2; }

.ExtratoRow-link {
    color: get-color(primary);
    font-size: 1.4rem;
    line-height: 1.2;
    cursor: pointer; }

.ExtratoHeader {
    display: flex;
    padding: 0 1rem;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: #777;

    @include media(800px, max) {
        display: none; } }

.ExtratoHeader-item {
    &:nth-of-type(1) {
        @include media(800px) {
            width: 15rem;
            margin-right: 2rem; } }
    &:nth-of-type(2) {
        @include media(800px) {
            width: 30rem;
            margin-right: 2rem; } }
    &:nth-of-type(3) {
        @include media(800px) {
            margin-left: auto; } } }


.ExtratoResumo {
    margin-top: 3rem;

    @include media(800px) {
        padding: 0 2rem;
        margin-top: 4rem; } }

.ExtratoResumo-item {
    font-weight: 600;
    line-height: 1.2;

    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .ExtratoResumo-value {
        flex: none;
        margin-left: 2rem; } }

.select-date {
    position: relative;

    .select-date__select {
        font-size: 1.6rem;
        font-weight: 500;
        appearance: none;
        border: 0;
        background-color: transparent;
        padding-right: 3rem;
        cursor: pointer; }

    .select-date__icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        font-size: 1.5rem;
        pointer-events: none; } }
