@import '../../tooling.sass';

.Modal-GestaoEquipe {
    font-weight: 500;
    color: #555; }

.message-error {
    width: '100%';
    .result__title {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        color: get-color(primary); } }

.gestao-equipe-header {
    display: flex;
    justify-content: center;
    border-bottom: .1rem solid #d8d8d8;
    padding: 1rem 2rem 2rem;

    @include media(1200px) {
        justify-content: space-between; }


    .gestao-equipe-header-item {
        color: get-color(text-blue-grey);
        font-size: 1.6rem;

        &:nth-of-type(1) {
            @include media(1200px) {
                flex: 0 1 31rem; } }

        &:nth-of-type(2),
        &:nth-of-type(3) {
            @include media(1200px) {
                flex: 0 1 17rem;
                text-align: center; } }

        &:nth-of-type(3) {
            @include media(1200px) {
                margin-right: auto; } }

        &:not(.is-dropdown) {
            @include media(1200px, max) {
                display: none; } }

        &.is-dropdown {
            cursor: pointer;
            display: flex; }

        .dropdown-icon {
            color: #2B4F81;
            margin-left: 1.2rem; } } }

.form-select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0.5rem;

    .checkbox-group {
        padding-left: 2rem;
        padding-right: 2rem; } }

.form-search {
    width: 20rem;
    display: flex;
    justify-content: center;

    .button {
        margin-top: 2.5rem;
        height: 2.5rem; } }

.convite-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media(1200px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center; } }

.convite-buttons-container {
    display: flex;
    flex-direction: row;

    @include media(1200px, max) {
        margin-top: 2rem; } }
