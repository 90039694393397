.alterar-foto-cadastro {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .foto {
        overflow: hidden;
        position: relative;
        width: 100px;
        height: 100px;
        border: 2px solid get-color(primary);
        border-radius: 50%;
        margin-right: 40px;
        @media (min-width: 1200px) {
            margin-right: 50px; }
        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: #e8e8e8; } }
    .container-botoes {
        @media (min-width: 1200px) {
            display: flex; }
        @media (max-width: 1199px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start; }
        .botao-item {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
            color: #37474f;
            cursor: pointer;
            @media (min-width: 1200px) {
                margin-right: 50px; }
            @media (max-width: 1199px) {
                display: flex;
                flex-direction: row-reverse; }
            .icone {
                margin-left: 10px;
                font-size: 16px;
                @media (max-width: 1199px) {
                    margin-right: 10px; }
                &.icone-mudar {
                    color: #0F94D3; }
                &.icone-lixo {
                    color: #D0021B; } } } } }
