.plano-cadastro-desktop-item {
    width: 18.75%;
    text-align: center;
    .area-info {
        padding: 20px;
        border-bottom: 1px solid #d8d8d8;
        &:last-child {
            border: 0; }
        .info-item {
            font-size: 14px;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0; } } }
    .nome-plano {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px; }
    .valor-plano {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        .tag-mes {
            font-size: 14px; } } }
