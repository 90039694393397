.usuario-bar {
    margin-left: 20px;
    position: relative;

    .nome-usuario {
        display: flex;
        align-items: center;
        color: white;

        .nome {
            padding-right: 10px;
            font-size: 1.5rem;
            letter-spacing: 0.03em;
            cursor: pointer; }

        .icone-dropdown {
            margin-right: 20px;
            cursor: pointer; } }
    .botao-novidades {
        border: 1px solid #ffffff;
        padding: 5px;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 30px;
        padding-left: 15px;
        padding-right: 15px;
        cursor: pointer;
        transition: 0.4s;
        font-weight: 600;
        &:hover {
            background-color: #fff;
            color: #335180; } }
    .swiper-container {
        position: relative;
        padding-bottom: 40px;
        margin-left: -20px;
        margin-right: -20px;
        margin-top: -16px;
        .swiper-pagination {
            bottom: 0; } } }

.modal-usuario {
    width: 200px;
    position: absolute;
    bottom: -190px;
    right: 6rem;
    text-align: {}
    background-color: #fff;
    z-index: 999900;
    height: 190px;
    border-radius: 4px;
    box-shadow: 0px 6px 20px 0px rgba(0,0,0,0.3);
    .icone-caret {
        position: absolute;
        top: -16px;
        right: 2px;
        font-size: 22px;
        color: white; }
    .titulo-modal {
        padding: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #d8d8d8;
        background-color: #fafafa;
        color: #37474f;

        .botao-fechar {
            font-weight: bold;
            color: #1E88E5;
            cursor: pointer; } }

    .listagem-dropdown {
        height: 100%;

        .dropdown-item {
            text-align: left;
            padding: 10px;
            border-bottom: 1px solid #dfdfdf;
            cursor: pointer;
            color: #416592;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;

            &:hover {
                opacity: 0.7; } }

        a {
            color: inherit; } } }

.swiper-button-prev-novidades {
    position: absolute;
    width: 18px;
    left: 20px;
    bottom: 00px;
    margin-top: 30px;
    z-index: 9999; }
.swiper-button-next-novidades {
    position: absolute;
    width: 18px;
    right: 20px;
    bottom: 00px;
    margin-top: 30px;
    z-index: 9999; }

.slide-novidades-item {
    width: 100%;
    img {
        width: 100%;
        object-fit: cover; } }
