@import '../../tooling.sass';

.GestaoRepasseHeader {
    border-bottom: .1rem solid #d8d8d8;

    @include media(1200px) {
        padding-bottom: 2rem; } }

.GestaoRepasseHeader-title {
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 2rem;
    line-height: 1.2;

    @include media(1200px) {
        margin-bottom: 1rem; } }

.GestaoRepasseHeader-search-form {
    display: flex;
    flex-direction: column;

    @include media(1200px) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between; }

    .form-group {
        @include media(1200px) {
            margin-bottom: 0;
            margin-right: 2rem; }

        &:last-of-type {
            @include media(1200px) {
                margin-right: 0; } }

        > .label {
            color: get-color(primary);
            font-size: 1.4rem; }

        &.--busca,
        &.--usuario,
        &.--vendedor {
            @include media(1200px) {
                flex: 0 0 20.5rem;
                width: 20.5rem; } }

        &.--status {
            @include media(1200px) {
                display: flex;
                flex-wrap: wrap; }

            > .label {
                width: 100%; }

            .checkbox-group {
                @include media(1200px) {
                    margin-right: 2rem;
                    height: 4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center; }

                &:last-of-type {
                    margin-right: 0; } } }

        &.--button {
            @include media(1200px) {
                width: 8rem; }

            .button {
                width: 100%; } } } }

.RepassesListing {
    display: flex;
    flex-wrap: wrap;

    .result__title {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        color: get-color(primary);

        .result__title-obs {
            color: get-color(grey);
            display: block;
            font-size: 1.35rem; } } }

.RepassesItem {
    padding: 2rem 0;
    border-bottom: .1rem solid #d8d8d8;
    width: 100%;

    @include media-range(700px, 1200px) {
        width: 50%;

        &:nth-of-type(odd) {
            padding-right: 2rem;
            border-right: .1rem solid #d8d8d8; }

        &:nth-of-type(even) {
            padding-left: 2rem; } }

    @include media(1200px) {
        display: flex;
        justify-content: space-between;
        width: 100%; }

    &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0; } }

.RepassesItem-image-container {
    display: block;
    margin-bottom: 2rem;
    width: 100%;

    @include media-range(400px, 1200px) {
        height: 30rem; }

    @include media(1200px) {
        width: 11rem;
        height: 11rem;
        margin-bottom: 0; } }

.RepassesItem-image {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.RepassesItem-info-car {
    @include media(1200px); }

.RepassesItem-info-availability {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @include media(1200px) {
        margin-bottom: 0; }

    .StatusLabel {
        margin-top: auto; } }

.RepassesItem-info-values {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include media(1200px) {
        margin-bottom: 0;
        margin-top: 0; } }

.RepassesItem-info-interested {
    @include media(1200px); }

.RepassesItem-info-turbo {
    @include media(1200px, max) {
        margin-top: 2rem; }

    @include media(1200px) {
        display: flex;
        align-items: center;
        justify-content: center;

        padding-right: 2rem; } }


.RepassesListing.--gestao-repasses {
    .RepassesItem {
        @include media(1200px) {
            justify-content: flex-start; } }

    .RepassesItem-image-container {
        @include media(1200px) {
            margin-right: 3rem; } }

    .RepassesItem-info-car {
        @include media(1200px) {
            width: 35rem;
            margin-right: 5rem; } }

    .RepassesItem-info-values {
        @include media(1200px) {
            width: 20rem;
            margin-right: 5rem; } }

    .RepassesItem-info-interested {
        @include media(1200px) {
            width: 18rem;
            margin-right: 3rem; } }

    .RepassesItem-info-turbo {
        @include media(1200px) {
            margin-left: auto; } } }
