@import '../../tooling.sass';

.InteressadoRow-listing {
    @include media(600px) {
        display: flex;
        flex-wrap: wrap; }

    .InteressadoRow {
        @include media-range(600px, 800px) {
            width: calc(100% / 2);

            &:nth-of-type(odd) {
                padding-right: 2rem;
                border-right: .1rem solid #d8d8d8; }

            &:nth-of-type(even) {
                padding-left: 2rem; } }

        @include media-range(800px, 1000px) {
            width: calc(100% / 3);

            padding-right: 2rem;
            padding-left: 2rem;
            border-right: .1rem solid #d8d8d8;

            &:nth-of-type(3n) {
                border-right: 0; } }

        @include media-range(1000px, 1200px) {
            width: calc(100% / 5);

            padding-right: 2rem;
            padding-left: 2rem;
            border-right: .1rem solid #d8d8d8;

            &:nth-of-type(5n) {
                border-right: 0; } } } }


.TabContentInteressados {
    padding: 1rem 2rem 0;

    .container-empty-list {
        margin: 20px; } }
