@import '../../tooling.sass';

.RepasseTurboStatus {
    font-size: 1.4rem;
    line-height: 1;
    min-width: 10rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }

.RepasseTurboStatus-image-container {
    width: 2.5rem;
    margin-bottom: 1rem; }

.RepasseTurboStatus-image {
    width: 100%;
    display: block; }

.RepasseTurboStatus-status {
    margin-bottom: 0.5rem;
    color: #78909C; }

.RepasseTurboStatus-cta {
    font-weight: bold;
    color: get-color(blue); }
