@import '../../tooling.sass';

.CadastroRepasse-VehicleSelection {
    @include media(800px) {
        max-width: 80rem;
        margin: auto;
        padding: 6rem 0 8rem; }

    @include media(1200px) {
        padding: 8rem 0 10rem; } }

.VehicleSelection__listing {
    @include media(800px) {
        display: flex;
        justify-content: space-between;
 } }        // align-items: baseline

.VehicleSelection__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 6rem;

    &:last-of-type {
        margin-bottom: 0;
        border-right: 0; }

    @include media(800px) {
        width: calc(100% / 3);
        margin-bottom: 0;
        border-right: 2px solid #ececec;
        padding: 0 3rem; } }

.VehicleSelection__image-container {
    width: 10rem;
    margin-bottom: 2rem; }

.VehicleSelection__image {
    height: 100%;
    width: 100%;
    object-fit: contain; }

.VehicleSelection__description {
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 1.5rem; }

.VehicleSelection__action-container {
    margin-top: auto; }
