a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

ul, li {
  list-style-type: none; }

html {
  font-family: sans-serif;
  font-size: 62.5%;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

*, *:before, *:after {
  box-sizing: border-box; }

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

body {
  padding: 0;
  font-size: 1.6rem; }

input,
textarea,
select,
a,
button {
  outline: none;

  &:focus {
    outline: none; } }
a {
  text-decoration: none; }


h1,h2,h3,h4,h5,h6,p {
  margin: 0; }

img {
  display: block;
  max-width: 100%; }
