@import '../../tooling.sass';

.home-container {
    background-color: #f5f5f5;
    min-height: 60vh;
    padding: 10px;
    padding-top: 120px;

    @include media(1200px) {
        padding-top: 80px; }

    .conteudo-home {
        max-width: 1200px;
        padding: 20px;
        margin: auto;

        @include media(1200px) {
            padding: 50px; }

        .linha-topo {
            @include media(1200px) {
                display: flex;
                justify-content: space-between;
                align-items: center; } }

        .titulo-card {
            color: #2B4F82;
            font-size: 18px; }

        .subtitulo-detalhe {
            font-size: 12px;
            margin-bottom: 20px;
            color: #ababab; } } }

.flex-container-bottom {
    @include media(1200px) {
        display: flex;
        justify-content: space-between; }

    .coluna-esquerda {
        margin-bottom: 2rem;

        @include media(1200px) {
            width: calc(30% - 1.5rem);
            margin-bottom: 0; } }

    .coluna-direita {
        @include media(1200px) {
            width: calc(70% - 1.5rem); } } }

.container-repasses-home {
    @include media(700px) {
        display: flex;
        flex-wrap: wrap; }

    .RepasseItemHome {
        @include media-range(700px, 1200px) {
            width: 50%;

            &:nth-of-type(odd) {
                padding-right: 2rem;
                border-right: 1px solid #d8d8d8; }
            &:nth-of-type(even) {
                padding-left: 2rem; } }

        @include media(1200px) {
            width: 100%; } } }
