@import '../../tooling.sass';

.PageCadastroRepasse {
    .CardConteudo {
        @include media(800px) {
            padding: 4rem; } } }

.CadastroRepasse-box {
    @include media(1200px) {
        display: flex; } }

.CadastroRepasse-progress {
    color: get-color(primary);
    font-weight: 600;

    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #d8d8d8;

    @include media(1200px, max) {
        padding-bottom: 5rem; }

    @include media(1200px) {
        border-bottom: 0;
        border-right: 1px solid #d8d8d8;
        padding-top: 4rem;
        padding-bottom: 0;
        padding-right: 4rem;
        margin-bottom: 0;
        width: 30rem; } }

.CadastroRepasse-content {
    @include media(1200px) {
        padding: 2rem 4rem;
        width: calc(100% - 30rem); } }

.CadastroRepasse-title {
    color: get-color(primary);
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 4rem; }

.CadastroRepasse-subtitle {
    color: get-color(text-muted);
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: 600;
    margin-top: 4rem;
    margin-bottom: 3rem; }

.CadastroRepasse-navigation {
    display: flex;
    flex-direction: column;

    @include media(800px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between; }

    .button {
        width: 100%;
        margin-bottom: 1rem;

        @include media(800px) {
            width: auto;
            margin-bottom: 0; }

        &:last-of-type {
            margin-bottom: 0; } }

    .navigation-icon {
        margin: 0 1rem; } }

// ** Steps **

.CadastroRepasse {
    .step-1 {} }
