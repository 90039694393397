@import '../../tooling.sass';

.InfoItem {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;

    &:last-of-type {
        margin-right: 0; } }

.InfoItem-valor {
    color: #335180;
    font-weight: bold;
    font-size: 2.8rem;
    margin-right: .5rem;
    line-height: 1; }

.InfoItem-texto {
    color: get-color(text-muted);
    font-size: 1.2rem;
    line-height: 1; }
