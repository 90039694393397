@import '../../tooling.sass';

.InteressadoHeader {
    display: flex;
    justify-content: center;
    border-bottom: .1rem solid #d8d8d8;
    padding: 1rem 2rem 2rem;

    @include media(1200px) {
        align-items: center;
        justify-content: flex-start; } }

.InteressadoHeader.is-lite {
    padding: 0 0 .5rem;
    justify-content: flex-start; }

.InteressadoHeader-item {
    color: #37474F;
    font-size: 1.6rem; }

.InteressadoHeader-counter {
    display: flex;
    align-items: baseline;

    .InteressadoHeader-counter-icon {
        width: 2.2rem;
        margin-right: 1rem; }

    .InteressadoHeader-counter-number {
        color: get-color(primary);
        font-size: 2.2rem;
        font-weight: bold;
        margin-right: 0.7rem; }

    .InteressadoHeader-counter-text {
        color: #78909C;
        font-size: 1.5rem; } }

.InteressadoHeader-item {
    &:nth-of-type(1) {
        @include media(1200px) {
            width: calc(34.5rem + 5.5rem); } }

    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
        @include media(1200px, max) {
            display: none; }

        @include media(1200px) {
            text-align: center;
            width: 18rem; } } }
