@import "../../tooling.sass";

.padding-header-full {
    padding-top: 109px;

    @include media(800px) {
        padding-top: 194px; }

    @include media(1200px) {
        padding-top: 198px; } }

.padding-header {
    padding-top: 69px;

    @include media(800px) {
        padding-top: 135px; }

    @include media(1200px) {
        padding-top: 138px; } }
