.cadastro-usuario-tres {
    .subtitulo {
        font-weight: 500;
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 10px;
        letter-spacing: 2px; }

    .botoes-container {
        margin-top: 30px;
        @media (min-width: 1200px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 45px; }
        .botao-item {
            margin-bottom: 20px;
            height: 30px; } } }
