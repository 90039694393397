.TitleWSelect {
    @include media(800px) {
        display: flex; } }

.TitleWSelect-title-container {
    @include media(800px) {
        width: calc(100% - 30rem); } }

.TitleWSelect-select-container {
    @include media(800px) {
        width: 30rem; } }
