.cadastro-usuario-dois {
    width: 100%;
    @media (min-width: 1200px) {
        width: 80%; }
    .area-cadastro {
        margin-bottom: 60px; }
    .subtitulo {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 10px;
        letter-spacing: 2px; }
    .texto-desc {
        font-size: 14px;
        margin-bottom: 10px; }
    .input-linha {
        @media (min-width: 1200px) {
            display: flex;
            justify-content: space-between; }
        .input-item {

            @media (min-width: 1200px) {
                width: 48%; } }
        &.logradouro {
            .input-item {
                @media (min-width: 1200px) {
                    width: 28%; }
                &:first-child {
                    @media (min-width: 1200px) {
                        width: 68%; } } } } }

    .label-checkbox {
        font-size: 14px;
        .link-termos-uso {
            color: #37474f;
            font-weight: 500;
            text-decoration: underline; } }

    .botoes-container {
        margin-top: 30px;
        @media (min-width: 1200px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 45px; }
        .botao-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
            height: 30px; } } }
