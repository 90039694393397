
.descricao-plano-container {
    .titulo-plano {
        color: #37474f;
        font-weight: 600;
        font-size: 22px; }
    .descricao-plano {
        font-size: 14px; }
    .destaque-bold {
        font-weight: bold; }

    .container-botao {
        text-align: center; } }
