.Button {
    appearance: none;
    border: 0;
    border-radius: 3px;
    margin: 0 5px 20px;
    padding: 5px 25px;
    color: white;
    min-width: 200px;
    &.primary {
        background-color: #2c3e50;
        &-outline {
            background-color: transparent;
            border: 1px solid #2c3e50;
            color: #2c3e50; } }

    &.cancel {
        background-color: #bdc3c7;
        &-outline {
            background-color: transparent;
            border: 1px solid #bdc3c7;
            color: #bdc3c7; } }

    &.success {
        background-color: #27ae60;
        &-outline {
            background-color: transparent;
            border: 1px solid #27ae60;
            color: #27ae60; } }

    &.error {
        background-color: #c0392b;
        &-outline {
            background-color: transparent;
            border: 1px solid #c0392b;
            color: #c0392b; } }

    &.warning {
        background-color: #e67e22;
        &-outline {
            background-color: transparent;
            border: 1px solid #e67e22;
            color: #e67e22; } } }
