@import '../../tooling.sass';

.EstoqueContainer {
    @include media(1200px) {
        display: flex; } }


.EstoqueContainer-left {
    .GestaoRepasseHeader-title {
 } }        // padding-bottom: 4rem

.EstoqueContainer-Seller-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.2;
    padding: 4rem 0;

    @include media(1200px) {
        width: 30rem;
        margin-right: 3rem;
        border-right: 1px solid #eee; }

    .Seller-image {
        width: 7rem;
        height: 7rem;
        margin-bottom: 2rem; }

    .Seller-name {
        color: #333;
        margin-top: 1rem;
        font-size: 1.4rem;
        margin-bottom: 0.7rem; }

    .Seller-address {
        color: get-color(primary);
        font-size: 1.4rem;
        margin-bottom: 0.7rem; }

    .Seller-repasses {
        font-size: 1.4rem;
        color: rgba(get-color(primary), .6);

        span {
            color: rgba(get-color(primary), 1);
            font-weight: bold; } } }

.EstoqueContainer-right {
    @include media(1200px) {
        width: calc(100% - 30rem); }

    .RepassesItem-image-container {
        @include media(1200px) {
            margin-right: 3rem; } }

    .RepassesItem-info-car {
        @include media(1200px) {
            margin-right: auto;
            width: 40rem; } }

    .RepassesItem-info-values {
        @include media(1200px) {
            width: 20rem; } } }
