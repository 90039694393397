.chart-header {
    margin-bottom: 3rem; }

.chart-title {
    color: get-color(primary);
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.2;

    + .chart-subtitle {
        margin-top: 0.5rem; } }

.chart-subtitle {
    color: rgba(get-color(primary), .7);
    font-size: 1.6rem;
    line-height: 1.2; }

.chart-total {
    color: get-color(primary);
    font-weight: bold;
    font-size: 3rem; }

.chart-container {
    position: relative; }
