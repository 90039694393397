// @import '../../tooling.sass'

.CadastroProgressBar {
    font-weight: 500;

    @media (max-width: 1199px) {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center; } }

.CadastroProgressBar-item {
    @media (max-width: 1199px) {
        position: relative;
        text-align: center;
        width: 15rem;
        height: 2rem;

        display: flex;

        &::after,
        &::before {
            position: absolute;
            top: 0;
            left: 0;

            content: '';
            border-radius: 50%;
            z-index: 1; }

        &::before {
            width: 2.2rem;
            height: 2.2rem;
            border: .5rem solid #fff;
            background-color: #ccc; }

        &::after {
            width: 2.2rem;
            height: 2.2rem;
            border: .2rem solid transparent; }

        .CadastroProgressBar-item-text {
            display: none; } }

    @media (min-width: 1200px) {
        position: relative;
        margin-bottom: 4rem;
        color: #ccc;

        &::after,
        &::before {
            position: absolute;
            top: 0;
            right: 0;

            content: '';
            border-radius: 50%;
            z-index: 1; }

        &::before {
            width: 2.2rem;
            height: 2.2rem;
            border: .5rem solid #fff;
            background-color: #ccc; }

        &::after {
            width: 2.2rem;
            height: 2.2rem;
            border: .2rem solid transparent; } } }

.CadastroProgressBar-item.past {
    @media (max-width: 1199px) {
        color: #335180;

        &::before {
            background-color: #1e88e5; }

        .CadastroProgressBar-item-bar {
            background-color: #1e88e5; } }

    @media (min-width: 1200px) {
        color: #335180;

        &::before {
            background-color: #1e88e5; }

        .CadastroProgressBar-item-bar {
            background-color: #1e88e5; } } }


.CadastroProgressBar-item.current {
    // @media (min-width: 1200px)
    color: #335180;

    &::before {
        background-color: #1e88e5; }
    &::after {
        border-color: #1e88e5; } }


.CadastroProgressBar-item-text {
    @media (max-width: 1199px) {
        display: none; }

    @media (min-width: 1200px) {
        font-size: 1.6rem;
        font-weight: 500;
        text-align: right;
        padding-right: 4rem; } }


.CadastroProgressBar-item-bar {
    @media (max-width: 1199px) {
        height: .4rem;
        width: 100%;
        background-color: #ccc;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(calc(-50% + 1px)); }

    @media (min-width: 1200px) {
        width: .4rem;
        height: 5rem;
        background-color: #ccc;
        position: absolute;
        right: 0.9rem;
        transform: translateY(-5px); } }

.CadastroProgressBar-item.current {
     & + .CadastroProgressBar-standalone-text {
        @media (max-width: 1199px) {
            display: block; } } }

.CadastroProgressBar-standalone-text {
    display: none;

    @media (max-width: 1199px) {
        position: absolute;
        top: 3rem; } }

.CadastroProgressBar-item:nth-last-of-type(2) {
    @media (max-width: 1199px) {
        width: 2.5rem; }

    .CadastroProgressBar-item-bar {
        display: none !important; } }
