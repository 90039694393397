.responsive-embed {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 !important;
    overflow: hidden;

    &::before {
        content: "";
        display: block;
        padding-bottom: 100%; } }

.responsive-embed-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border: 0;
    object-fit: cover; }
