@import '../../tooling.sass';

.relatorios-row {
    @include media(800px) {
        display: flex;
        flex-wrap: wrap; } }

.relatorios-col {
    margin-bottom: 2rem;

    @include media(800px) {
        margin-bottom: 0; }

    &:first-of-type {
        @include media(800px) {
            width: calc(100% - 30rem);
            padding-right: 2rem; } }

    &:last-of-type {
        margin: auto;

        @include media(800px) {
            width: calc(30rem); } } }

.status-listing {
    @include media-range(700px, 800px) {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.5rem;
        margin-right: -0.5rem; }

    .ReportStatusItem {
        margin-bottom: 1rem;

        @include media-range(700px, 800px) {
            width: calc(100% / 3 - 1rem);
            margin-left: .5rem;
            margin-right: .5rem; } } }

.global-status-title {
    font-size: 2.5rem;
    color: get-color(primary);
    margin-bottom: 2rem;
    font-weight: 500; }

.global-status-listing {
    @include media(600px) {
        display: flex;
        flex-wrap: wrap;

        margin-left: -0.5rem;
        margin-right: -0.5rem; }

    .ReportStatusItem {
        margin-bottom: 1rem;

        @include media(600px) {
            width: calc(100% / 2 - 1rem);
            margin: 0 0.5rem 1rem; }
        @include media(800px) {
            width: calc(100% / 3 - 1rem); } }

    // &.--valores
    &.--quantidades {
        .ReportStatusItem {
            @include media(1200px) {
                width: calc(100% / 6 - 1rem); } } } }
