.perfil-vendedor {
    text-align: center;
    .container-foto {
        position: relative;
        .chat-tag {
            background-color: #335180;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            right: 36%;
            border: 3px solid white;
            &.offline {
                background-color: #ababab; } } }
    .foto-circle-crop {
        overflow: hidden;
        width: 100px;
        margin: auto;
        img {
            border-radius: 50%;
            object-fit: contain;
            width: 100%; } }
    .nome-usuario {
        margin-top: 20px;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: -5px; }
    .email-usuario {
        color: #ababab;
        font-size: 14px; }
    .listagem-infos-vendedor {
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        @media (min-width: 768px) {
            display: flex;
            flex-wrap: wrap; }
        .info-item {
            text-align: center;
            display: block;
            padding: 5px;
            padding-bottom: 0;
            &:nth-child(even) {
                border-right: 0; }
            @media (min-width: 768px) {
                width: 50%;
                border-right: 1px solid #dfdfdf; }
            .titulo {
                width: 100%; }
            .conteudo {
                font-weight: bold;
                color: #355180;
                font-size: 14px; } } }
    .exportar-container {
        .titulo {
            text-transform: uppercase;
            font-size: 14px; }
        .link-download {
            text-decoration: underline;
            color: #355180;
            cursor: pointer;
            font-size: 12px; } } }
