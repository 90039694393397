@import '../../tooling.sass';

$small-text-size: 1.6rem;
$item-spacing: 3rem;

.GestaoEquipeRow {
    border-bottom: .1rem solid #d8d8d8;
    margin: 0;
    padding: 4rem 0;

    @include media(1200px) {
        display: flex;
        align-items: center;

        margin: 0;
        padding: 1.5rem; }

    &:first-of-type {
        margin-top: 0;
        padding-top: 1rem; }

    &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 1rem; } }

.gestao-equipe-avatar-container {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;

    flex: none;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(get-color(primary), .1);

    @include media(1200px) {
        margin: 0;
        height: 6rem;
        width: 6rem; } }

.gestao-equipe-avatar {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block; }

.gestao-equipe-initials {
    font-weight: bold;
    color: get-color(primary);
    line-height: 1;
    font-size: 2.8rem;

    @include media(1200px) {
        font-size: 2.3rem; } }

.gestao-equipe-name-container {
    text-align: center;
    color: get-color(primary);

    @include media(1200px) {
        text-align: left; } }

.gestao-equipe-name {
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 1;
    margin-bottom: 0.4rem;

    @include media(1200px) {
        font-size: 1.8rem; } }

.gestao-equipe-job-title {
    color: #78909C;
    font-size: 1.5rem;
    line-height: 1;

    @include media(1200px) {
        font-size: 1.4rem; } }

.gestao-equipe-phones {
    color: #78909C;
    font-size: 1.5rem;
    line-height: 1;
    margin-top: 10px;

    @include media(1200px) {
        font-size: 1.4rem; } }

.gestao-equipe-number {
    text-align: center;

    .number {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1;

        display: flex;
        align-items: center;
        justify-content: center; }

    .number-variation {
        width: 1.3rem;
        margin-left: 1rem; }

    .text {
        font-size: $small-text-size;
        line-height: 1;
        margin-top: 1rem;

        @include media(1200px) {
            display: none; } } }

.gestao-equipe-icon-text {
    color: get-color(text-blue-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    @include media(1200px) {
        margin: 0 2rem; }

    &:first-of-type {
        margin-left: 0; }
    &:last-of-type {
        margin-right: 0;
        margin-bottom: 0; }

    &:hover {
        .text-container {
            color: #0e85e8; } }

    .icon-container {
        display: flex;
        align-items: center;
        margin-bottom: 1.2rem;

        .text {
            color: #0e85e8;
            margin-right: 0.8rem;
            font-size: 2rem;
            font-weight: 500;
            line-height: 1; }

        .icon {
            height: 2rem; } }

    .text-container {
        line-height: 1;
        font-size: $small-text-size;
        text-align: center;

        @include media(1200px) {
            font-size: 1.4rem; } } }

.gestao-equipe-more-actions-container {
    display: flex;
    align-items: center;
    justify-content: center; }

.gestao-equipe-more-actions {
    color: get-color(primary);
    font-size: 3rem;
    cursor: pointer;

    svg {
        display: block; } }

.GestaoEquipeRow-item {
    margin-bottom: $item-spacing;

    &:last-of-type {
        margin-bottom: 0; }

    @include media(1200px) {
        margin-bottom: 0; }

    &.--avatar {
        @include media(1200px) {
            margin-right: 2rem; } }

    &.--name {
        @include media(1200px) {
            width: 23rem; } }

    &.--sales,
    &.--avg-time {
        @include media(1200px) {
            width: 17rem; } }

    &.--view,
    &.--edit,
    &.--remove {
        @include media(1200px) {
            margin-left: 4rem;
            margin-right: 4rem; } }

    &.--view {
        @include media(1200px) {
            margin-left: auto; } }

    &.--remove {
        @include media(1200px) {
            margin-right: 0; } } }

.GestaoEquipeRow.is-lite {
    .GestaoEquipeRow-item {
        &.--name {
            @include media(1200px) {
                width: 20rem; } }

        &.--sales,
        &.--avg-time {
            @include media(1200px) {
                width: 15rem; } }
        &.--actions {
            @include media(1200px) {
                margin-left: auto;
                margin-right: 1rem; } } } }
