@import '../../tooling.sass';

.TitleAside {
    margin-bottom: 2rem;

    @include media(800px) {
        display: flex;
        justify-content: space-between;
        align-items: center; }

    &.small {
        .TitleAside-title {
            font-size: 2rem; } } }


.TitleAside-title {
    color: get-color(primary);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 1.5rem;

    display: flex;
    align-items: center;

    @include media(800px) {
        padding-right: 2rem;
        margin-bottom: 0; } }

.TitleAside-icon {
    margin-right: 1.5rem; }

.TitleAside-image-container {
    max-width: 3rem;
    margin-right: 1.5rem; }

.TitleAside-image {
    width: 3rem;
    display: block; }

// .TitleAside-aside
