.banner-turbine {
    display: flex;
    align-items: center;
    background-color: #335180;
    height: 250px;
    overflow: hidden;
    justify-content: space-between;
    padding: 20px;
    @media (min-width: 768px) {
        padding: 0;
        height: 200px; }
    .texto-banner {
        width: 100%;
        color: white;
        padding: 20px;
        text-align: center;
        @media (min-width: 768px) {
            width: 40%;
            text-align: right; }
        .chamada {
            font-weight: bold;
            font-size: 50px;
            line-height: 0.9;
            margin-bottom: 10px;
            @media (min-width: 768px) {
                max-width: 75%;
                margin-left: auto; } }
        .botao-assinar {
            cursor: pointer;
            border: 1px solid white;
            padding: 5px;
            border-radius: 4px;
            width: 220px;
            text-align: center;
            margin: auto;
            margin-top: 20px;
            transition: 0.3s;
            @media (min-width: 768px) {
                margin: unset;
                margin-left: auto; }
            &:hover {
                background-color: white;
                color: #355180;
                font-weight: bold; } } }
    .foto-banner {
        width: 60%;
        display: none;
        @media (min-width: 768px) {
            display: block; } } }
