@import '../../tooling.sass';

.SmallAction {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;

    &:hover {
        .SmallAction-title {
            text-decoration: underline; } } }

.SmallAction-title {
    font-size: 1.4rem;
    font-weight: 500;
    color: get-color(primary);
    margin-top: 0.5rem; }


.SmallAction-icon-container {}

.SmallAction-icon {
    color: get-color(primary);
    display: block; }
