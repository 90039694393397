.Seller {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center; }

.Seller-image {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    overflow: hidden;
    flex: none;

    + .Seller-info {
        padding-left: 15px;
        // text-align: right

        .Seller-label {
            width: 100%;
            font-size: 1.2rem; }

        .Seller-name {
            width: 100%;
            line-height: 1.2; }

        .Seller-phones {
            width: 100px;
            margin-left: 50px;
            .Seller-phone-item {
                width: 100%;
                line-height: 1.2; } } } }

.Seller-info {
    font-size: 1.4rem;
    display: flex;
    flex-wrap: wrap; }

.Seller-label {
    color: #355180;
    margin-right: 5px; }


.Seller.is-lite {
    .Seller-label {
        color: #37474F; } }
