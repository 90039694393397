.container-infograficos {
    margin-top: 40px;
    margin-bottom: 40px;
    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        flex-grow: 1; }
    .coluna-esquerda {
        margin-bottom: 20px;
        @media (min-width: 768px) {
            width: 68%; } }
    .coluna-direita {
        margin-bottom: 20px;
        @media (min-width: 768px) {
            width: 30%; } } }
