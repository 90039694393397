@import '../../tooling.sass';

.ModalPreviaRepasse-content {
    @include media(700px) {
        display: flex;
        flex-wrap: wrap; }

    .RepasseInfo-vendedor {
        @include media(700px) {
            margin-top: 2rem; } } }

.ModalPreviaRepasse-section {
    margin-bottom: 2rem;

    &.--repasse {
        @include media(700px) {
            width: 100%;
            display: flex;
            margin-bottom: 4rem; } }

    &.--chat {
        @include media(700px) {
            width: 50%; }

        .InteressadoHeader.is-lite {
            padding-left: 1rem; } }

    &.--turbo {
        @include media(700px) {
            width: calc(100% / 2 - 8rem);
            margin-left: 8rem; }

        .mini-title {
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 1.4rem; }

        .TurboTab-box {
            @include media(700px) {
                margin-top: 2rem; } } }

    &.--dentro,
    &.--fora {
        .mini-title {
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 1.4rem;
            margin-bottom: 1.5rem; } }

    &.--dentro {
        @include media(700px) {
            width: 50%; }

        .InteressadoHeader.is-lite {
            padding-left: 1rem; } }

    &.--fora {
        @include media(700px) {
            width: calc(100% / 2 - 8rem);
            margin-left: 8rem; }

        .mini-title {
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 1.4rem; } } }

.ModalPreviaRepasse-image-container {
    margin-bottom: 2rem;
    position: relative;

    @include media(700px) {
        width: 20rem;
        margin-right: 3rem;
        margin-bottom: 0; } }


// .ModalPreviaRepasse-image

.ModalPreviaRepasse-image-overlay {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1;

    color: #fff;

    display: flex;
    align-items: center;

    cursor: pointer;

    img {
        width: 1.5rem;
        margin-right: 0.7rem;
        display: block; } }

.ModalPreviaRepasse-info-car {
    margin-bottom: 2rem;

    @include media(700px) {
        margin-bottom: 0;
        margin-right: 5rem; } }

.ModalPreviaRepasse-info-price {
    margin-bottom: 2rem;

    @include media(700px) {
        margin-bottom: 0; } }

.ModalPreviaRepasse-cta {
    margin-top: 2rem; }
