@import '../../tooling.sass';

.PageNaoAutorizado {
    .content-container {
        max-width: 95rem; } }

.PageNaoAutorizado-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;

    @include media(800px) {
        padding: 4rem 2rem; }

    @include media(1200px) {
        padding: 8rem 2rem; } }

.PageNaoAutorizado-image {
    display: block;
    margin-bottom: 2rem;

    @include media(800px) {
        margin-bottom: 3rem; } }

.PageNaoAutorizado-title {
    color: #37474F;
    margin-bottom: 2rem;
    font-size: 4rem;
    font-weight: 600; }

.PageNaoAutorizado-subtitle {
    color: rgba(#000, .6);
    margin-bottom: 2rem;
    font-weight: 500;
    line-height: 1.4; }
