// @import '../../tooling.sass'

$_spacing: .8rem;

.CadastroRepasse-Confirmation {
    .summary__title {
        font-size: 1.4rem;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-weight: 600;
        margin: 2rem 0;

        display: flex;
        align-items: center; }

    .summary__title-icon {
        margin-left: 1rem;
        color: #1e88e5;
        cursor: pointer; }

    .summary-header {
        margin-bottom: 2rem;

        @media (min-width: 700px) {
            display: flex;
            align-items: center;
            margin-bottom: 4rem; } }


    .summary-header__image-container {
        width: 100%;
        margin-bottom: 2rem;

        @media (min-width: 700px) {
            max-width: 15rem;
            margin-right: 3rem;
            margin-bottom: 0; } }

    .summary-header__info-container {
        margin-bottom: 4rem;

        @media (min-width: 700px) {
            margin-bottom: 0; } }

    .RepasseInfo {
        margin-bottom: 2rem;

        // @media (min-width: 700px)
 }        //     margin-bottom: 0

    .summary__gallery-listing {
        display: flex;
        flex-wrap: wrap;

        margin-left: -$_spacing;
        margin-right: -$_spacing; }

    .summary__gallery-item {
        border-radius: 6px;
        overflow: hidden;

        margin: $_spacing;
        width: calc(100% / 2 - (#{$_spacing} * 2));

        position: relative;
        display: flex;

        cursor: pointer;

        @media (min-width: 600px) {
            width: calc(100% / 5 - (#{$_spacing} * 2)); }
        @media (min-width: 800px) {
            width: calc(100% / 7 - (#{$_spacing} * 2)); } } }

.CadastroRepasse-gallery-listing {
    display: flex;
    flex-wrap: wrap;

    margin-left: -$_spacing;
    margin-right: -$_spacing; }
