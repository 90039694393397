@import '../../tooling.sass';

.GestaoEquipeHeaderHome {
    padding: 1rem 2rem 2rem;
    border-bottom: .1rem solid #ddd;
    color: #37474F; }

.GestaoEquipeHeaderHome-title-listing {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .GestaoEquipeHeaderHome-title {
        font-size: 2rem; }

    .GestaoEquipeHeaderHome-select {
        display: flex;
        font-size: 1.5rem;
        cursor: pointer; }

    .dropdown-icon {
        color: #2B4F81;
        margin-left: 1.2rem; } }

.GestaoEquipeHeaderHome-item-listing {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    @include media(1200px, max) {
        display: none; } }

.GestaoEquipeHeaderHome-item {
    font-size: 1.5rem;

    &:nth-of-type(1) {
        @include media(1200px) {
            width: 27.5rem; } }

    &:nth-of-type(2),
    &:nth-of-type(3) {
        @include media(1200px) {
            text-align: center;
            width: 15rem; } } }
