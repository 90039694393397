.plano-cadastro-desktop {
    display: none;
    @media (min-width: 1200px) {
        display: flex;
        width: 100%; }
    .coluna-titulos {
        width: 25%;
        .area-titulo {
            padding: 20px;
            border-bottom: 1px solid #d8d8d8;
            &:last-child {
                border: 0; }
            .titulo-item {
                font-size: 14px;
                margin-bottom: 10px;
                .tag-mes {
                    font-size: 10px; }
                &:last-child {
                    margin-bottom: 0; } } } } }
