.cadastro-usuario-page-um {
    .subtitulo-pagina {
        margin-bottom: 40px;
        font-size: 14px;
        margin-top: 10px; }
    .container-tipo-perfil {
        display: flex;
        flex-direction: column;
        align-items: center;
        .foto {
            width: 200px;
            height: 200px;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            border: 2px solid #37474f;
            img {
                display: block;
                height: 100%;
                object-fit: cover; } }
        .select-perfil {
            width: 300px;
            margin: auto;
            margin-top: 30px;
            margin-bottom: 30px;
            select {
                width: 100%;
                height: 40px; } } } }


.botao-continuar-cadastro {
    background-color: #37474f;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    color: white;
    width: 300px;
    text-align: center;
    font-size: 14px;
    &:hover {
        opacity: 0.8; } }
