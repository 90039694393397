.RepasseTab {
    .react-tabs__tab {
        border-radius: 0;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1;
        padding: 1.5rem 1.8rem !important;

        display: inline-flex !important;
        align-items: center; }

    .react-tabs__tab {
        &.selected {
            border-radius: 0;
            border-top: .4rem solid #d0021b;
            border-left: .1rem solid #ddd;
            border-right: .1rem solid #ddd;
            background-color: #fff; } }

    .react-tabs__tab-list {
        border: .1rem solid #ddd !important;
        border-top: 0 !important;
        background-color: #ebebeb;
        margin-left: -.1rem !important;
        margin-right: -.1rem !important;
        box-shadow: inset 0 1px 0 #ddd; } }

.react-tabs__tab.filtro-custom {
    width: 100%;
    text-align: center;
    font-size: 1.8rem;

    &.selected {
        background-color: get-color(blue);
        color: #fff; } }
