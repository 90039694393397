@import '../../tooling.sass';

.convite-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media(1200px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center; } }

.convite-buttons-container {
    display: flex;
    flex-direction: row;

    @include media(1200px, max) {
        margin-top: 2rem; } }

.convite-action-title {
    padding: 2rem;
    text-align: center; }

.message-empty-list {
    width: '100%';
    .empty-list-title {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        width: 100%;
        margin-top: 2rem;
        color: get-color(primary); } }
