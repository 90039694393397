@import '../../tooling.sass';

.ReportStatusItem {
    color: #fff;
    background-color: get-color(primary);
    font-weight: bold;
    text-align: center;
    border-radius: .3rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 1.5rem;

    &.big {
        .ReportStatusItem-content {
            font-size: 2.5rem;

            @include media(700px) {
                font-size: 3rem; } } } }


.ReportStatusItem-title {
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: 600;
    opacity: 0.9; }

.ReportStatusItem-content {
    line-height: 1;
    font-size: 2rem;
    margin-bottom: 0.7rem; }
