@import '../../tooling.sass';

.InteressadoRow {
    border-bottom: .1rem solid #d8d8d8;
    padding: 4rem 2rem;
    width: 100%;

    &:last-of-type {
        border-bottom: 0; }

    @include media(1200px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem; } }

.InteressadoRow.is-lite {
    display: flex;
    align-items: center;

    padding: 0;
    border-bottom: 0;
    margin: 1.5rem 0;

    .InteressadoRow-item {
        margin-bottom: 0; }

    .InteressadoRow-item:last-of-type {
        margin-left: auto;
        width: 7rem;

        @include media(800px) {
            width: auto; } }

    .InteressadoRow-avatar-container {
        width: 5rem;
        height: 5rem;
        margin-right: 1rem;

        @include media(800px) {
            width: 4rem;
            height: 4rem; }

        @include media(1200px) {
            margin-right: 0; }

        .NameInitials {
            font-size: 2rem; } }

    .InteressadoRow-name-number {
        text-align: left;

        .InteressadoRow-name {
            font-size: 1.4rem; }

        .InteressadoRow-number-container {
            justify-content: flex-start; } }

    .InteressadoRow-icon-field-container {
        @include media(800px) {
            display: flex;
            flex-direction: row;
            align-items: center; }

        .InteressadoRow-icon {
            width: 2rem;

            @include media(800px) {
                margin-right: 1rem; } }

        .InteressadoRow-field {
            @include media(800px) {
                // width: 100%
                flex: none; } } } }

.InteressadoRow-item {
    margin-bottom: 2rem;
    font-size: 1.6rem;
    text-align: center;
    color: get-color(text-blue-grey);

    &:last-of-type {
        margin-bottom: 0; }

    @include media(1200px) {
        margin-bottom: 0; }

    .InteressadoRow-avatar-container {
        margin: auto;
        border-radius: 50%;
        width: 10rem;
        height: 10rem;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(get-color(primary), .1);

        font-weight: 500;
        color: get-color(primary);
        line-height: 1;
        font-size: 2.8rem;


        @include media(1200px) {
            width: 5.5rem;
            height: 5.5rem;
            font-size: 2rem; } }

    .InteressadoRow-avatar {
        width: 100%;
        height: 100%;
        object-fit: cover; }

    .InteressadoRow-name-number {
        .InteressadoRow-name {
            color: get-color(primary);
            font-size: 1.8rem;
            font-weight: 500;

            @include media(1200px) {
                font-size: 1.4rem;
                text-align: left; } }

        .InteressadoRow-number-container {
            display: flex;
            align-items: center;
            justify-content: center;

            @include media(1200px) {
                justify-content: flex-start;
                text-align: left; } }

        .InteressadoRow-icon {
            width: 1.4rem;
            margin-right: 0.6rem; }

        .InteressadoRow-number {
            font-size: 1.4rem;
            color: #78909C;

            @include media(1200px) {
                font-size: 1.2rem; } } }

    .InteressadoRow-field-container {
        .InteressadoRow-field {
            font-weight: 500;

            @include media(1200px) {
                font-weight: normal; } }

        .InteressadoRow-field-caption {
            font-size: 1.4rem;

            @include media(1200px) {
                display: none; } } }

    .InteressadoRow-icon-field-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: inherit;
        margin-left: -2rem;

        .InteressadoRow-icon {
            width: 2.4rem;
            margin-bottom: 0.5rem; }

        .InteressadoRow-field {
            font-size: 1.4rem; } } }

.InteressadoRow-item {
    &:nth-of-type(1) {
        @include media(1200px) {
            width: 5.5rem; } }

    &:nth-of-type(2) {
        @include media(1200px) {
            width: 30rem;
            margin-left: 2rem; } }

    &:nth-of-type(3) {
        @include media(1200px) {
            width: 18rem; } }

    &:nth-of-type(4) {
        @include media(1200px) {
            width: 18rem; } }

    &:nth-of-type(5) {
        @include media(1200px) {
            width: 18rem; } }

    &:nth-of-type(6) {
        @include media(1200px) {
            width: 7rem;
            margin-left: 6rem; } } }
