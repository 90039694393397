.custom-select {
    position: relative;
    width: 100%;
    select {
        border: 0;
        appearance: none;
        background-color: #fff;
        width: 100%; }
    .icone-caret {
        position: absolute;
        right: 0;
        top: 0px;
        pointer-events: none; } }
