@import '../../tooling.sass';

$message-box-color: #555;
$message-box-padding: 1.2rem 1.5rem;
$message-box-border-size: 0.1rem;
$message-box-border-style: solid;
$message-box-border-radius: 0.6rem;
$message-box-margin: 0;

$message-box-font-size: 1.6rem;
$message-box-font-size-small: $message-box-font-size - 0.3rem;

@mixin message-box-base(
    $font-size: $message-box-font-size,
    $border-radius: $message-box-border-radius,
    $padding: $message-box-padding,
    $margin: $message-box-margin
) {
    font-size: $font-size;
    border-radius: $border-radius;
    padding: $padding;
    margin: $margin;
    display: inline-block;
}

@mixin message-box(
    $color: #555,
    $font-color: #555,
    $border-size: $message-box-border-size,
    $border-style: $message-box-border-style
) {
    font-weight: 500;
    color: mix(#000, $color, 25%);
    background-color: mix(#fff, $color, 87%);
    border: $border-size $border-style mix(#fff, $color, 50%);
}

.MessageBox {
    @include message-box-base;
    @include message-box;
}

.MessageBox-icon {
    margin-right: 0.7rem;
    font-size: 1.8rem;

    svg {
        display: block;
    }
}

.MessageBox.primary {
    @include message-box($color: get-color(primary));
}

.MessageBox.secondary {
    @include message-box($color: get-color(secondary));
}

.MessageBox.info {
    @include message-box($color: get-color(info));
}

.MessageBox.success {
    @include message-box($color: get-color(success));
}

.MessageBox.warning {
    @include message-box($color: #f7a700);

    .MessageBox-icon {
        color: #f7a700;
    }

    .MessageBox-text {
        color: #333;
    }
}

.MessageBox.danger {
    @include message-box($color: get-color(danger));
}

.MessageBox.small {
    font-size: $message-box-font-size-small;
}
