@import '../../tooling.sass';

.ModalPlanos-intro-text {
    color: get-color(text-muted);
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
    margin: 3rem auto;

    @include media(900px) {
        margin: 3rem auto 5rem; } }

.ModalPlanos-plano-listing {
    @include media(900px) {
        display: flex;
        flex-wrap: wrap; } }

.ModalPlanos-plano-item {
    text-align: center;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: .1rem solid #ddd;

    &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0; }

    @include media(900px) {
        border-bottom: 0;
        border-right: .1rem solid #ddd;
        width: calc(100% / 3);

        &:last-of-type {
            border-right: 0; } } }

.plano-item__image-container {
    width: 7rem;
    margin: 0 auto 2.5rem; }

.plano-item__image {
    display: block; }

.plano-item__name {
    color: get-color(primary);
    font-weight: 600;
    line-height: 1;
    margin-bottom: 1.5rem; }

.plano-item__price {
    line-height: 1;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: -.08em;
    margin-bottom: 1.2rem; }

.plano-item__description {
    color: get-color(text-muted);
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0 3rem; }

.ModalPlanos-cta {
    color: get-color(blue);
    font-weight: 600;
    text-align: center;
    line-height: 1.3;
    font-size: 1.6rem; }
