.chat-info-bar {
    .botao-toggle {
        background-color: #fff;
        border: 0;
        color: #37474F;
        font-size: 12px;
        padding-left: 20px;
        text-transform: uppercase;
        font-weight: bold; }
    .container-flex-info-chat {
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 10px;
        padding: 20px;
        @media (min-width: 1200px) {
            display: flex;
            align-items: flex-end; }
        .foto-veiculo {
            width: 150px;
            margin-right: 30px; }
        .desc-veiculo {
            margin-right: 30px;
            .nome-veiculo {
                color: #37474F;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 5px; }
            .detalhe-veiculo {
                text-transform: uppercase;
                font-size: 12px;
                margin-bottom: 5px; }
            .status-veiculo {
                font-size: 14px;
                color: #37474f;
                border: 1px solid #37474f;
                text-align: center;
                padding: 5px;
                padding-top: 2px;
                padding-bottom: 2px;
                border-radius: 4px;
                width: 180px; } }
        .proposta-container {
            margin-top: 10px;
            .texto-proposta {
                font-size: 14px;
                margin-bottom: 20px; }
            .vendedor-container {
                display: flex;
                .foto-vendedor {
                    width: 40px;
                    overflow: hidden;
                    img {
                        border-radius: 50%; } }
                .info-vendedor {
                    margin-left: 15px;
                    .titulo-info-vendedor {
                        color: #37474f;
                        font-weight: bold;
                        font-size: 14px; }
                    .nome-info-vendedor {
                        font-size: 14px; } } } }
        .botao-anunciar {
            margin-top: 20px;
            @media (min-width: 1200px) {
                margin-left: auto;
                margin-top: 0; } } } }
