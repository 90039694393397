@import '../../tooling.sass';

.titulo-com-botao {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 22px;
    color: get-color(primary);

    .botao-mobile {
        font-size: 28px;

        @include media(768px) {
            display: none; } }

    .botao-desktop {
        display: none;
        background-color: get-color(primary);
        color: #fff;
        margin: 1.9rem;
        padding: .5rem 1.4rem;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid get-color(primary);
        font-weight: 500;
        cursor: pointer;
        transition: .2s;

        &:hover {
            background-color: #ffffff;
            color: get-color(primary); }

        @include media(768px) {
            display: block; } } }
