.alert {
    border-radius: 3px;
    display: block;
    margin-bottom: 20px;
    padding: 10px 15px;
    width: 100%;
    .alert-msg {
        display: block;
        font-size: 1.4rem; }
    &.primary {
        border: 1px solid #7f8c8d;
        background-color: #ecf0f1;
        color: #2c3e50; }
    &.success {
        border: 1px solid #27ae60;
        background-color: rgba(#2ecc71, 0.1);
        color: #27ae60; }
    &.error {
        border: 1px solid #c0392b;
        background-color: rgba(#e74c3c, 0.1);
        color: #c0392b; }
    &.warning {
        border: 1px solid #e67e22;
        background-color: rgba(#f1c40f, 0.1);
        color: #e67e22; }
    &.only-message {
        border: 0;
        background-color: transparent;
        padding: 0; } }
