.footer-bar {
    background-color: #242424;
    border-top: 2px solid red;
    color: white;
    text-align: center;
    .footer-content {
        text-align: left;
        width: 100%;
        max-width: 1200px;
        margin: auto;
        padding: 30px;
        @media (min-width: 768px) {
            display: flex;
            padding: 50px;
            justify-content: space-between; }
        .coluna-esquerda {
            @media (min-width: 768px) {
                width: 45%; }
            .logo-footer {
                text-align: center;
                width: 300px;
                margin-bottom: 30px;
                @media (min-width: 768px) {
                    width: 400px;
                    margin: unset;
                    margin-bottom: 50px; } }

            .store-container {
                margin-bottom: 30px;
                display: flex;
                flex-direction: row;
                margin-left: -20px;
                .store-container-link {
                    margin-left: 2rem;
                    border: 1px solid white;
                    border-radius: 4px; } } }

        .coluna-direita {
            font-weight: 600;
            @media (min-width: 768px) {
                width: 17.5%; }

            .titulo {
                color: #6d6e6e;
                margin-bottom: 5px;
                border: 0;
                padding: 0; }
            .listagem-links {
                padding: 0;
                margin: 0;
                .link-item {
                    padding: 0;
                    margin-bottom: 10px; } }
            .link-footer {
                color: #fff;
                margin-bottom: 20px;
                &:hover {
                    text-decoration: underline; } } } }
    .social-bar {
        background: #000;
        text-align: right;
        padding: 20px;
        .container-social {
            display: flex;
            justify-content: flex-end;
            margin-right: 10%;
            @media (min-width: 1367px) {
                margin-right: 20%; }
            .social-item {
                width: 30px;
                height: 30px;
                margin-left: 20px; } } } }
