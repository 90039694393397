.RepasseValores {
    color: #37474F;

    .BarraPorcentagem-titulo {
        margin-bottom: -.6rem;
        line-height: 1;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase; } }

.RepasseValores-destaque {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1;
    margin: 0.4rem 0 0.9rem; }

// .RepasseValores-fipe

.RepasseValores-fipe-titulo {
    font-size: 1.2rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: flex-start;

    img {
        width: 3rem;
        object-fit: contain;
        margin-left: .5rem; } }

.RepasseValores-custo-valor {
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.9rem; }

.RepasseValores-fipe-valor {
    font-weight: 600;
    line-height: 1; }

.RepasseValores-button-container {
    text-align: center;
    margin-top: 1.5rem; }

// .RepasseValores-button
