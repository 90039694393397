.box-chat-item {
    height: 100%; }



.input-chat-container {
    padding: 10px;
    position: relative;
    border-top: 1px solid #d8d8d8;
    margin-top: 10px;
    input {
        width: 100%;
        height: 40px;
        border-radius: 12px;
        border: 0;
        border: 1px solid #d8d8d8;
        padding-left: 20px;
        font-size: 14px;
        background-color: #d8d8d8; }
    .icone-enviar {
        position: absolute;
        top: 20px;
        right: 30px;
        color: #9b9b9b;
        cursor: pointer; }
    .icone-anexo {
        position: absolute;
        top: 20px;
        right: 70px;
        color: #9b9b9b;
        cursor: pointer; } }
