.tab-chat-container {
    height: 100%;
    .container-flex {
        @media (min-width: 1024px) {
            display: flex; }
        .coluna-esquerda {
            @media (min-width: 1024px) {
                width: 24.5%;
                border-right: 1px solid #d8d8d8;
                padding-right: 2%; } }
        .coluna-direita {
            @media (min-width: 1024px) {
                width: 75.5%; } } }

    .container-pesquisa {
        border-bottom: 1px solid #d8d8d8;
        padding: 10px;
        height: 75px; }

    .container-usuarios {
        width: 100%;
        height: 100px;
        overflow-y: auto;
        display: flex;

        @media (min-width: 1024px) {
            display: block;
            height: auto;
            max-height: 500px; }
        .usuario-item {
            margin-right: 20px;
            cursor: pointer; } }

    .container-empty-list {
        margin: 20px; } }
