@import '../../tooling.sass';

.RepasseInfo {
    @include media(1200px) {
        margin-bottom: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 35rem; } }

.RepasseInfo-titulo {
    color: get-color(primary);
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: .8rem;
    font-weight: 600;

    display: flex;

    a {
        color: inherit; } }

.RepasseInfo-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

.RepasseInfo-subcontainer-link {
    color: get-color(blue); }

.RepasseInfo-item {
    color: #98aab4;
    font-size: 1.4rem;
    line-height: 1.2;
    margin-bottom: 0.8rem; }

.RepasseInfo-icon-info {
    font-size: 1.4rem;
    line-height: 1.2;
    color: #37474F;
    margin-bottom: 1.5rem;

    display: flex;
    align-items: center;

    &:last-of-type {
        margin-bottom: 0; }

    .badge {
        background-color: #D0021B;
        color: white;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 1rem;
        padding: 10px;
        margin-right: 1rem;

        font-weight: bold;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; }

    /* &:hover
        text-decoration: underline
        cursor: pointer */

    img,
    svg {
        margin-right: 1rem; } }

.RepasseInfo-vendedor {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    color: #37474F;
    margin: 0;
    width: 50%;
    .icon {
        font-size: 1.4rem;
        margin-left: 3px;
        + .text {
            margin-left: 11px; } }

    .text {
        font-size: 1.4rem;
        line-height: 1.2;
        word-break: break-word;
        width: 250px;

        + .icon {
            margin-left: 11px; } } }

.RepassesItem-info-availability {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @include media(1200px) {
        margin-bottom: 0; }

    .StatusLabel {
        margin-top: auto; } }

.RepasseInfo-stock-link {
    font-size: 1.25rem;
    color: get-color(blue);
    display: block;
    line-height: 1rem;
    margin: 0;
    text-decoration: underline; }
