.container-page-cadastro {
    background-image: url('../../assets/images/bg-default.jpg');
    background-size: cover;
    min-height: 80vh;
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .card-cadastro {
        background-color: #fff;
        width: 90%;
        min-height: 70vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        margin-bottom: 70px;
        .logo-topo {
            margin-left: -30px;
            width: 150px;
            @media (min-width: 1200px) {
                width: auto; } }
        .container-conteudo-cadastro {
            width: 100%;
            margin-top: 30px;
            @media (min-width: 1200px) {
                display: flex;
                justify-content: space-between;
                flex: 1;
                margin-top: 70px; }
            .coluna-esquerda {
                margin-bottom: 60px;
                @media (min-width: 1200px) {
                    width: 20%;
                    border-right: 1px solid #d8d8d8;
                    padding-right: 30px;
                    margin-bottom: 30px; }
                .link-login {
                    font-size: 12px;
                    text-align: right;
                    display: none;
                    @media (min-width: 1200px) {
                        display: block; } } }
            .coluna-direita {
                margin-bottom: 30px;
                @media (min-width: 1200px) {
                    width: 80%;
                    padding-left: 30px; }
                .titulo-cadastro {
                    font-size: 22px;
                    font-weight: 500; } } } } }
