@import '../../tooling.sass';

.PerfilCompra-header {
    @include media(1200px) {
        border-bottom: 1px solid #d8d8d8;
        display: flex;
        padding: 0 1.5rem 1.5rem;

        font-size: 1.6rem;
        font-weight: 500;
        color: #666; } }

.PerfilCompra-header-item {
    @include media(1200px, max) {
        display: none; }

    &:nth-of-type(1) {
        width: 20rem;
        margin-right: 2rem; }

    &:nth-of-type(2) {
        width: 25rem;
        margin-right: 2rem;
        text-align: center; }

    &:nth-of-type(3) {
        width: 20rem;
        margin-right: 2rem;
        text-align: center; }

    &:nth-of-type(4) {
        width: 20rem;
        margin-right: 2rem;
        text-align: center; } }

.PerfilCompra-listing {
    @include media(600px) {
        display: flex;
        flex-wrap: wrap; } }

.PerfilCompra-row {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #d8d8d8;

    @include media-range(600px, 900px) {
        width: calc(100% / 2); }

    @include media-range(900px, 1200px) {
        width: calc(100% / 3);
        border-right: 1px solid #d8d8d8;
        padding: 3rem 2rem; }

    @include media(1200px) {
        display: flex;
        align-items: center;
        width: 100%;

        padding: 1.5rem; }

    &:last-of-type {
        @include media(1200px) {
            border-bottom: 0; } }

    &:nth-of-type(odd) {
        @include media-range(600px, 900px) {
            border-right: 1px solid #d8d8d8;
            padding-right: 2rem; } }

    &:nth-of-type(even) {
        @include media-range(600px, 900px) {
            padding-left: 2rem; } }

    &:nth-of-type(3n) {
        @include media-range(900px, 1200px) {
            border-right: 0; } } }

.PerfilCompra-item {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.2;

    margin-bottom: 2rem;

    @include media(1200px) {
        margin-bottom: 0; }

    &:last-of-type {
        margin-bottom: 0; }

    &.--title {
        color: get-color(primary);
        @include media(1200px) {
            width: 20rem;
            margin-right: 2rem; } }

    &.--region {
        @include media(1200px) {
            width: 25rem;
            margin-right: 2rem;
            text-align: center; } }

    &.--type {
        > span {
            display: block; }

        @include media(1200px) {
            width: 20rem;
            margin-right: 2rem;
            text-align: center; } }
    &.--year {
        @include media(1200px) {
            width: 20rem;
            margin-right: 2rem;
            text-align: center; } }

    &.--actions {
        display: flex;
        justify-content: center;
        margin-top: 3rem;

        @include media(1200px) {
            margin-top: 0;
            margin-left: auto; }

        .--edit {
            margin-right: 2rem; }

        .--delete {
            margin-left: 2rem; } } }


.PerfilCompra-item-caption {
    font-size: 1.5rem;
    color: #888;
    margin-bottom: 0.5rem;

    @include media(1200px) {
        display: none; } }

//
//
//
//

.Modal-PerfilCompra {
    font-weight: 500;
    color: #555;

    .Modal-content {
        padding: 2rem; } }

.remove__title {
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 2rem; }

.remove__title-confirm {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 3rem; }

//
//
//

.seletor-veiculo-listing {
    @include media(700px) {
        display: flex;
        margin-left: -1rem;
        margin-right: -1rem; } }

.seletor-veiculo-listing.is-invalid {
    .seletor-veiculo__item {
        border-color: #f4516c; } }

.seletor-veiculo__item {
    display: block;
    position: relative;
    border: 1px solid #0f94d3;
    border-radius: 7px;
    padding: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-bottom: 1.5rem;
    cursor: pointer;

    @include media(700px) {
        margin: 0 1rem;
        width: calc(100% / 3); }

    .checkbox-box {
        position: absolute !important;
        top: 1rem !important;
        right: 0.5rem !important; } }

.seletor-veiculo__icon {
    width: 5rem; }

.seletor-veiculo__texto {
    font-size: 1.5rem;
    line-height: 1.2;
    text-align: center;
    margin-top: 1rem; }
