@import '../../tooling.sass';

.home-container {
    background-color: #f5f5f5;
    min-height: 60vh;
    padding: 10px;
    padding-top: 120px;

    @include media(1200px) {
        padding-top: 80px; }

    .conteudo-home {
        max-width: 1200px;
        padding: 20px;
        margin: auto;

        @include media(1200px) {
            padding: 50px; }

        .linha-topo {
            @include media(1200px) {
                display: flex;
                justify-content: space-between;
                align-items: center; } }

        .titulo-card {
            color: #2B4F82;
            font-size: 18px; }

        .subtitulo-detalhe {
            font-size: 12px;
            margin-bottom: 20px;
            color: #ababab; } } }

.flex-container-bottom {
    @include media(1200px) {
        display: flex;
        justify-content: space-between; }

    .coluna-esquerda {
        margin-bottom: 2rem;

        @include media(1200px) {
            width: calc(30% - 1.5rem);
            margin-bottom: 0; } }

    .coluna-direita {
        @include media(1200px) {
            width: calc(70% - 1.5rem); } } }

.container-repasses-home {
    @include media(700px) {
        display: flex;
        flex-wrap: wrap; }

    .RepasseItemHome {
        @include media-range(700px, 1200px) {
            width: 50%;

            &:nth-of-type(odd) {
                padding-right: 2rem;
                border-right: 1px solid #d8d8d8; }
            &:nth-of-type(even) {
                padding-left: 2rem; } }

        @include media(1200px) {
            width: 100%; } } }

// /////////////////////////
// ///// Filtro-home ///////
// /////////////////////////

$Filtro-home-spacing: 2rem;

.Filtro-home-layout {
    @include media(800px) {
        display: flex;
        flex-wrap: wrap; } }

// Layout styles
.Filtro-home__sidebar {
    @include media(800px, max) {
        margin-bottom: 1rem; }

    @include media(800px) {
        width: 30rem;
        margin-right: $Filtro-home-spacing; } }

.Filtro-home__result {
    @include media(800px) {
        width: calc(100% - 30rem - #{$Filtro-home-spacing}); } }

// Other Styles
.Filtro-home__sidebar {
    .label {
        color: get-color(primary); }

    .CheckboxGroup {
        .label {
            color: get-color(text-muted);
            font-size: 1.4rem; } }

    .sidebar__vehicle-type-listing {
        display: flex;
        margin-bottom: 2rem; }

    .sidebar__vehicle-type-item {
        font-size: 2rem;
        width: 100%; }

    .sidebar__title {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 2rem;
        color: get-color(primary); }

    .sidebar__section {
        padding: 1rem 0;
        border-bottom: 1px solid #ddd;
        margin-bottom: 1rem;

        &:last-of-type {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0; } }

    .sidebar__section-title {
        color: get-color(text-muted);
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        font-size: 1.4rem;
        letter-spacing: 0.05em; } }


.Filtro-home__result {
    .result__title {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 2rem;
        color: get-color(primary);

        .result__title-obs {
            color: get-color(grey);
            display: block;
            font-size: 1.35rem;

            a {
                color: get-color(grey);
                text-decoration: underline; }

            a:hover {
                color: get-color(primary); } } }

    .result__item {
        border-bottom: 1px solid #ddd;
        padding-bottom: 2.5rem;
        margin-bottom: 2.5rem;

        @include media(600px) {
            display: flex;
            flex-wrap: wrap; }

        &:last-of-type {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0; } }

    .result__image-container {
        margin-bottom: 2rem;
        width: 100%;

        @include media(1200px) {
            margin-bottom: 0;
            width: 20rem;
            height: 16.5rem;
            margin-right: 3rem; } }

    .result__image {
        width: 100%;

        @include media(1200px) {
            object-fit: cover;
            height: 100%;
            width: 100%; } }

    .result__info {
        margin-bottom: 2rem;

        @include media(600px) {
            width: calc(100% / 2);
            padding-right: 1rem;
            margin-bottom: 0; }

        @include media(1200px) {
            width: calc(100% / 3); } }

    .result__valores {
        @include media(600px) {
            width: calc(100% / 2);
            padding-left: 1rem; }

        @include media(1200px) {
            width: calc(100% / 3); } } }


// .ModalTermoUso

.ModalTermoUso-content {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 1.5rem;
    height: 38rem;
    overflow: auto;

    font-size: 1.6rem;

    p {
        margin-bottom: 1em;
        font-weight: 500;
        color: #666;

        + h2,
        + h3 {
            margin-top: 1em; } }

    h2,
    h3 {
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 0.8em; }

    h2 {
        font-size: 3rem; }

    h3 {
        font-size: 2rem; } }

.ModalTermoUso-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media(500px) {
        flex-direction: row-reverse; }

    > button {
        width: 100%;
        margin-top: 0.5rem;

        @include media(500px) {
            width: 20rem;
            margin: 0 1rem; } } }
