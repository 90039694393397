.rounded-search-input {
    position: relative;
    height: 40px;
    width: 100%;
    input {
        width: 100%;
        height: 100%;
        border: 0;
        padding-left: 10px;
        border: 1px solid #ababab;
        border-radius: 4px; }
    .icone {
        position: absolute;
        right: 8px;
        top: 8px;
        color: #ababab; } }
