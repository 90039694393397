@import '../../tooling.sass';

.main-header-container {
    display: block;
    width: 100%;
    background-color: #335180;
    position: fixed;
    top: 0;
    z-index: 20;

    .main-header {
        padding: 1.5rem 2rem;

        @include media(800px) {
            padding: 2rem; }

        &.has-border {
            border-bottom: 4px solid red; } }

    .inner-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-width: 110rem;
        margin: auto;

        @include media(800px) {
            max-width: calc(110rem + 4rem); } }

    .main-header-logo {
        display: block;
        max-width: 14rem;
        width: 100%;

        @include media(1200px) {
            margin: 0;
            max-width: 18rem; } }

    .burger-icon-container {
        @include media(800px) {
            display: none; } }

    .main-header-action-container {
        @include media(800px, max) {
            display: none; }

        display: flex;
        align-items: center;
        margin-left: auto; }

    .main-header-menu {
        @include media(800px) {
            margin-top: 1.5rem;
            margin-left: auto;
            width: 100%;

            display: flex;
            justify-content: flex-end; } } }

.main-subheader {
    background: #fff;
    padding: 1rem 0;
    box-shadow: 0 1px 2px rgba(#000, .1);

    @include media(800px) {
        padding: 1.5rem 0;
        display: block; } }

.main-subheader-container {
    max-width: 120rem;
    padding: 0 2rem;
    margin: auto; }

.title-container {
    display: flex;
    align-items: center;
    justify-content: space-between; }


.breadcrumbs-container {
    margin-top: 0.5rem; }


.infos-container {
    display: none;
    margin-top: 0 !important;

    @include media(800px) {
        display: flex;
        justify-content: space-between; } }
