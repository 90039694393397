@import '../../tooling.sass';

.reveal {
    opacity: 1;
    visibility: visible;
    transition: .2s;

    &.hide {
        opacity: 0;
        visibility: hidden; } }

.MeuPerfil-avatar-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 2rem;
    margin-bottom: 2rem;

    @include media(800px) {
        flex-direction: row; }

    .MeuPerfil-name {
        font-weight: 600;
        color: get-color(primary) !important;
        font-size: 1.8rem;

        @include media(800px, max) {
            text-align: center; }

        @include media(800px) {
            max-width: 40rem; } } }

.MeuPerfil-avatar-container {
    height: 10rem;
    width: 10rem;
    border: 2px solid get-color(primary);
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1rem;

    @include media(800px) {
        margin-right: 2rem;
        margin-bottom: 0; } }

.MeuPerfil-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #e8e8e8; }

.MeuPerfil-avatar-actions {
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;

    @include media(800px) {
        padding-bottom: 3rem;
        justify-content: flex-start; } }

.MeuPerfil-avatar-action {
    margin-bottom: 1rem;
    text-align: center;
    margin-right: 2rem;

    @include media(800px) {
        margin-bottom: 0; }

    &:last-of-type {
        margin-bottom: 0; }

    .SmallAction {
        @include media(800px) {
            flex-direction: row; } }

    .SmallAction-title {
        @include media(800px) {
            margin-right: 1rem;
            margin-bottom: 0; } }

    .SmallAction-icon {} }
