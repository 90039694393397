@import '../../tooling.sass';

.RepasseInteressados {}

.RepasseInteressados-title {
    font-size: 1.4rem;
    display: flex;
    align-items: baseline; }

.RepasseInteressados-number {
    font-size: 2rem;
    font-weight: bold;
    color: get-color(primary-dark);
    margin-right: 0.3rem; }

.RepasseInteressados-text {
    color: #78909C;
    margin-right: 0.3rem; }

.RepasseInteressados-link {
    color: get-color(blue); }


.RepasseInteressados-item-listing {
    margin-top: 1rem;
    margin-left: 0.5rem; }

.RepasseInteressados-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;

    &:last-of-type {
        margin-bottom: 0; } }

.RepasseInteressados-item-number {
    background-color: get-color(primary-dark);
    color: #fff;
    width: 1.7rem;
    height: 1.7rem;
    margin-right: 1.2rem;
    border-radius: 50%;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1;
    cursor: default;

    display: flex;
    align-items: center;
    justify-content: center; }

.RepasseInteressados-item-value {
    font-size: 1.4rem;
    line-height: 1;
    color: rgba(#000, .6);
    font-weight: 500; }
