.social-bar {
    background: #000;
    text-align: right;
    padding: 20px;
    .container-social {
        display: flex;
        justify-content: flex-end;
        margin-right: 10%;
        @media (min-width: 1367px) {
            margin-right: 20%; }
        .social-item {
            width: 30px;
            height: 30px;
            margin-left: 20px; } } }
