.usuario-item-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    padding: 20px;
    width: 260px;
    &:last-child {
        border-bottom: 0; }
    &.ativo {
        background-color: #efefef; }
    .foto-usuario {
        border-radius: 50%;
        overflow: hidden;
        width: 50px;
        height: 50px; }
    .info-usuario {
        margin-left: 15px;
        .nome-usuario {
            position: relative;
            display: flex;
            align-items: center;
            .texto {
                font-weight: bold;
                color: #2B4F81;
                margin-right: 10px;
                font-size: 14px; }
            .badge {
                background-color: #D0021B;
                color: white;
                width: 2rem;
                height: 2rem;
                padding: 6px;
                border-radius: 50%;
                font-size: 0.9rem;
                padding: 12px;

                font-weight: bold;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center; } }
        .info-line {
            display: flex;
            font-size: 14px;
            .icone {
                color: #1E88E5;
                margin-right: 12px;
                font-size: 14px;
                margin-left: 4px;
                &.phone {
                    margin-left: 0;
                    font-size: 12px;
                    margin-right: 12px;
                    transform: rotate(90deg); } }
            .texto {
                font-size: 12px; } } } }
