@import '../../tooling.sass';

.non-avatar {
    // padding: 10px
    border-radius: 50%;
    font-size: 16px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
    background-color: #8097bb;
    color: get-color(primary);
    font-weight: bold;
    cursor: default; }
