// @author Maicol Santos, Breno Soares
// @source https://github.com/maicolsantos/generator-skull/blob/master/generators/app/templates/sass/global/_burger.sass
//
// Burger parts
//
// (---) top    -> &::before
// [---] middle -> &
// (---) bottom -> &::after

$burger-width: 2.5rem;
$burger-height: 0.3rem;
$burger-gutter: 0.4rem;
$burger-color: #222;
$burger-transition-duration: 0.3s;

// Burger
@mixin burger($width: $burger-width, $height: $burger-height, $gutter: $burger-gutter, $color: $burger-color, $transition-duration: $burger-transition-duration) {
    position: relative;
    margin-top: $burger-height + $burger-gutter;
    margin-bottom: $burger-height + $burger-gutter;
    user-select: none;

    // 1. Fixes jagged edges in Firefox, see issue #10.
    &,
    &::before,
    &::after {
        display: block;
        width: $width;
        height: $burger-height;
        background-color: $color;
        outline: 1px solid transparent;
        // 1
        transition-property: background-color, transform;
        transition-duration: $transition-duration; }

    &::before,
    &::after {
        position: absolute;
        content: ""; }

    &::before {
        top: -($burger-height + $burger-gutter); }

    &::after {
        top: $burger-height + $burger-gutter; } }

// Burger animation
@mixin burger-to-cross($color: auto) {
    background-color: transparent;

    @if $color != auto {
        &::before,
        &::after {
            background-color: $color; } }

    &::before {
        transform: translateY($burger-gutter + $burger-height) rotate(45deg); }

    &::after {
        transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg); } }

.burger-icon-container {
    cursor: pointer;
    display: block;
    padding: 0.5em;
    background-color: transparent;
    border: 0; }

.burger-icon {
    @include burger;

    &.white {
        &,
        &::before,
        &::after {
            background-color: #fff; } } }

.burger-icon-container.is-active .burger-icon {
    @include burger-to-cross; }
