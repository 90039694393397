.page-container {
    background-color: #eee;
    padding: 40px 0;

    flex: 1 0 auto;
    height: 100%; }

.content-container {
    max-width: 1200px;
    padding: 0 20px;
    margin: auto; }
