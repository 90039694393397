h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    line-height: 1.2; }
h1 {
    font-size: 7rem; }
h2 {
    font-size: 5rem; }
h3 {
    font-size: 3.5rem; }
h4 {
    font-size: 2.3rem; }
h5 {
    font-size: 1.8rem; }
h6 {
    font-size: 1.6rem; }
p {
    line-height: 1.5; }
code {
    background-color: #e6e6e6;
    padding: 1px 3px; }


.mini-title {
    display: flex;
    color: get-color(primary-dark);
    font-weight: 600;
    line-height: 1.2;
    font-size: 1.8rem;

    .mini-title-icon {
        margin-right: 1rem;
        height: 2.2rem;
        width: auto; } }
