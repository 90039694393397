@import url(https://fonts.googleapis.com/css?family=Teko:500);
/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

ul, li {
  list-style-type: none; }

html {
  font-family: sans-serif;
  font-size: 62.5%;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

*, *:before, *:after {
  box-sizing: border-box; }

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

body {
  padding: 0;
  font-size: 1.6rem; }

input,
textarea,
select,
a,
button {
  outline: none; }
  input:focus,
  textarea:focus,
  select:focus,
  a:focus,
  button:focus {
    outline: none; }

a {
  text-decoration: none; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0; }

img {
  display: block;
  max-width: 100%; }

body {
  margin: 0;
  color: #33373b;
  font-family: Avenir Next, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.45;
  overflow-x: hidden; }

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.eot");
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.woff2") format("woff2"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.woff") format("woff"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.ttf") format("truetype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.svg#Avenir Next") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.eot");
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.woff2") format("woff2"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.woff") format("woff"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.ttf") format("truetype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.svg#Avenir Next") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.eot");
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.eot?#iefix") format("embedded-opentype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.woff2") format("woff2"), url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.woff") format("woff"), url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.ttf") format("truetype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.svg#Avenir Next") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.eot");
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.eot?#iefix") format("embedded-opentype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.woff2") format("woff2"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.woff") format("woff"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.ttf") format("truetype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.svg#Avenir Next") format("svg");
  font-weight: 900;
  font-style: normal; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  line-height: 1.2; }

h1 {
  font-size: 7rem; }

h2 {
  font-size: 5rem; }

h3 {
  font-size: 3.5rem; }

h4 {
  font-size: 2.3rem; }

h5 {
  font-size: 1.8rem; }

h6 {
  font-size: 1.6rem; }

p {
  line-height: 1.5; }

code {
  background-color: #e6e6e6;
  padding: 1px 3px; }

.mini-title {
  display: -webkit-flex;
  display: flex;
  color: #2f4b76;
  font-weight: 600;
  line-height: 1.2;
  font-size: 1.8rem; }
  .mini-title .mini-title-icon {
    margin-right: 1rem;
    height: 2.2rem;
    width: auto; }

.button {
  display: inline-block;
  padding: 1rem 1.4rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  line-height: 1;
  font-weight: 500;
  border: 0.1rem solid transparent;
  border-radius: 0.4rem;
  background-color: transparent;
  transition: 0.2s;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-size: 1.5rem; }
  .button.clean {
    padding: 0; }

.button.light {
  color: #111;
  background-color: #fff; }
  .button.light:hover {
    background-color: #e6e6e6; }
  .button.light:active, .button.light:focus {
    background-color: #cccccc;
    outline: 0; }
  .button.light.is-active {
    background-color: #cccccc; }
  .button.light[disabled],
  .button.light .is-disabled {
    background-color: white;
    color: #585858;
    cursor: not-allowed; }

.button.dark {
  color: #fff;
  background-color: #333; }
  .button.dark:hover {
    background-color: #2e2e2e; }
  .button.dark:active, .button.dark:focus {
    background-color: #292929;
    outline: 0; }
  .button.dark.is-active {
    background-color: #292929; }
  .button.dark[disabled],
  .button.dark .is-disabled {
    background-color: #707070;
    color: white;
    cursor: not-allowed; }

.button.grey {
  color: #333;
  background-color: #ddd; }
  .button.grey:hover {
    background-color: #c7c7c7; }
  .button.grey:active, .button.grey:focus {
    background-color: #b1b1b1;
    outline: 0; }
  .button.grey.is-active {
    background-color: #b1b1b1; }
  .button.grey[disabled],
  .button.grey .is-disabled {
    background-color: #e7e7e7;
    color: #707070;
    cursor: not-allowed; }

.button.primary {
  color: #fff;
  background-color: #335180; }
  .button.primary:hover {
    background-color: #2e4973; }
  .button.primary:active, .button.primary:focus {
    background-color: #294166;
    outline: 0; }
  .button.primary.is-active {
    background-color: #294166; }
  .button.primary[disabled],
  .button.primary .is-disabled {
    background-color: #7085a6;
    color: white;
    cursor: not-allowed; }

.button.secondary {
  color: #fff;
  background-color: #c63f43; }
  .button.secondary:hover {
    background-color: #b2393c; }
  .button.secondary:active, .button.secondary:focus {
    background-color: #9e3236;
    outline: 0; }
  .button.secondary.is-active {
    background-color: #9e3236; }
  .button.secondary[disabled],
  .button.secondary .is-disabled {
    background-color: #d7797b;
    color: white;
    cursor: not-allowed; }

.button.blue {
  color: #fff;
  background-color: #1e88e5; }
  .button.blue:hover {
    background-color: #1b7ace; }
  .button.blue:active, .button.blue:focus {
    background-color: #186db7;
    outline: 0; }
  .button.blue.is-active {
    background-color: #186db7; }
  .button.blue[disabled],
  .button.blue .is-disabled {
    background-color: #62aced;
    color: white;
    cursor: not-allowed; }

.button.info {
  color: #111;
  background-color: #36a3f7; }
  .button.info:hover {
    background-color: #3193de; }
  .button.info:active, .button.info:focus {
    background-color: #2b82c6;
    outline: 0; }
  .button.info.is-active {
    background-color: #2b82c6; }
  .button.info[disabled],
  .button.info .is-disabled {
    background-color: #72bff9;
    color: #585858;
    cursor: not-allowed; }

.button.success {
  color: #111;
  background-color: #34bfa3; }
  .button.success:hover {
    background-color: #2fac93; }
  .button.success:active, .button.success:focus {
    background-color: #2a9982;
    outline: 0; }
  .button.success.is-active {
    background-color: #2a9982; }
  .button.success[disabled],
  .button.success .is-disabled {
    background-color: #71d2bf;
    color: #585858;
    cursor: not-allowed; }

.button.warning {
  color: #111;
  background-color: #ffb822; }
  .button.warning:hover {
    background-color: #e6a61f; }
  .button.warning:active, .button.warning:focus {
    background-color: #cc931b;
    outline: 0; }
  .button.warning.is-active {
    background-color: #cc931b; }
  .button.warning[disabled],
  .button.warning .is-disabled {
    background-color: #ffcd64;
    color: #585858;
    cursor: not-allowed; }

.button.danger {
  color: #111;
  background-color: #f4516c; }
  .button.danger:hover {
    background-color: #dc4961; }
  .button.danger:active, .button.danger:focus {
    background-color: #c34156;
    outline: 0; }
  .button.danger.is-active {
    background-color: #c34156; }
  .button.danger[disabled],
  .button.danger .is-disabled {
    background-color: #f78598;
    color: #585858;
    cursor: not-allowed; }

.button.outline {
  color: #111;
  background-color: transparent;
  border: 0.1rem solid #111; }
  .button.outline:hover {
    background-color: #111;
    color: #fff; }
  .button.outline:active, .button.outline:focus {
    color: #fff;
    border-color: #0f0f0f;
    background-color: #0f0f0f;
    outline: 0; }
  .button.outline.button-active {
    background-color: rgba(0, 0, 0, 0.15); }
  .button.outline[disabled],
  .button.outline .is-disabled {
    background-color: rgba(255, 255, 255, 0.5);
    color: #585858; }
  .button.outline.light {
    color: #fff;
    background-color: transparent;
    border: 0.1rem solid #fff; }
    .button.outline.light:hover {
      background-color: #fff;
      color: #111; }
    .button.outline.light:active, .button.outline.light:focus {
      color: #fff;
      border-color: #e6e6e6;
      background-color: #e6e6e6;
      outline: 0; }
    .button.outline.light.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.light[disabled],
    .button.outline.light .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: white; }
  .button.outline.dark {
    color: #333;
    background-color: transparent;
    border: 0.1rem solid #333; }
    .button.outline.dark:hover {
      background-color: #333;
      color: #fff; }
    .button.outline.dark:active, .button.outline.dark:focus {
      color: #fff;
      border-color: #2e2e2e;
      background-color: #2e2e2e;
      outline: 0; }
    .button.outline.dark.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.dark[disabled],
    .button.outline.dark .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #707070; }
  .button.outline.primary {
    color: #335180;
    background-color: transparent;
    border: 0.1rem solid #335180; }
    .button.outline.primary:hover {
      background-color: #335180;
      color: #fff; }
    .button.outline.primary:active, .button.outline.primary:focus {
      color: #fff;
      border-color: #2e4973;
      background-color: #2e4973;
      outline: 0; }
    .button.outline.primary.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.primary[disabled],
    .button.outline.primary .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #7085a6; }
  .button.outline.blue {
    color: #1e88e5;
    background-color: transparent;
    border: 0.1rem solid #1e88e5; }
    .button.outline.blue:hover {
      background-color: #1e88e5;
      color: #fff; }
    .button.outline.blue:active, .button.outline.blue:focus {
      color: #fff;
      border-color: #1b7ace;
      background-color: #1b7ace;
      outline: 0; }
    .button.outline.blue.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.blue[disabled],
    .button.outline.blue .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #62aced; }
  .button.outline.secondary {
    color: #c63f43;
    background-color: transparent;
    border: 0.1rem solid #c63f43; }
    .button.outline.secondary:hover {
      background-color: #c63f43;
      color: #fff; }
    .button.outline.secondary:active, .button.outline.secondary:focus {
      color: #fff;
      border-color: #b2393c;
      background-color: #b2393c;
      outline: 0; }
    .button.outline.secondary.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.secondary[disabled],
    .button.outline.secondary .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #d7797b; }
  .button.outline.info {
    color: #36a3f7;
    background-color: transparent;
    border: 0.1rem solid #36a3f7; }
    .button.outline.info:hover {
      background-color: #36a3f7;
      color: #fff; }
    .button.outline.info:active, .button.outline.info:focus {
      color: #fff;
      border-color: #3193de;
      background-color: #3193de;
      outline: 0; }
    .button.outline.info.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.info[disabled],
    .button.outline.info .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #72bff9; }
  .button.outline.success {
    color: #34bfa3;
    background-color: transparent;
    border: 0.1rem solid #34bfa3; }
    .button.outline.success:hover {
      background-color: #34bfa3;
      color: #fff; }
    .button.outline.success:active, .button.outline.success:focus {
      color: #fff;
      border-color: #2fac93;
      background-color: #2fac93;
      outline: 0; }
    .button.outline.success.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.success[disabled],
    .button.outline.success .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #71d2bf; }
  .button.outline.warning {
    color: #ffb822;
    background-color: transparent;
    border: 0.1rem solid #ffb822; }
    .button.outline.warning:hover {
      background-color: #ffb822;
      color: #fff; }
    .button.outline.warning:active, .button.outline.warning:focus {
      color: #fff;
      border-color: #e6a61f;
      background-color: #e6a61f;
      outline: 0; }
    .button.outline.warning.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.warning[disabled],
    .button.outline.warning .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #ffcd64; }
  .button.outline.danger {
    color: #f4516c;
    background-color: transparent;
    border: 0.1rem solid #f4516c; }
    .button.outline.danger:hover {
      background-color: #f4516c;
      color: #fff; }
    .button.outline.danger:active, .button.outline.danger:focus {
      color: #fff;
      border-color: #dc4961;
      background-color: #dc4961;
      outline: 0; }
    .button.outline.danger.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.danger[disabled],
    .button.outline.danger .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #f78598; }

.button.small {
  font-size: strip-unit(1.5rem)-0.3rem;
  padding: 0.7rem 0.8rem; }

.button.smaller {
  font-size: 1.2rem;
  padding: 0.7rem 0.8rem; }

.button.big {
  font-size: strip-unit(1.5rem)0.2rem;
  padding: 1.4rem 1.8rem;
  border-radius: 0.5rem; }

.button.block {
  display: block;
  width: 100%; }
  .button.block + .button.block {
    margin-top: 0.5rem; }

.button.uppercase {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.07em; }

.button-group {
  display: -webkit-flex;
  display: flex; }
  .button-group .button {
    border-radius: 0; }
    .button-group .button:first-of-type {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem; }
    .button-group .button:last-of-type {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem; }
  .button-group .button.outline {
    border-right-width: 0; }
    .button-group .button.outline:last-of-type {
      border-right-width: 0.1rem; }

.button-group-vertical {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center; }
  .button-group-vertical .button {
    width: 100%;
    border-radius: 0; }
    .button-group-vertical .button:first-of-type {
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem; }
    .button-group-vertical .button:last-of-type {
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem; }
  .button-group-vertical .button.outline {
    border-bottom-width: 0; }
    .button-group-vertical .button.outline:last-of-type {
      border-bottom-width: 0.1rem; }

.chart-header {
  margin-bottom: 3rem; }

.chart-title {
  color: #335180;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.2; }
  .chart-title + .chart-subtitle {
    margin-top: 0.5rem; }

.chart-subtitle {
  color: rgba(51, 81, 128, 0.7);
  font-size: 1.6rem;
  line-height: 1.2; }

.chart-total {
  color: #335180;
  font-weight: bold;
  font-size: 3rem; }

.chart-container {
  position: relative; }

/* Base for label styling */
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: -9999px; }

[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label,
[type='checkbox']:not(:checked) + .checkbox-box,
[type='checkbox']:checked + .checkbox-box {
  position: relative;
  padding-left: 2.4rem;
  cursor: pointer; }

/* checkbox aspect */
[type='checkbox']:not(:checked) + label::before,
[type='checkbox']:checked + label::before,
[type='checkbox']:not(:checked) + .checkbox-box::before,
[type='checkbox']:checked + .checkbox-box::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  transition: 0.2s; }

/* checked mark aspect */
[type='checkbox']:not(:checked) + label::after,
[type='checkbox']:checked + label::after,
[type='checkbox']:not(:checked) + .checkbox-box::after,
[type='checkbox']:checked + .checkbox-box::after {
  content: '';
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.2rem;
  background-color: rgba(30, 136, 229, 0.8);
  transition: all 0.2s; }

/* checked mark aspect changes */
[type='checkbox']:not(:checked) + label::after,
[type='checkbox']:not(:checked) + .checkbox-box::after {
  opacity: 0;
  -webkit-transform: scale(0.6);
          transform: scale(0.6); }

[type='checkbox']:checked + label::after,
[type='checkbox']:checked + .checkbox-box::after {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

[type='checkbox']:checked + label::before,
[type='checkbox']:checked + .checkbox-box::before {
  border: 2px solid rgba(30, 136, 229, 0.6); }

/* disabled checkbox */
[type='checkbox']:disabled:not(:checked) + label::before,
[type='checkbox']:disabled:checked + label::before,
[type='checkbox']:disabled:not(:checked) + .checkbox-box::before,
[type='checkbox']:disabled:checked + .checkbox-box::before {
  border-color: #bbb;
  background-color: #ddd; }

[type='checkbox']:disabled:checked + label::after,
[type='checkbox']:disabled:checked + .checkbox-box::after {
  color: #999; }

[type='checkbox']:disabled + label,
[type='checkbox']:disabled + .checkbox-box {
  color: #aaa; }

/* accessibility */
[type='checkbox']:checked:focus + label::before,
[type='checkbox']:not(:checked):focus + label::before,
[type='checkbox']:checked:focus + .checkbox-box::before,
[type='checkbox']:not(:checked):focus + .checkbox-box::before {
  border: 2px solid rgba(30, 136, 229, 0.6);
  box-shadow: 0 0 0 3px rgba(30, 136, 229, 0.2); }

/* hover style just for information */
[type='checkbox'] + label:hover:before,
[type='checkbox'] + .checkbox-box:hover:before {
  border: 2px solid #1e88e5 !important; }

.CheckboxGroup {
  margin-bottom: 1.2rem;
  color: #66696c;
  font-size: 1.4rem;
  font-weight: 500; }
  .CheckboxGroup .label {
    line-height: 1.8rem; }

.page-container {
  background-color: #eee;
  padding: 40px 0;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  height: 100%; }

.content-container {
  max-width: 1200px;
  padding: 0 20px;
  margin: auto; }

.l-form-wrapper--top {
  margin-top: 2rem; }

.form-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .form-wrapper .form-section-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px; }

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-row {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; } }

.form-row--reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  @media only screen and (min-width: 750px) {
    .form-row--reverse {
      -webkit-flex-direction: unset;
              flex-direction: unset; } }

.form-col {
  width: 100%;
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important;
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important; }
  @media only screen and (min-width: 750px) {
    .form-col {
      padding-left: 7px;
      padding-right: 7px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-flex-grow: 0;
              flex-grow: 0; } }
  .form-col:first-of-type {
    padding-left: 0; }
  .form-col:last-of-type {
    padding-right: 0; }

[class^='form-col']:first-of-type {
  padding-left: 0; }

.form-col--1 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--1 {
      padding-left: 7px;
      padding-right: 7px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-flex-grow: 0;
              flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--1 {
      width: calc((1 / 12) * 100%);
      -webkit-flex-basis: calc((1 / 12) * 100%);
              flex-basis: calc((1 / 12) * 100%); } }

.form-col--2 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--2 {
      padding-left: 7px;
      padding-right: 7px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-flex-grow: 0;
              flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--2 {
      width: calc((2 / 12) * 100%);
      -webkit-flex-basis: calc((2 / 12) * 100%);
              flex-basis: calc((2 / 12) * 100%); } }

.form-col--3 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--3 {
      padding-left: 7px;
      padding-right: 7px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-flex-grow: 0;
              flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--3 {
      width: calc((3 / 12) * 100%);
      -webkit-flex-basis: calc((3 / 12) * 100%);
              flex-basis: calc((3 / 12) * 100%); } }

.form-col--4 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--4 {
      padding-left: 7px;
      padding-right: 7px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-flex-grow: 0;
              flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--4 {
      width: calc((4 / 12) * 100%);
      -webkit-flex-basis: calc((4 / 12) * 100%);
              flex-basis: calc((4 / 12) * 100%); } }

.form-col--5 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--5 {
      padding-left: 7px;
      padding-right: 7px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-flex-grow: 0;
              flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--5 {
      width: calc((5 / 12) * 100%);
      -webkit-flex-basis: calc((5 / 12) * 100%);
              flex-basis: calc((5 / 12) * 100%); } }

.form-col--6 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--6 {
      padding-left: 7px;
      padding-right: 7px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-flex-grow: 0;
              flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--6 {
      width: calc((6 / 12) * 100%);
      -webkit-flex-basis: calc((6 / 12) * 100%);
              flex-basis: calc((6 / 12) * 100%); } }

.form-col--7 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--7 {
      padding-left: 7px;
      padding-right: 7px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-flex-grow: 0;
              flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--7 {
      width: calc((7 / 12) * 100%);
      -webkit-flex-basis: calc((7 / 12) * 100%);
              flex-basis: calc((7 / 12) * 100%); } }

.form-col--8 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--8 {
      padding-left: 7px;
      padding-right: 7px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-flex-grow: 0;
              flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--8 {
      width: calc((8 / 12) * 100%);
      -webkit-flex-basis: calc((8 / 12) * 100%);
              flex-basis: calc((8 / 12) * 100%); } }

.form-col--10 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--10 {
      padding-left: 7px;
      padding-right: 7px;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-flex-grow: 0;
              flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--10 {
      width: calc((10 / 12) * 100%);
      -webkit-flex-basis: calc((10 / 12) * 100%);
              flex-basis: calc((10 / 12) * 100%); } }

.form-validation {
  display: none;
  color: inherit;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-top: 0.5rem; }
  .form-validation.is-visible {
    display: block; }

.form-validation-danger {
  display: none;
  color: #f4516c;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-top: 0.5rem; }
  .form-validation-danger.is-visible {
    display: block; }

.form-validation-warning {
  display: none;
  color: #ffb822;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-top: 0.5rem; }
  .form-validation-warning.is-visible {
    display: block; }

.form-group.is-invalid .label {
  color: #f4516c; }

.form-group.is-invalid .form-control {
  border-color: #f4516c; }

.label {
  font-size: 1.6rem;
  line-height: 1;
  display: inline-block;
  margin-bottom: 0.8rem; }
  .label.small {
    font-size: 1.4rem; }

.form-instructions {
  display: block;
  margin-top: 0.5rem;
  color: #888; }

.form-control::-webkit-input-placeholder {
  color: #999; }

.form-control::-ms-input-placeholder {
  color: #999; }

.form-control::placeholder {
  color: #999; }

.form-control {
  display: block;
  color: inherit;
  background-color: #fff;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid #ddd;
  font-weight: 500;
  border-radius: 0.4rem;
  padding: 1rem;
  font-size: 1.6rem;
  line-height: 1.2;
  width: 100%;
  min-height: 3rem;
  transition: 0.2s; }
  .form-control:hover {
    border-color: rgba(51, 81, 128, 0.5); }
  .form-control:focus {
    border-color: #335180;
    outline: none; }
  .form-control[disabled], .form-control[readonly] {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid transparent;
    padding-left: 0 !important;
    pointer-events: none;
    touch-action: none; }
  .form-control + .form-control {
    margin-top: 1rem; }

textarea.form-control {
  height: unset;
  resize: vertical;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1.4; }

select.form-control {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAMAAAALbFwWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAM1BMVEUAAAAvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8AAAAr6/H9AAAAD3RSTlMAMgQ3Evete0Ba/I1+WIMOmTnpAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAADNJREFUCNdFyMcNACAMBMElZ9x/twgDwp/bMRjLOQc+xKMkGUpVJWn7pbqt6q+3fsOYOgszbAE1j++tNQAAAABJRU5ErkJggg==");
  background-position: right 1rem center;
  background-repeat: no-repeat; }

select.select-arrow-light {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAABGdBTUEAALGPC/xhBQAAAJFJREFUGBljYACCa9euGf3//58FxMYFrl69ag6SYwQyhP79+/cUyN6mo6MTzsjI+Add0+XLl6uBBrYA5RzBckBNUZcuXfoDlFiLbhNIMVDu/5UrV3pRDMKmCZtiRmRdIE1///5dBLR6I9Cmi0C5RiYmpj6gU4th6lA0gARhmoBMZnTFME0YNNC9EUB3N2NIAAUANU5evYYF+U4AAAAASUVORK5CYII="); }

select.select-arrow-dark {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAMAAAALbFwWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAM1BMVEUAAAAvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8AAAAr6/H9AAAAD3RSTlMAMgQ3Evete0Ba/I1+WIMOmTnpAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAADNJREFUCNdFyMcNACAMBMElZ9x/twgDwp/bMRjLOQc+xKMkGUpVJWn7pbqt6q+3fsOYOgszbAE1j++tNQAAAABJRU5ErkJggg=="); }

.form-control.small {
  padding: 0.8rem;
  font-size: 1.4rem;
  border-radius: 0.3rem; }

.form-control.big {
  padding: 1.2rem;
  font-size: 1.8rem;
  border-radius: 0.5rem; }

.form-group {
  position: relative;
  margin-bottom: 2rem;
  width: 100%; }
  .form-group.no-margin {
    margin-bottom: 0; }

.input-group {
  display: -webkit-flex;
  display: flex;
  width: 100%; }

.input-group-append {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.input-icon {
  position: relative;
  width: 100%; }
  .input-icon.left .input-icon__icon-container {
    left: 0; }
  .input-icon.left .form-control {
    padding-left: 4rem; }
  .input-icon.right .input-icon__icon-container {
    right: 0; }
  .input-icon.right .form-control {
    padding-right: 4rem; }
    @media only screen and (min-width: 800px) {
      .input-icon.right .form-control {
        padding-right: 3.8rem; } }
  .input-icon .input-icon__icon-container {
    position: absolute;
    height: 100%;
    text-align: center;
    top: 0;
    width: 4rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #66696c;
    font-size: 1.6rem;
    font-weight: 500;
    cursor: default; }

.input-group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 0.4rem; }
  .input-group input.form-control {
    border-radius: 0;
    border: 0; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-content {
  padding-left: 1rem;
  padding-right: 1rem; }

.hidden-input {
  position: absolute;
  height: 0.1px;
  width: 0.1px;
  left: -9999px; }

.master-page-section {
  margin-bottom: 4rem; }

.master-page-title {
  font-size: 2.2rem;
  margin-bottom: 2.5rem; }

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 3rem 2rem;
  overflow: auto;
  transition: .2s;
  z-index: 30;
  opacity: 0;
  visibility: hidden; }
  .Modal.is-active {
    opacity: 1;
    visibility: visible; }
  .Modal.small .Modal-header {
    padding-bottom: 1.5rem; }
  .Modal.small .Modal-title {
    font-size: 1.8rem; }
  .Modal.small .Modal-close {
    width: 1.8rem; }
  .Modal.small .Modal-box {
    max-width: 40rem; }

.Modal .Modal-box {
  transition: .2s;
  -webkit-transform: translate3d(0, -3rem, 0);
          transform: translate3d(0, -3rem, 0); }

.Modal.is-active .Modal-box {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.Modal-bg {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 31; }

.Modal-box {
  background-color: #fff;
  position: relative;
  z-index: 32;
  padding: 2rem;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  max-width: 82rem;
  width: 100%; }

.Modal-header {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media only screen and (min-width: 700px) {
    .Modal-header {
      padding-bottom: 2rem; } }

.Modal-title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2; }
  @media only screen and (min-width: 700px) {
    .Modal-title {
      font-size: 2.4rem; } }

.Modal-close {
  cursor: pointer;
  width: 1.8rem; }
  @media only screen and (min-width: 700px) {
    .Modal-close {
      width: 2rem; } }

.Modal-forgot-password,
.Modal-confirmation {
  text-align: center; }
  .Modal-forgot-password .Modal-content,
  .Modal-confirmation .Modal-content {
    margin: 2rem 0 0; }

.Modal-forgot-password__text,
.Modal-confirmation__text-container {
  margin-bottom: 3rem;
  line-height: 1.2;
  font-size: 1.6rem;
  color: #66696c;
  font-weight: 600; }

.PageTitle {
  color: #335180;
  font-size: 3rem;
  font-weight: 500; }
  @media only screen and (min-width: 800px) {
    .PageTitle {
      font-size: 3.5rem; } }

/* Base for label styling */
[type='radio']:not(:checked),
[type='radio']:checked {
  position: absolute;
  left: -9999px; }

[type='radio']:not(:checked) + label,
[type='radio']:checked + label {
  position: relative;
  padding-left: 2.4rem;
  cursor: pointer; }

/* checkbox aspect */
[type='radio']:not(:checked) + label::before,
[type='radio']:checked + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 50%;
  transition: 0.2s; }

/* checked mark aspect */
[type='radio']:not(:checked) + label::after,
[type='radio']:checked + label::after {
  content: '';
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgba(30, 136, 229, 0.8);
  transition: all 0.2s; }

/* checked mark aspect changes */
[type='radio']:not(:checked) + label::after {
  opacity: 0;
  -webkit-transform: scale(0.6);
          transform: scale(0.6); }

[type='radio']:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

[type='radio']:checked + label::before {
  border: 2px solid rgba(30, 136, 229, 0.6); }

/* disabled checkbox */
[type='radio']:disabled:not(:checked) + label::before,
[type='radio']:disabled:checked + label::before {
  border-color: #bbb;
  background-color: #ddd; }

[type='radio']:disabled:checked + label::after {
  color: #999; }

[type='radio']:disabled + label {
  color: #aaa; }

/* accessibility */
[type='radio']:checked:focus + label::before,
[type='radio']:not(:checked):focus + label::before {
  border: 2px solid rgba(30, 136, 229, 0.6);
  box-shadow: 0 0 0 3px rgba(30, 136, 229, 0.2); }

/* hover style just for information */
[type='radio'] + label:hover:before {
  border: 2px solid #1e88e5 !important; }

.RadioGroup {
  margin-bottom: 1.2rem;
  color: #66696c;
  font-size: 1.4rem;
  font-weight: 500; }
  .RadioGroup .label {
    line-height: 1.8rem; }

.RepasseTab .react-tabs__tab {
  border-radius: 0;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1;
  padding: 1.5rem 1.8rem !important;
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
  -webkit-align-items: center;
          align-items: center; }

.RepasseTab .react-tabs__tab.selected {
  border-radius: 0;
  border-top: .4rem solid #d0021b;
  border-left: .1rem solid #ddd;
  border-right: .1rem solid #ddd;
  background-color: #fff; }

.RepasseTab .react-tabs__tab-list {
  border: .1rem solid #ddd !important;
  border-top: 0 !important;
  background-color: #ebebeb;
  margin-left: -.1rem !important;
  margin-right: -.1rem !important;
  box-shadow: inset 0 1px 0 #ddd; }

.react-tabs__tab.filtro-custom {
  width: 100%;
  text-align: center;
  font-size: 1.8rem; }
  .react-tabs__tab.filtro-custom.selected {
    background-color: #1e88e5;
    color: #fff; }

.responsive-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 !important;
  overflow: hidden; }
  .responsive-embed::before {
    content: "";
    display: block;
    padding-bottom: 100%; }

.responsive-embed-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border: 0;
  object-fit: cover; }

.Site {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh; }

.Site-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto; }

@media only screen and (min-width: 800px) {
  .TitleWSelect {
    display: -webkit-flex;
    display: flex; } }

@media only screen and (min-width: 800px) {
  .TitleWSelect-title-container {
    width: calc(100% - 30rem); } }

@media only screen and (min-width: 800px) {
  .TitleWSelect-select-container {
    width: 30rem; } }

.padding {
  padding: 2rem; }
  .padding-vertical {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    .padding-vertical--smaller {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; }
    .padding-vertical--small {
      padding-top: 1rem;
      padding-bottom: 1rem; }
    .padding-vertical--big {
      padding-top: 4rem;
      padding-bottom: 4rem; }
    .padding-vertical--bigger {
      padding-top: 5rem;
      padding-bottom: 5rem; }
  .padding-horizontal {
    padding-left: 2rem;
    padding-right: 2rem; }
    .padding-horizontal--smaller {
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
    .padding-horizontal--small {
      padding-left: 1rem;
      padding-right: 1rem; }
    .padding-horizontal--big {
      padding-left: 4rem;
      padding-right: 4rem; }
    .padding-horizontal--bigger {
      padding-left: 5rem;
      padding-right: 5rem; }
  .padding-top {
    padding-top: 2rem; }
    .padding-top--smaller {
      padding-top: 0.5rem; }
    .padding-top--small {
      padding-top: 1rem; }
    .padding-top--big {
      padding-top: 4rem; }
    .padding-top--bigger {
      padding-top: 5rem; }
  .padding-bottom {
    padding-bottom: 2rem; }
    .padding-bottom--smaller {
      padding-bottom: 0.5rem; }
    .padding-bottom--small {
      padding-bottom: 1rem; }
    .padding-bottom--big {
      padding-bottom: 4rem; }
    .padding-bottom--bigger {
      padding-bottom: 5rem; }
  .padding-left {
    padding-left: 2rem; }
    .padding-left--smaller {
      padding-left: 0.5rem; }
    .padding-left--small {
      padding-left: 1rem; }
    .padding-left--big {
      padding-left: 4rem; }
    .padding-left--bigger {
      padding-left: 5rem; }
  .padding-right {
    padding-right: 2rem; }
    .padding-right--smaller {
      padding-right: 0.5rem; }
    .padding-right--small {
      padding-right: 1rem; }
    .padding-right--big {
      padding-right: 4rem; }
    .padding-right--bigger {
      padding-right: 5rem; }

.margin {
  margin: 2rem; }
  .margin-vertical {
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .margin-vertical--smaller {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; }
    .margin-vertical--small {
      margin-top: 1rem;
      margin-bottom: 1rem; }
    .margin-vertical--big {
      margin-top: 4rem;
      margin-bottom: 4rem; }
    .margin-vertical--bigger {
      margin-top: 5rem;
      margin-bottom: 5rem; }
  .margin-horizontal {
    margin-left: 2rem;
    margin-right: 2rem; }
    .margin-horizontal--smaller {
      margin-left: 0.5rem;
      margin-right: 0.5rem; }
    .margin-horizontal--small {
      margin-left: 1rem;
      margin-right: 1rem; }
    .margin-horizontal--big {
      margin-left: 4rem;
      margin-right: 4rem; }
    .margin-horizontal--bigger {
      margin-left: 5rem;
      margin-right: 5rem; }
  .margin-top {
    margin-top: 2rem; }
    .margin-top--smaller {
      margin-top: 0.5rem; }
    .margin-top--small {
      margin-top: 1rem; }
    .margin-top--big {
      margin-top: 4rem; }
    .margin-top--bigger {
      margin-top: 5rem; }
  .margin-bottom {
    margin-bottom: 2rem; }
    .margin-bottom--smaller {
      margin-bottom: 0.5rem; }
    .margin-bottom--small {
      margin-bottom: 1rem; }
    .margin-bottom--big {
      margin-bottom: 4rem; }
    .margin-bottom--bigger {
      margin-bottom: 5rem; }
  .margin-left {
    margin-left: 2rem; }
    .margin-left--smaller {
      margin-left: 0.5rem; }
    .margin-left--small {
      margin-left: 1rem; }
    .margin-left--big {
      margin-left: 4rem; }
    .margin-left--bigger {
      margin-left: 5rem; }
  .margin-right {
    margin-right: 2rem; }
    .margin-right--smaller {
      margin-right: 0.5rem; }
    .margin-right--small {
      margin-right: 1rem; }
    .margin-right--big {
      margin-right: 4rem; }
    .margin-right--bigger {
      margin-right: 5rem; }

.no-margin {
  margin: 0; }

.no-margin-vertical {
  margin-top: 0;
  margin-bottom: 0; }

.no-margin-horizontal {
  margin-left: 0;
  margin-right: 0; }

.no-margin-top {
  margin-top: 0; }

.no-margin-bottom {
  margin-bottom: 0; }

.no-margin-left {
  margin-left: 0; }

.no-margin-right {
  margin-right: 0; }

.no-padding {
  padding: 0; }

.no-padding-vertical {
  padding-top: 0;
  padding-bottom: 0; }

.no-padding-horizontal {
  padding-left: 0;
  padding-right: 0; }

.no-padding-top {
  padding-top: 0; }

.no-padding-bottom {
  padding-bottom: 0; }

.no-padding-left {
  padding-left: 0; }

.no-padding-right {
  padding-right: 0; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.padding-header-full {
  padding-top: 109px; }
  @media only screen and (min-width: 800px) {
    .padding-header-full {
      padding-top: 194px; } }
  @media only screen and (min-width: 1200px) {
    .padding-header-full {
      padding-top: 198px; } }

.padding-header {
  padding-top: 69px; }
  @media only screen and (min-width: 800px) {
    .padding-header {
      padding-top: 135px; } }
  @media only screen and (min-width: 1200px) {
    .padding-header {
      padding-top: 138px; } }

.components-container {
  border-top: 1px solid #818181;
  display: block;
  margin-top: 20px;
  padding: 50px 20px;
  width: 100%; }
  .components-container .component-example {
    background-color: #f8f8f8;
    display: block;
    margin: 50px auto;
    padding: 30px;
    width: 100%; }
    .components-container .component-example .component-example-title, .components-container .component-example .component-example-description {
      display: block;
      margin-bottom: 30px;
      width: 100%; }
    .components-container .component-example .components-example-item {
      background-color: #fff;
      box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
      display: block;
      padding: 20px;
      margin-bottom: 30px; }
    .components-container .component-example .example-item-row {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap; }
    .components-container .component-example .w-3 {
      display: block;
      margin-right: 30px;
      width: 30%; }

.alert {
  border-radius: 3px;
  display: block;
  margin-bottom: 20px;
  padding: 10px 15px;
  width: 100%; }
  .alert .alert-msg {
    display: block;
    font-size: 1.4rem; }
  .alert.primary {
    border: 1px solid #7f8c8d;
    background-color: #ecf0f1;
    color: #2c3e50; }
  .alert.success {
    border: 1px solid #27ae60;
    background-color: rgba(46, 204, 113, 0.1);
    color: #27ae60; }
  .alert.error {
    border: 1px solid #c0392b;
    background-color: rgba(231, 76, 60, 0.1);
    color: #c0392b; }
  .alert.warning {
    border: 1px solid #e67e22;
    background-color: rgba(241, 196, 15, 0.1);
    color: #e67e22; }
  .alert.only-message {
    border: 0;
    background-color: transparent;
    padding: 0; }

.Button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  border-radius: 3px;
  margin: 0 5px 20px;
  padding: 5px 25px;
  color: white;
  min-width: 200px; }
  .Button.primary {
    background-color: #2c3e50; }
    .Button.primary-outline {
      background-color: transparent;
      border: 1px solid #2c3e50;
      color: #2c3e50; }
  .Button.cancel {
    background-color: #bdc3c7; }
    .Button.cancel-outline {
      background-color: transparent;
      border: 1px solid #bdc3c7;
      color: #bdc3c7; }
  .Button.success {
    background-color: #27ae60; }
    .Button.success-outline {
      background-color: transparent;
      border: 1px solid #27ae60;
      color: #27ae60; }
  .Button.error {
    background-color: #c0392b; }
    .Button.error-outline {
      background-color: transparent;
      border: 1px solid #c0392b;
      color: #c0392b; }
  .Button.warning {
    background-color: #e67e22; }
    .Button.warning-outline {
      background-color: transparent;
      border: 1px solid #e67e22;
      color: #e67e22; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.collapse-bar {
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  display: block;
  width: 100%; }

.collapse-bar-title {
  font-size: 1.5rem;
  font-weight: 600;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .collapse-bar-title svg {
    color: #335180;
    transition: .4s; }

.collapse-container {
  padding: 20px 10px; }

.collapse-bar.is-open .collapse-bar-title svg {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

.SpinnerFull {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: .4s; }
  .SpinnerFull.is-active {
    opacity: 1;
    visibility: visible; }
    .SpinnerFull.is-active .SpinnerFull-box {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }

.SpinnerFull-bg {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 31; }

.SpinnerFull-box {
  background-color: #ffffff;
  position: relative;
  z-index: 32;
  padding: 3rem 5rem;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2.5rem, 0);
          transform: translate3d(0, -2.5rem, 0);
  transition: .4s; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.FormGroupInputNumber .input-group-content {
  color: #335180;
  font-size: 1.5rem; }

.FormGroupInputNumber svg {
  display: block; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.main-header-container {
  display: block;
  width: 100%;
  background-color: #335180;
  position: fixed;
  top: 0;
  z-index: 20; }
  .main-header-container .main-header {
    padding: 1.5rem 2rem; }
    @media only screen and (min-width: 800px) {
      .main-header-container .main-header {
        padding: 2rem; } }
    .main-header-container .main-header.has-border {
      border-bottom: 4px solid red; }
  .main-header-container .inner-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    max-width: 110rem;
    margin: auto; }
    @media only screen and (min-width: 800px) {
      .main-header-container .inner-container {
        max-width: calc(110rem + 4rem); } }
  .main-header-container .main-header-logo {
    display: block;
    max-width: 14rem;
    width: 100%; }
    @media only screen and (min-width: 1200px) {
      .main-header-container .main-header-logo {
        margin: 0;
        max-width: 18rem; } }
  @media only screen and (min-width: 800px) {
    .main-header-container .burger-icon-container {
      display: none; } }
  .main-header-container .main-header-action-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-left: auto; }
    @media only screen and (max-width: 799px) {
      .main-header-container .main-header-action-container {
        display: none; } }
  @media only screen and (min-width: 800px) {
    .main-header-container .main-header-menu {
      margin-top: 1.5rem;
      margin-left: auto;
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; } }

.main-subheader {
  background: #fff;
  padding: 1rem 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }
  @media only screen and (min-width: 800px) {
    .main-subheader {
      padding: 1.5rem 0;
      display: block; } }

.main-subheader-container {
  max-width: 120rem;
  padding: 0 2rem;
  margin: auto; }

.title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.breadcrumbs-container {
  margin-top: 0.5rem; }

.infos-container {
  display: none;
  margin-top: 0 !important; }
  @media only screen and (min-width: 800px) {
    .infos-container {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.main-header-menu {
  position: absolute;
  top: 6.9rem;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  background-color: #2b456d; }
  @media only screen and (min-width: 800px) {
    .main-header-menu {
      position: relative;
      top: 0;
      width: auto;
      background-color: transparent; } }
  .main-header-menu ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding: 20px;
    height: 120vh;
    overflow: hidden;
    text-align: left; }
    @media only screen and (min-width: 800px) {
      .main-header-menu ul {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        padding: 0;
        height: unset; } }
  .main-header-menu li {
    color: #fff;
    cursor: pointer;
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1; }
    @media only screen and (max-width: 799px) {
      .main-header-menu li {
        margin: 1rem 0; } }
    @media only screen and (min-width: 800px) {
      .main-header-menu li {
        font-size: 1.5rem;
        margin-right: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.03em; }
        .main-header-menu li:last-of-type {
          margin-right: 0; } }
    @media only screen and (min-width: 1000px) {
      .main-header-menu li {
        font-size: 1.6rem;
        margin-right: 2rem; } }
  .main-header-menu a {
    display: block;
    color: #fff;
    padding: .5rem;
    font-weight: 600;
    transition: .2s; }
    .main-header-menu a:hover {
      opacity: 0.7; }
  .main-header-menu .button {
    font-size: inherit;
    padding: 1rem;
    text-align: center;
    border-radius: .5rem; }

.menu-controls-mobile-appearance {
  display: block; }
  @media (min-width: 1200px) {
    .menu-controls-mobile-appearance {
      display: none; } }

.usuario-bar {
  margin-left: 20px;
  position: relative; }
  .usuario-bar .nome-usuario {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: white; }
    .usuario-bar .nome-usuario .nome {
      padding-right: 10px;
      font-size: 1.5rem;
      letter-spacing: 0.03em;
      cursor: pointer; }
    .usuario-bar .nome-usuario .icone-dropdown {
      margin-right: 20px;
      cursor: pointer; }
  .usuario-bar .botao-novidades {
    border: 1px solid #ffffff;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 30px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    transition: 0.4s;
    font-weight: 600; }
    .usuario-bar .botao-novidades:hover {
      background-color: #fff;
      color: #335180; }
  .usuario-bar .swiper-container {
    position: relative;
    padding-bottom: 40px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -16px; }
    .usuario-bar .swiper-container .swiper-pagination {
      bottom: 0; }

.modal-usuario {
  width: 200px;
  position: absolute;
  bottom: -190px;
  right: 6rem;
  background-color: #fff;
  z-index: 999900;
  height: 190px;
  border-radius: 4px;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.3); }
  .modal-usuario .icone-caret {
    position: absolute;
    top: -16px;
    right: 2px;
    font-size: 22px;
    color: white; }
  .modal-usuario .titulo-modal {
    padding: 10px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 2px solid #d8d8d8;
    background-color: #fafafa;
    color: #37474f; }
    .modal-usuario .titulo-modal .botao-fechar {
      font-weight: bold;
      color: #1E88E5;
      cursor: pointer; }
  .modal-usuario .listagem-dropdown {
    height: 100%; }
    .modal-usuario .listagem-dropdown .dropdown-item {
      text-align: left;
      padding: 10px;
      border-bottom: 1px solid #dfdfdf;
      cursor: pointer;
      color: #416592;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px; }
      .modal-usuario .listagem-dropdown .dropdown-item:hover {
        opacity: 0.7; }
    .modal-usuario .listagem-dropdown a {
      color: inherit; }

.swiper-button-prev-novidades {
  position: absolute;
  width: 18px;
  left: 20px;
  bottom: 00px;
  margin-top: 30px;
  z-index: 9999; }

.swiper-button-next-novidades {
  position: absolute;
  width: 18px;
  right: 20px;
  bottom: 00px;
  margin-top: 30px;
  z-index: 9999; }

.slide-novidades-item {
  width: 100%; }
  .slide-novidades-item img {
    width: 100%;
    object-fit: cover; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.non-avatar {
  border-radius: 50%;
  font-size: 16px;
  width: 40px;
  height: 40px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  background-color: #8097bb;
  color: #335180;
  font-weight: bold;
  cursor: default; }


.burger-icon-container {
  cursor: pointer;
  display: block;
  padding: 0.5em;
  background-color: transparent;
  border: 0; }

.burger-icon {
  position: relative;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .burger-icon, .burger-icon::before, .burger-icon::after {
    display: block;
    width: 2.5rem;
    height: 0.3rem;
    background-color: #222;
    outline: 1px solid transparent;
    transition-property: background-color, -webkit-transform;
    transition-property: background-color, transform;
    transition-property: background-color, transform, -webkit-transform;
    transition-duration: 0.3s; }
  .burger-icon::before, .burger-icon::after {
    position: absolute;
    content: ""; }
  .burger-icon::before {
    top: -0.7rem; }
  .burger-icon::after {
    top: 0.7rem; }
  .burger-icon.white, .burger-icon.white::before, .burger-icon.white::after {
    background-color: #fff; }

.burger-icon-container.is-active .burger-icon {
  background-color: transparent; }
  .burger-icon-container.is-active .burger-icon::before {
    -webkit-transform: translateY(0.7rem) rotate(45deg);
            transform: translateY(0.7rem) rotate(45deg); }
  .burger-icon-container.is-active .burger-icon::after {
    -webkit-transform: translateY(-0.7rem) rotate(-45deg);
            transform: translateY(-0.7rem) rotate(-45deg); }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.InfoItem {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 1.5rem; }
  .InfoItem:last-of-type {
    margin-right: 0; }

.InfoItem-valor {
  color: #335180;
  font-weight: bold;
  font-size: 2.8rem;
  margin-right: .5rem;
  line-height: 1; }

.InfoItem-texto {
  color: #66696c;
  font-size: 1.2rem;
  line-height: 1; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.Breadcrumbs {
  display: -webkit-flex;
  display: flex;
  font-size: 1.4rem; }
  @media only screen and (min-width: 800px) {
    .Breadcrumbs {
      font-size: 1.6rem; } }
  .Breadcrumbs .titulo-breadcrumb {
    color: #2B4F81; }
    .Breadcrumbs .titulo-breadcrumb.atual {
      font-weight: bold;
      color: #2b4f81; }
    .Breadcrumbs .titulo-breadcrumb:hover {
      text-decoration: underline; }
  .Breadcrumbs .separador {
    color: #999;
    margin-left: 1rem;
    margin-right: 1rem; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.InteressadoRow {
  border-bottom: .1rem solid #d8d8d8;
  padding: 4rem 2rem;
  width: 100%; }
  .InteressadoRow:last-of-type {
    border-bottom: 0; }
  @media only screen and (min-width: 1200px) {
    .InteressadoRow {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      padding: 2rem; } }

.InteressadoRow.is-lite {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0;
  border-bottom: 0;
  margin: 1.5rem 0; }
  .InteressadoRow.is-lite .InteressadoRow-item {
    margin-bottom: 0; }
  .InteressadoRow.is-lite .InteressadoRow-item:last-of-type {
    margin-left: auto;
    width: 7rem; }
    @media only screen and (min-width: 800px) {
      .InteressadoRow.is-lite .InteressadoRow-item:last-of-type {
        width: auto; } }
  .InteressadoRow.is-lite .InteressadoRow-avatar-container {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem; }
    @media only screen and (min-width: 800px) {
      .InteressadoRow.is-lite .InteressadoRow-avatar-container {
        width: 4rem;
        height: 4rem; } }
    @media only screen and (min-width: 1200px) {
      .InteressadoRow.is-lite .InteressadoRow-avatar-container {
        margin-right: 0; } }
    .InteressadoRow.is-lite .InteressadoRow-avatar-container .NameInitials {
      font-size: 2rem; }
  .InteressadoRow.is-lite .InteressadoRow-name-number {
    text-align: left; }
    .InteressadoRow.is-lite .InteressadoRow-name-number .InteressadoRow-name {
      font-size: 1.4rem; }
    .InteressadoRow.is-lite .InteressadoRow-name-number .InteressadoRow-number-container {
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
  @media only screen and (min-width: 800px) {
    .InteressadoRow.is-lite .InteressadoRow-icon-field-container {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center; } }
  .InteressadoRow.is-lite .InteressadoRow-icon-field-container .InteressadoRow-icon {
    width: 2rem; }
    @media only screen and (min-width: 800px) {
      .InteressadoRow.is-lite .InteressadoRow-icon-field-container .InteressadoRow-icon {
        margin-right: 1rem; } }
  @media only screen and (min-width: 800px) {
    .InteressadoRow.is-lite .InteressadoRow-icon-field-container .InteressadoRow-field {
      -webkit-flex: none;
              flex: none; } }

.InteressadoRow-item {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  text-align: center;
  color: #37474f; }
  .InteressadoRow-item:last-of-type {
    margin-bottom: 0; }
  @media only screen and (min-width: 1200px) {
    .InteressadoRow-item {
      margin-bottom: 0; } }
  .InteressadoRow-item .InteressadoRow-avatar-container {
    margin: auto;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgba(51, 81, 128, 0.1);
    font-weight: 500;
    color: #335180;
    line-height: 1;
    font-size: 2.8rem; }
    @media only screen and (min-width: 1200px) {
      .InteressadoRow-item .InteressadoRow-avatar-container {
        width: 5.5rem;
        height: 5.5rem;
        font-size: 2rem; } }
  .InteressadoRow-item .InteressadoRow-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .InteressadoRow-item .InteressadoRow-name-number .InteressadoRow-name {
    color: #335180;
    font-size: 1.8rem;
    font-weight: 500; }
    @media only screen and (min-width: 1200px) {
      .InteressadoRow-item .InteressadoRow-name-number .InteressadoRow-name {
        font-size: 1.4rem;
        text-align: left; } }
  .InteressadoRow-item .InteressadoRow-name-number .InteressadoRow-number-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    @media only screen and (min-width: 1200px) {
      .InteressadoRow-item .InteressadoRow-name-number .InteressadoRow-number-container {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        text-align: left; } }
  .InteressadoRow-item .InteressadoRow-name-number .InteressadoRow-icon {
    width: 1.4rem;
    margin-right: 0.6rem; }
  .InteressadoRow-item .InteressadoRow-name-number .InteressadoRow-number {
    font-size: 1.4rem;
    color: #78909C; }
    @media only screen and (min-width: 1200px) {
      .InteressadoRow-item .InteressadoRow-name-number .InteressadoRow-number {
        font-size: 1.2rem; } }
  .InteressadoRow-item .InteressadoRow-field-container .InteressadoRow-field {
    font-weight: 500; }
    @media only screen and (min-width: 1200px) {
      .InteressadoRow-item .InteressadoRow-field-container .InteressadoRow-field {
        font-weight: normal; } }
  .InteressadoRow-item .InteressadoRow-field-container .InteressadoRow-field-caption {
    font-size: 1.4rem; }
    @media only screen and (min-width: 1200px) {
      .InteressadoRow-item .InteressadoRow-field-container .InteressadoRow-field-caption {
        display: none; } }
  .InteressadoRow-item .InteressadoRow-icon-field-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    color: inherit;
    margin-left: -2rem; }
    .InteressadoRow-item .InteressadoRow-icon-field-container .InteressadoRow-icon {
      width: 2.4rem;
      margin-bottom: 0.5rem; }
    .InteressadoRow-item .InteressadoRow-icon-field-container .InteressadoRow-field {
      font-size: 1.4rem; }

@media only screen and (min-width: 1200px) {
  .InteressadoRow-item:nth-of-type(1) {
    width: 5.5rem; } }

@media only screen and (min-width: 1200px) {
  .InteressadoRow-item:nth-of-type(2) {
    width: 30rem;
    margin-left: 2rem; } }

@media only screen and (min-width: 1200px) {
  .InteressadoRow-item:nth-of-type(3) {
    width: 18rem; } }

@media only screen and (min-width: 1200px) {
  .InteressadoRow-item:nth-of-type(4) {
    width: 18rem; } }

@media only screen and (min-width: 1200px) {
  .InteressadoRow-item:nth-of-type(5) {
    width: 18rem; } }

@media only screen and (min-width: 1200px) {
  .InteressadoRow-item:nth-of-type(6) {
    width: 7rem;
    margin-left: 6rem; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.InteressadoHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: .1rem solid #d8d8d8;
  padding: 1rem 2rem 2rem; }
  @media only screen and (min-width: 1200px) {
    .InteressadoHeader {
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; } }

.InteressadoHeader.is-lite {
  padding: 0 0 .5rem;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.InteressadoHeader-item {
  color: #37474F;
  font-size: 1.6rem; }

.InteressadoHeader-counter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline; }
  .InteressadoHeader-counter .InteressadoHeader-counter-icon {
    width: 2.2rem;
    margin-right: 1rem; }
  .InteressadoHeader-counter .InteressadoHeader-counter-number {
    color: #335180;
    font-size: 2.2rem;
    font-weight: bold;
    margin-right: 0.7rem; }
  .InteressadoHeader-counter .InteressadoHeader-counter-text {
    color: #78909C;
    font-size: 1.5rem; }

@media only screen and (min-width: 1200px) {
  .InteressadoHeader-item:nth-of-type(1) {
    width: calc(34.5rem + 5.5rem); } }

@media only screen and (max-width: 1199px) {
  .InteressadoHeader-item:nth-of-type(2), .InteressadoHeader-item:nth-of-type(3), .InteressadoHeader-item:nth-of-type(4) {
    display: none; } }

@media only screen and (min-width: 1200px) {
  .InteressadoHeader-item:nth-of-type(2), .InteressadoHeader-item:nth-of-type(3), .InteressadoHeader-item:nth-of-type(4) {
    text-align: center;
    width: 18rem; } }


.footer-bar {
  background-color: #242424;
  border-top: 2px solid red;
  color: white;
  text-align: center; }
  .footer-bar .footer-content {
    text-align: left;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 30px; }
    @media (min-width: 768px) {
      .footer-bar .footer-content {
        display: -webkit-flex;
        display: flex;
        padding: 50px;
        -webkit-justify-content: space-between;
                justify-content: space-between; } }
    @media (min-width: 768px) {
      .footer-bar .footer-content .coluna-esquerda {
        width: 45%; } }
    .footer-bar .footer-content .coluna-esquerda .logo-footer {
      text-align: center;
      width: 300px;
      margin-bottom: 30px; }
      @media (min-width: 768px) {
        .footer-bar .footer-content .coluna-esquerda .logo-footer {
          width: 400px;
          margin: unset;
          margin-bottom: 50px; } }
    .footer-bar .footer-content .coluna-esquerda .store-container {
      margin-bottom: 30px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      margin-left: -20px; }
      .footer-bar .footer-content .coluna-esquerda .store-container .store-container-link {
        margin-left: 2rem;
        border: 1px solid white;
        border-radius: 4px; }
    .footer-bar .footer-content .coluna-direita {
      font-weight: 600; }
      @media (min-width: 768px) {
        .footer-bar .footer-content .coluna-direita {
          width: 17.5%; } }
      .footer-bar .footer-content .coluna-direita .titulo {
        color: #6d6e6e;
        margin-bottom: 5px;
        border: 0;
        padding: 0; }
      .footer-bar .footer-content .coluna-direita .listagem-links {
        padding: 0;
        margin: 0; }
        .footer-bar .footer-content .coluna-direita .listagem-links .link-item {
          padding: 0;
          margin-bottom: 10px; }
      .footer-bar .footer-content .coluna-direita .link-footer {
        color: #fff;
        margin-bottom: 20px; }
        .footer-bar .footer-content .coluna-direita .link-footer:hover {
          text-decoration: underline; }
  .footer-bar .social-bar {
    background: #000;
    text-align: right;
    padding: 20px; }
    .footer-bar .social-bar .container-social {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      margin-right: 10%; }
      @media (min-width: 1367px) {
        .footer-bar .social-bar .container-social {
          margin-right: 20%; } }
      .footer-bar .social-bar .container-social .social-item {
        width: 30px;
        height: 30px;
        margin-left: 20px; }

.social-bar {
  background: #000;
  text-align: right;
  padding: 20px; }
  .social-bar .container-social {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-right: 10%; }
    @media (min-width: 1367px) {
      .social-bar .container-social {
        margin-right: 20%; } }
    .social-bar .container-social .social-item {
      width: 30px;
      height: 30px;
      margin-left: 20px; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.BotaoCarregarMais-container {
  text-align: center; }
  .BotaoCarregarMais-container.small .BotaoCarregarMais {
    width: auto;
    padding-left: 3rem;
    padding-right: 3rem; }
  .BotaoCarregarMais-container .BotaoCarregarMais {
    background-color: #335180;
    color: #fff;
    display: inline-block;
    width: 90%;
    border-radius: 3rem;
    padding: .7rem 1rem;
    font-weight: 600;
    font-size: 1.4rem;
    transition: 0.3s;
    cursor: pointer; }
    .BotaoCarregarMais-container .BotaoCarregarMais:hover {
      color: #335180;
      background-color: #fff; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.MessageBox {
  font-size: 1.6rem;
  border-radius: 0.6rem;
  padding: 1.2rem 1.5rem;
  margin: 0;
  display: inline-block;
  font-weight: 500;
  color: #404040;
  background-color: #e9e9e9;
  border: 0.1rem solid #aaaaaa; }

.MessageBox-icon {
  margin-right: 0.7rem;
  font-size: 1.8rem; }
  .MessageBox-icon svg {
    display: block; }

.MessageBox.primary {
  font-weight: 500;
  color: #263d60;
  background-color: #e4e8ee;
  border: 0.1rem solid #99a8c0; }

.MessageBox.secondary {
  font-weight: 500;
  color: #952f32;
  background-color: #f8e6e7;
  border: 0.1rem solid #e39fa1; }

.MessageBox.info {
  font-weight: 500;
  color: #297ab9;
  background-color: #e5f3fe;
  border: 0.1rem solid #9bd1fb; }

.MessageBox.success {
  font-weight: 500;
  color: #278f7a;
  background-color: #e5f7f3;
  border: 0.1rem solid #9adfd1; }

.MessageBox.warning {
  font-weight: 500;
  color: #b97d00;
  background-color: #fef4de;
  border: 0.1rem solid #fbd380; }
  .MessageBox.warning .MessageBox-icon {
    color: #f7a700; }
  .MessageBox.warning .MessageBox-text {
    color: #333; }

.MessageBox.danger {
  font-weight: 500;
  color: #b73d51;
  background-color: #fee8ec;
  border: 0.1rem solid #faa8b6; }

.MessageBox.small {
  font-size: 1.3rem; }

.StatusLabel {
  font-size: 1.4rem;
  min-width: 180px;
  text-transform: uppercase;
  padding: 4px 12px;
  border: 1px solid currentColor;
  border-radius: 4px;
  line-height: 0.7rem;
  display: inline-block; }
  .StatusLabel.is-lite {
    border: 0;
    padding: 0; }
  .StatusLabel.status-code {
    padding: 0.45em 0.25em;
    text-align: center;
    font-weight: 700;
    border-radius: 20px; }
    .StatusLabel.status-code.status-1 {
      background-color: #76c14d;
      color: #fff; }
    .StatusLabel.status-code.status-2 {
      background-color: #dec53a;
      color: #000; }
    .StatusLabel.status-code.status-3 {
      background-color: #2b4f82;
      color: #fff; }
    .StatusLabel.status-code.status-4 {
      color: #cd3e3e;
      color: #000; }
    .StatusLabel.status-code.status-5 {
      background-color: #6e6f72;
      color: #fff; }
    .StatusLabel.status-code.status-6 {
      color: #dec53a;
      color: #000; }
    .StatusLabel.status-code.status-7 {
      color: #000000; }
    .StatusLabel.status-code.status-8 {
      color: #000000; }
    .StatusLabel.status-code.status-9 {
      background-color: #76c14d;
      color: #fff; }

.Seller {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center; }

.Seller-image {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  overflow: hidden;
  -webkit-flex: none;
          flex: none; }
  .Seller-image + .Seller-info {
    padding-left: 15px; }
    .Seller-image + .Seller-info .Seller-label {
      width: 100%;
      font-size: 1.2rem; }
    .Seller-image + .Seller-info .Seller-name {
      width: 100%;
      line-height: 1.2; }
    .Seller-image + .Seller-info .Seller-phones {
      width: 100px;
      margin-left: 50px; }
      .Seller-image + .Seller-info .Seller-phones .Seller-phone-item {
        width: 100%;
        line-height: 1.2; }

.Seller-info {
  font-size: 1.4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.Seller-label {
  color: #355180;
  margin-right: 5px; }

.Seller.is-lite .Seller-label {
  color: #37474F; }

.action {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #333;
  transition: .2s ease-in-out;
  font-weight: 500;
  cursor: pointer; }
  .action:hover {
    opacity: 0.7; }
  .action.action-link {
    font-weight: 600;
    color: #335180;
    font-size: 1.6rem; }
    .action.action-link .text {
      font-size: 1.6rem; }
  .action .icon + .text {
    margin-left: 8px; }
  .action .text {
    font-size: 1.4rem;
    color: #335180;
    line-height: 1.2; }
    .action .text + .icon {
      margin-left: 8px; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
@media only screen and (min-width: 1200px) {
  .RepasseInfo {
    margin-bottom: 0;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 35rem; } }

.RepasseInfo-titulo {
  color: #335180;
  font-size: 1.8rem;
  line-height: 1.2;
  margin-bottom: .8rem;
  font-weight: 600;
  display: -webkit-flex;
  display: flex; }
  .RepasseInfo-titulo a {
    color: inherit; }

.RepasseInfo-subcontainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.RepasseInfo-subcontainer-link {
  color: #1e88e5; }

.RepasseInfo-item {
  color: #98aab4;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 0.8rem; }

.RepasseInfo-icon-info {
  font-size: 1.4rem;
  line-height: 1.2;
  color: #37474F;
  margin-bottom: 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* &:hover
        text-decoration: underline
        cursor: pointer */ }
  .RepasseInfo-icon-info:last-of-type {
    margin-bottom: 0; }
  .RepasseInfo-icon-info .badge {
    background-color: #D0021B;
    color: white;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 1rem;
    padding: 10px;
    margin-right: 1rem;
    font-weight: bold;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .RepasseInfo-icon-info img,
  .RepasseInfo-icon-info svg {
    margin-right: 1rem; }

.RepasseInfo-vendedor {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #37474F;
  margin: 0;
  width: 50%; }
  .RepasseInfo-vendedor .icon {
    font-size: 1.4rem;
    margin-left: 3px; }
    .RepasseInfo-vendedor .icon + .text {
      margin-left: 11px; }
  .RepasseInfo-vendedor .text {
    font-size: 1.4rem;
    line-height: 1.2;
    word-break: break-word;
    width: 250px; }
    .RepasseInfo-vendedor .text + .icon {
      margin-left: 11px; }

.RepassesItem-info-availability {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 1200px) {
    .RepassesItem-info-availability {
      margin-bottom: 0; } }
  .RepassesItem-info-availability .StatusLabel {
    margin-top: auto; }

.RepasseInfo-stock-link {
  font-size: 1.25rem;
  color: #1e88e5;
  display: block;
  line-height: 1rem;
  margin: 0;
  text-decoration: underline; }

.BarraPorcentagem {
  max-width: 20rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase; }

.RepasseValores {
  color: #37474F; }
  .RepasseValores .BarraPorcentagem-titulo {
    margin-bottom: -.6rem;
    line-height: 1;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase; }

.RepasseValores-destaque {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1;
  margin: 0.4rem 0 0.9rem; }

.RepasseValores-fipe-titulo {
  font-size: 1.2rem;
  margin-bottom: .5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start; }
  .RepasseValores-fipe-titulo img {
    width: 3rem;
    object-fit: contain;
    margin-left: .5rem; }

.RepasseValores-custo-valor {
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.9rem; }

.RepasseValores-fipe-valor {
  font-weight: 600;
  line-height: 1; }

.RepasseValores-button-container {
  text-align: center;
  margin-top: 1.5rem; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.home-container {
  background-color: #f5f5f5;
  min-height: 60vh;
  padding: 10px;
  padding-top: 120px; }
  @media only screen and (min-width: 1200px) {
    .home-container {
      padding-top: 80px; } }
  .home-container .conteudo-home {
    max-width: 1200px;
    padding: 20px;
    margin: auto; }
    @media only screen and (min-width: 1200px) {
      .home-container .conteudo-home {
        padding: 50px; } }
    @media only screen and (min-width: 1200px) {
      .home-container .conteudo-home .linha-topo {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center; } }
    .home-container .conteudo-home .titulo-card {
      color: #2B4F82;
      font-size: 18px; }
    .home-container .conteudo-home .subtitulo-detalhe {
      font-size: 12px;
      margin-bottom: 20px;
      color: #ababab; }

@media only screen and (min-width: 1200px) {
  .flex-container-bottom {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; } }

.flex-container-bottom .coluna-esquerda {
  margin-bottom: 2rem; }
  @media only screen and (min-width: 1200px) {
    .flex-container-bottom .coluna-esquerda {
      width: calc(30% - 1.5rem);
      margin-bottom: 0; } }

@media only screen and (min-width: 1200px) {
  .flex-container-bottom .coluna-direita {
    width: calc(70% - 1.5rem); } }

@media only screen and (min-width: 700px) {
  .container-repasses-home {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .container-repasses-home .RepasseItemHome {
    width: 50%; }
    .container-repasses-home .RepasseItemHome:nth-of-type(odd) {
      padding-right: 2rem;
      border-right: 1px solid #d8d8d8; }
    .container-repasses-home .RepasseItemHome:nth-of-type(even) {
      padding-left: 2rem; } }

@media only screen and (min-width: 1200px) {
  .container-repasses-home .RepasseItemHome {
    width: 100%; } }

@media only screen and (min-width: 800px) {
  .Filtro-home-layout {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

@media only screen and (max-width: 799px) {
  .Filtro-home__sidebar {
    margin-bottom: 1rem; } }

@media only screen and (min-width: 800px) {
  .Filtro-home__sidebar {
    width: 30rem;
    margin-right: 2rem; } }

@media only screen and (min-width: 800px) {
  .Filtro-home__result {
    width: calc(100% - 30rem - 2rem); } }

.Filtro-home__sidebar .label {
  color: #335180; }

.Filtro-home__sidebar .CheckboxGroup .label {
  color: #66696c;
  font-size: 1.4rem; }

.Filtro-home__sidebar .sidebar__vehicle-type-listing {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 2rem; }

.Filtro-home__sidebar .sidebar__vehicle-type-item {
  font-size: 2rem;
  width: 100%; }

.Filtro-home__sidebar .sidebar__title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #335180; }

.Filtro-home__sidebar .sidebar__section {
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem; }
  .Filtro-home__sidebar .sidebar__section:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0; }

.Filtro-home__sidebar .sidebar__section-title {
  color: #66696c;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  letter-spacing: 0.05em; }

.Filtro-home__result .result__title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #335180; }
  .Filtro-home__result .result__title .result__title-obs {
    color: #686a6c;
    display: block;
    font-size: 1.35rem; }
    .Filtro-home__result .result__title .result__title-obs a {
      color: #686a6c;
      text-decoration: underline; }
    .Filtro-home__result .result__title .result__title-obs a:hover {
      color: #335180; }

.Filtro-home__result .result__item {
  border-bottom: 1px solid #ddd;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem; }
  @media only screen and (min-width: 600px) {
    .Filtro-home__result .result__item {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; } }
  .Filtro-home__result .result__item:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0; }

.Filtro-home__result .result__image-container {
  margin-bottom: 2rem;
  width: 100%; }
  @media only screen and (min-width: 1200px) {
    .Filtro-home__result .result__image-container {
      margin-bottom: 0;
      width: 20rem;
      height: 16.5rem;
      margin-right: 3rem; } }

.Filtro-home__result .result__image {
  width: 100%; }
  @media only screen and (min-width: 1200px) {
    .Filtro-home__result .result__image {
      object-fit: cover;
      height: 100%;
      width: 100%; } }

.Filtro-home__result .result__info {
  margin-bottom: 2rem; }
  @media only screen and (min-width: 600px) {
    .Filtro-home__result .result__info {
      width: calc(100% / 2);
      padding-right: 1rem;
      margin-bottom: 0; } }
  @media only screen and (min-width: 1200px) {
    .Filtro-home__result .result__info {
      width: calc(100% / 3); } }

@media only screen and (min-width: 600px) {
  .Filtro-home__result .result__valores {
    width: calc(100% / 2);
    padding-left: 1rem; } }

@media only screen and (min-width: 1200px) {
  .Filtro-home__result .result__valores {
    width: calc(100% / 3); } }

.ModalTermoUso-content {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 1.5rem;
  height: 38rem;
  overflow: auto;
  font-size: 1.6rem; }
  .ModalTermoUso-content p {
    margin-bottom: 1em;
    font-weight: 500;
    color: #666; }
    .ModalTermoUso-content p + h2,
    .ModalTermoUso-content p + h3 {
      margin-top: 1em; }
  .ModalTermoUso-content h2,
  .ModalTermoUso-content h3 {
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0.8em; }
  .ModalTermoUso-content h2 {
    font-size: 3rem; }
  .ModalTermoUso-content h3 {
    font-size: 2rem; }

.ModalTermoUso-button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center; }
  @media only screen and (min-width: 500px) {
    .ModalTermoUso-button-container {
      -webkit-flex-direction: row-reverse;
              flex-direction: row-reverse; } }
  .ModalTermoUso-button-container > button {
    width: 100%;
    margin-top: 0.5rem; }
    @media only screen and (min-width: 500px) {
      .ModalTermoUso-button-container > button {
        width: 20rem;
        margin: 0 1rem; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.TitleAside {
  margin-bottom: 2rem; }
  @media only screen and (min-width: 800px) {
    .TitleAside {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; } }
  .TitleAside.small .TitleAside-title {
    font-size: 2rem; }

.TitleAside-title {
  color: #335180;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  @media only screen and (min-width: 800px) {
    .TitleAside-title {
      padding-right: 2rem;
      margin-bottom: 0; } }

.TitleAside-icon {
  margin-right: 1.5rem; }

.TitleAside-image-container {
  max-width: 3rem;
  margin-right: 1.5rem; }

.TitleAside-image {
  width: 3rem;
  display: block; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.titulo-com-botao {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-weight: bold;
  font-size: 22px;
  color: #335180; }
  .titulo-com-botao .botao-mobile {
    font-size: 28px; }
    @media only screen and (min-width: 768px) {
      .titulo-com-botao .botao-mobile {
        display: none; } }
  .titulo-com-botao .botao-desktop {
    display: none;
    background-color: #335180;
    color: #fff;
    margin: 1.9rem;
    padding: .5rem 1.4rem;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #335180;
    font-weight: 500;
    cursor: pointer;
    transition: .2s; }
    .titulo-com-botao .botao-desktop:hover {
      background-color: #ffffff;
      color: #335180; }
    @media only screen and (min-width: 768px) {
      .titulo-com-botao .botao-desktop {
        display: block; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.RepasseItemHome {
  border-bottom: 1px solid #d8d8d8;
  padding: 2rem 0; }
  @media only screen and (min-width: 1200px) {
    .RepasseItemHome {
      display: -webkit-flex;
      display: flex;
      border-bottom: 0;
      padding-bottom: 0; } }
  @media only screen and (max-width: 699px) {
    .RepasseItemHome:first-of-type {
      padding-top: 0; } }
  @media only screen and (min-width: 1200px) {
    .RepasseItemHome:first-of-type {
      padding-top: 0; } }
  .RepasseItemHome:last-of-type {
    border-bottom: 0;
    padding-bottom: 0; }
    @media only screen and (min-width: 1200px) {
      .RepasseItemHome:last-of-type {
        margin-bottom: 0; } }
  @media only screen and (max-width: 1199px) {
    .RepasseItemHome .RepasseInfo {
      margin-bottom: 2rem; } }
  @media only screen and (min-width: 1200px) {
    .RepasseItemHome .RepasseInfo {
      width: calc(24rem + 4rem); } }
  .RepasseItemHome .RepasseValores {
    padding-bottom: 2rem; }
    @media only screen and (min-width: 1200px) {
      .RepasseItemHome .RepasseValores {
        border-bottom: 0;
        padding-bottom: 0;
        border-right: 1px solid #d8d8d8;
        padding-right: 4rem;
        width: calc(24rem + 4rem); } }
  .RepasseItemHome .InteressadosContainer {
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 1rem;
    max-width: 36rem; }
    @media only screen and (min-width: 1200px) {
      .RepasseItemHome .InteressadosContainer {
        margin-top: 0;
        margin-bottom: 0;
        width: calc(40rem + 4rem);
        padding-left: 4rem;
        padding-right: 2rem;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center; } }

.RepasseItemHome-image-container {
  margin-bottom: 20px; }
  @media only screen and (min-width: 1200px) {
    .RepasseItemHome-image-container {
      width: 20rem;
      height: 20rem;
      margin-right: 3rem;
      margin-bottom: 0; } }
  .RepasseItemHome-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.InteressadosContainer.no-turbo .InteressadosContainer-text {
  opacity: 0.5; }

.InteressadosContainer.no-turbo .button {
  border: 2px solid #e4eaed !important;
  color: #74909e !important; }
  .InteressadosContainer.no-turbo .button small {
    opacity: 0.5; }

.InteressadosContainer-data {
  color: #355180; }
  @media only screen and (min-width: 370px) {
    .InteressadosContainer-data {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center; } }

.InteressadosContainer-data-item {
  text-align: center;
  color: #355180;
  margin-top: 1rem;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 370px) {
    .InteressadosContainer-data-item {
      padding: 0 1rem;
      margin-bottom: 0; } }

.InteressadosContainer-number {
  font-weight: bold;
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 0.7rem; }

.InteressadosContainer-icon-container {
  height: 3rem;
  margin-bottom: 0.7rem; }
  .InteressadosContainer-icon-container img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.InteressadosContainer-text {
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 500; }

.InteressadosContainer-button-container {
  width: 100%;
  margin-top: 20px; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
@media only screen and (min-width: 700px) {
  .ModalPreviaRepasse-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

@media only screen and (min-width: 700px) {
  .ModalPreviaRepasse-content .RepasseInfo-vendedor {
    margin-top: 2rem; } }

.ModalPreviaRepasse-section {
  margin-bottom: 2rem; }
  @media only screen and (min-width: 700px) {
    .ModalPreviaRepasse-section.\--repasse {
      width: 100%;
      display: -webkit-flex;
      display: flex;
      margin-bottom: 4rem; } }
  @media only screen and (min-width: 700px) {
    .ModalPreviaRepasse-section.\--chat {
      width: 50%; } }
  .ModalPreviaRepasse-section.\--chat .InteressadoHeader.is-lite {
    padding-left: 1rem; }
  @media only screen and (min-width: 700px) {
    .ModalPreviaRepasse-section.\--turbo {
      width: calc(100% / 2 - 8rem);
      margin-left: 8rem; } }
  .ModalPreviaRepasse-section.\--turbo .mini-title {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 1.4rem; }
  @media only screen and (min-width: 700px) {
    .ModalPreviaRepasse-section.\--turbo .TurboTab-box {
      margin-top: 2rem; } }
  .ModalPreviaRepasse-section.\--dentro .mini-title, .ModalPreviaRepasse-section.\--fora .mini-title {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 1.4rem;
    margin-bottom: 1.5rem; }
  @media only screen and (min-width: 700px) {
    .ModalPreviaRepasse-section.\--dentro {
      width: 50%; } }
  .ModalPreviaRepasse-section.\--dentro .InteressadoHeader.is-lite {
    padding-left: 1rem; }
  @media only screen and (min-width: 700px) {
    .ModalPreviaRepasse-section.\--fora {
      width: calc(100% / 2 - 8rem);
      margin-left: 8rem; } }
  .ModalPreviaRepasse-section.\--fora .mini-title {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 1.4rem; }

.ModalPreviaRepasse-image-container {
  margin-bottom: 2rem;
  position: relative; }
  @media only screen and (min-width: 700px) {
    .ModalPreviaRepasse-image-container {
      width: 20rem;
      margin-right: 3rem;
      margin-bottom: 0; } }

.ModalPreviaRepasse-image-overlay {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer; }
  .ModalPreviaRepasse-image-overlay img {
    width: 1.5rem;
    margin-right: 0.7rem;
    display: block; }

.ModalPreviaRepasse-info-car {
  margin-bottom: 2rem; }
  @media only screen and (min-width: 700px) {
    .ModalPreviaRepasse-info-car {
      margin-bottom: 0;
      margin-right: 5rem; } }

.ModalPreviaRepasse-info-price {
  margin-bottom: 2rem; }
  @media only screen and (min-width: 700px) {
    .ModalPreviaRepasse-info-price {
      margin-bottom: 0; } }

.ModalPreviaRepasse-cta {
  margin-top: 2rem; }

.banner-turbine {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #335180;
  height: 250px;
  overflow: hidden;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 20px; }
  @media (min-width: 768px) {
    .banner-turbine {
      padding: 0;
      height: 200px; } }
  .banner-turbine .texto-banner {
    width: 100%;
    color: white;
    padding: 20px;
    text-align: center; }
    @media (min-width: 768px) {
      .banner-turbine .texto-banner {
        width: 40%;
        text-align: right; } }
    .banner-turbine .texto-banner .chamada {
      font-weight: bold;
      font-size: 50px;
      line-height: 0.9;
      margin-bottom: 10px; }
      @media (min-width: 768px) {
        .banner-turbine .texto-banner .chamada {
          max-width: 75%;
          margin-left: auto; } }
    .banner-turbine .texto-banner .botao-assinar {
      cursor: pointer;
      border: 1px solid white;
      padding: 5px;
      border-radius: 4px;
      width: 220px;
      text-align: center;
      margin: auto;
      margin-top: 20px;
      transition: 0.3s; }
      @media (min-width: 768px) {
        .banner-turbine .texto-banner .botao-assinar {
          margin: unset;
          margin-left: auto; } }
      .banner-turbine .texto-banner .botao-assinar:hover {
        background-color: white;
        color: #355180;
        font-weight: bold; }
  .banner-turbine .foto-banner {
    width: 60%;
    display: none; }
    @media (min-width: 768px) {
      .banner-turbine .foto-banner {
        display: block; } }

.container-vendas .linha-topo-vendas {
  padding: 20px;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 14px;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .container-vendas .linha-topo-vendas {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
  .container-vendas .linha-topo-vendas .filtro-select {
    width: 60px; }

.custom-select {
  position: relative;
  width: 100%; }
  .custom-select select {
    border: 0;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #fff;
    width: 100%; }
  .custom-select .icone-caret {
    position: absolute;
    right: 0;
    top: 0px;
    pointer-events: none; }

.container-valores {
  height: 240px; }
  .container-valores .titulo {
    text-align: center;
    color: #355180;
    margin-bottom: 20px; }
    .container-valores .titulo .valor {
      font-weight: bold;
      font-size: 24px; }

.listagem-quadrados {
  margin-top: 10px; }
  @media (min-width: 768px) {
    .listagem-quadrados {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; } }
  .listagem-quadrados .quadrado-item {
    margin-top: 10px;
    height: 80px;
    background-color: #355180;
    color: white;
    text-align: center;
    padding: 10px; }
    @media (min-width: 768px) {
      .listagem-quadrados .quadrado-item {
        width: 32%; } }
    .listagem-quadrados .quadrado-item .titulo {
      color: white;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: bold; }
    .listagem-quadrados .quadrado-item .valor {
      font-weight: bold;
      font-size: 15px; }

.container-infograficos {
  margin-top: 40px;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .container-infograficos {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-flex-grow: 1;
              flex-grow: 1; } }
  .container-infograficos .coluna-esquerda {
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .container-infograficos .coluna-esquerda {
        width: 68%; } }
  .container-infograficos .coluna-direita {
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .container-infograficos .coluna-direita {
        width: 30%; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.GestaoEquipeRow {
  border-bottom: .1rem solid #d8d8d8;
  margin: 0;
  padding: 4rem 0; }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin: 0;
      padding: 1.5rem; } }
  .GestaoEquipeRow:first-of-type {
    margin-top: 0;
    padding-top: 1rem; }
  .GestaoEquipeRow:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 1rem; }

.gestao-equipe-avatar-container {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  -webkit-flex: none;
          flex: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: rgba(51, 81, 128, 0.1); }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-avatar-container {
      margin: 0;
      height: 6rem;
      width: 6rem; } }

.gestao-equipe-avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block; }

.gestao-equipe-initials {
  font-weight: bold;
  color: #335180;
  line-height: 1;
  font-size: 2.8rem; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-initials {
      font-size: 2.3rem; } }

.gestao-equipe-name-container {
  text-align: center;
  color: #335180; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-name-container {
      text-align: left; } }

.gestao-equipe-name {
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 1;
  margin-bottom: 0.4rem; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-name {
      font-size: 1.8rem; } }

.gestao-equipe-job-title {
  color: #78909C;
  font-size: 1.5rem;
  line-height: 1; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-job-title {
      font-size: 1.4rem; } }

.gestao-equipe-phones {
  color: #78909C;
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 10px; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-phones {
      font-size: 1.4rem; } }

.gestao-equipe-number {
  text-align: center; }
  .gestao-equipe-number .number {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .gestao-equipe-number .number-variation {
    width: 1.3rem;
    margin-left: 1rem; }
  .gestao-equipe-number .text {
    font-size: 1.6rem;
    line-height: 1;
    margin-top: 1rem; }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-number .text {
        display: none; } }

.gestao-equipe-icon-text {
  color: #37474f;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-icon-text {
      margin: 0 2rem; } }
  .gestao-equipe-icon-text:first-of-type {
    margin-left: 0; }
  .gestao-equipe-icon-text:last-of-type {
    margin-right: 0;
    margin-bottom: 0; }
  .gestao-equipe-icon-text:hover .text-container {
    color: #0e85e8; }
  .gestao-equipe-icon-text .icon-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1.2rem; }
    .gestao-equipe-icon-text .icon-container .text {
      color: #0e85e8;
      margin-right: 0.8rem;
      font-size: 2rem;
      font-weight: 500;
      line-height: 1; }
    .gestao-equipe-icon-text .icon-container .icon {
      height: 2rem; }
  .gestao-equipe-icon-text .text-container {
    line-height: 1;
    font-size: 1.6rem;
    text-align: center; }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-icon-text .text-container {
        font-size: 1.4rem; } }

.gestao-equipe-more-actions-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.gestao-equipe-more-actions {
  color: #335180;
  font-size: 3rem;
  cursor: pointer; }
  .gestao-equipe-more-actions svg {
    display: block; }

.GestaoEquipeRow-item {
  margin-bottom: 3rem; }
  .GestaoEquipeRow-item:last-of-type {
    margin-bottom: 0; }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item {
      margin-bottom: 0; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--avatar {
      margin-right: 2rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--name {
      width: 23rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--sales, .GestaoEquipeRow-item.\--avg-time {
      width: 17rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--view, .GestaoEquipeRow-item.\--edit, .GestaoEquipeRow-item.\--remove {
      margin-left: 4rem;
      margin-right: 4rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--view {
      margin-left: auto; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--remove {
      margin-right: 0; } }

@media only screen and (min-width: 1200px) {
  .GestaoEquipeRow.is-lite .GestaoEquipeRow-item.\--name {
    width: 20rem; } }

@media only screen and (min-width: 1200px) {
  .GestaoEquipeRow.is-lite .GestaoEquipeRow-item.\--sales, .GestaoEquipeRow.is-lite .GestaoEquipeRow-item.\--avg-time {
    width: 15rem; } }

@media only screen and (min-width: 1200px) {
  .GestaoEquipeRow.is-lite .GestaoEquipeRow-item.\--actions {
    margin-left: auto;
    margin-right: 1rem; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.GestaoEquipeHeaderHome {
  padding: 1rem 2rem 2rem;
  border-bottom: .1rem solid #ddd;
  color: #37474F; }

.GestaoEquipeHeaderHome-title-listing {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .GestaoEquipeHeaderHome-title-listing .GestaoEquipeHeaderHome-title {
    font-size: 2rem; }
  .GestaoEquipeHeaderHome-title-listing .GestaoEquipeHeaderHome-select {
    display: -webkit-flex;
    display: flex;
    font-size: 1.5rem;
    cursor: pointer; }
  .GestaoEquipeHeaderHome-title-listing .dropdown-icon {
    color: #2B4F81;
    margin-left: 1.2rem; }

.GestaoEquipeHeaderHome-item-listing {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 2rem; }
  @media only screen and (max-width: 1199px) {
    .GestaoEquipeHeaderHome-item-listing {
      display: none; } }

.GestaoEquipeHeaderHome-item {
  font-size: 1.5rem; }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeHeaderHome-item:nth-of-type(1) {
      width: 27.5rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeHeaderHome-item:nth-of-type(2), .GestaoEquipeHeaderHome-item:nth-of-type(3) {
      text-align: center;
      width: 15rem; } }

.perfil-vendedor {
  text-align: center; }
  .perfil-vendedor .container-foto {
    position: relative; }
    .perfil-vendedor .container-foto .chat-tag {
      background-color: #335180;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      right: 36%;
      border: 3px solid white; }
      .perfil-vendedor .container-foto .chat-tag.offline {
        background-color: #ababab; }
  .perfil-vendedor .foto-circle-crop {
    overflow: hidden;
    width: 100px;
    margin: auto; }
    .perfil-vendedor .foto-circle-crop img {
      border-radius: 50%;
      object-fit: contain;
      width: 100%; }
  .perfil-vendedor .nome-usuario {
    margin-top: 20px;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: -5px; }
  .perfil-vendedor .email-usuario {
    color: #ababab;
    font-size: 14px; }
  .perfil-vendedor .listagem-infos-vendedor {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .perfil-vendedor .listagem-infos-vendedor {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; } }
    .perfil-vendedor .listagem-infos-vendedor .info-item {
      text-align: center;
      display: block;
      padding: 5px;
      padding-bottom: 0; }
      .perfil-vendedor .listagem-infos-vendedor .info-item:nth-child(even) {
        border-right: 0; }
      @media (min-width: 768px) {
        .perfil-vendedor .listagem-infos-vendedor .info-item {
          width: 50%;
          border-right: 1px solid #dfdfdf; } }
      .perfil-vendedor .listagem-infos-vendedor .info-item .titulo {
        width: 100%; }
      .perfil-vendedor .listagem-infos-vendedor .info-item .conteudo {
        font-weight: bold;
        color: #355180;
        font-size: 14px; }
  .perfil-vendedor .exportar-container .titulo {
    text-transform: uppercase;
    font-size: 14px; }
  .perfil-vendedor .exportar-container .link-download {
    text-decoration: underline;
    color: #355180;
    cursor: pointer;
    font-size: 12px; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.ModalPlanos-intro-text {
  color: #66696c;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin: 3rem auto; }
  @media only screen and (min-width: 900px) {
    .ModalPlanos-intro-text {
      margin: 3rem auto 5rem; } }

@media only screen and (min-width: 900px) {
  .ModalPlanos-plano-listing {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

.ModalPlanos-plano-item {
  text-align: center;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: .1rem solid #ddd; }
  .ModalPlanos-plano-item:last-of-type {
    border-bottom: 0;
    margin-bottom: 0; }
  @media only screen and (min-width: 900px) {
    .ModalPlanos-plano-item {
      border-bottom: 0;
      border-right: .1rem solid #ddd;
      width: calc(100% / 3); }
      .ModalPlanos-plano-item:last-of-type {
        border-right: 0; } }

.plano-item__image-container {
  width: 7rem;
  margin: 0 auto 2.5rem; }

.plano-item__image {
  display: block; }

.plano-item__name {
  color: #335180;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1.5rem; }

.plano-item__price {
  line-height: 1;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: -.08em;
  margin-bottom: 1.2rem; }

.plano-item__description {
  color: #66696c;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0 3rem; }

.ModalPlanos-cta {
  color: #1e88e5;
  font-weight: 600;
  text-align: center;
  line-height: 1.3;
  font-size: 1.6rem; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.home-container {
  background-color: #f5f5f5;
  min-height: 60vh;
  padding: 10px;
  padding-top: 120px; }
  @media only screen and (min-width: 1200px) {
    .home-container {
      padding-top: 80px; } }
  .home-container .conteudo-home {
    max-width: 1200px;
    padding: 20px;
    margin: auto; }
    @media only screen and (min-width: 1200px) {
      .home-container .conteudo-home {
        padding: 50px; } }
    @media only screen and (min-width: 1200px) {
      .home-container .conteudo-home .linha-topo {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center; } }
    .home-container .conteudo-home .titulo-card {
      color: #2B4F82;
      font-size: 18px; }
    .home-container .conteudo-home .subtitulo-detalhe {
      font-size: 12px;
      margin-bottom: 20px;
      color: #ababab; }

@media only screen and (min-width: 1200px) {
  .flex-container-bottom {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; } }

.flex-container-bottom .coluna-esquerda {
  margin-bottom: 2rem; }
  @media only screen and (min-width: 1200px) {
    .flex-container-bottom .coluna-esquerda {
      width: calc(30% - 1.5rem);
      margin-bottom: 0; } }

@media only screen and (min-width: 1200px) {
  .flex-container-bottom .coluna-direita {
    width: calc(70% - 1.5rem); } }

@media only screen and (min-width: 700px) {
  .container-repasses-home {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .container-repasses-home .RepasseItemHome {
    width: 50%; }
    .container-repasses-home .RepasseItemHome:nth-of-type(odd) {
      padding-right: 2rem;
      border-right: 1px solid #d8d8d8; }
    .container-repasses-home .RepasseItemHome:nth-of-type(even) {
      padding-left: 2rem; } }

@media only screen and (min-width: 1200px) {
  .container-repasses-home .RepasseItemHome {
    width: 100%; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.GestaoEquipeRow {
  border-bottom: .1rem solid #d8d8d8;
  margin: 0;
  padding: 4rem 0; }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin: 0;
      padding: 1.5rem; } }
  .GestaoEquipeRow:first-of-type {
    margin-top: 0;
    padding-top: 1rem; }
  .GestaoEquipeRow:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 1rem; }

.gestao-equipe-avatar-container {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  -webkit-flex: none;
          flex: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: rgba(51, 81, 128, 0.1); }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-avatar-container {
      margin: 0;
      height: 6rem;
      width: 6rem; } }

.gestao-equipe-avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block; }

.gestao-equipe-initials {
  font-weight: bold;
  color: #335180;
  line-height: 1;
  font-size: 2.8rem; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-initials {
      font-size: 2.3rem; } }

.gestao-equipe-name-container {
  text-align: center;
  color: #335180; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-name-container {
      text-align: left; } }

.gestao-equipe-name {
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 1;
  margin-bottom: 0.4rem; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-name {
      font-size: 1.8rem; } }

.gestao-equipe-job-title {
  color: #78909C;
  font-size: 1.5rem;
  line-height: 1; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-job-title {
      font-size: 1.4rem; } }

.gestao-equipe-phones {
  color: #78909C;
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 10px; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-phones {
      font-size: 1.4rem; } }

.gestao-equipe-number {
  text-align: center; }
  .gestao-equipe-number .number {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .gestao-equipe-number .number-variation {
    width: 1.3rem;
    margin-left: 1rem; }
  .gestao-equipe-number .text {
    font-size: 1.6rem;
    line-height: 1;
    margin-top: 1rem; }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-number .text {
        display: none; } }

.gestao-equipe-icon-text {
  color: #37474f;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-icon-text {
      margin: 0 2rem; } }
  .gestao-equipe-icon-text:first-of-type {
    margin-left: 0; }
  .gestao-equipe-icon-text:last-of-type {
    margin-right: 0;
    margin-bottom: 0; }
  .gestao-equipe-icon-text:hover .text-container {
    color: #0e85e8; }
  .gestao-equipe-icon-text .icon-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1.2rem; }
    .gestao-equipe-icon-text .icon-container .text {
      color: #0e85e8;
      margin-right: 0.8rem;
      font-size: 2rem;
      font-weight: 500;
      line-height: 1; }
    .gestao-equipe-icon-text .icon-container .icon {
      height: 2rem; }
  .gestao-equipe-icon-text .text-container {
    line-height: 1;
    font-size: 1.6rem;
    text-align: center; }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-icon-text .text-container {
        font-size: 1.4rem; } }

.gestao-equipe-more-actions-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.gestao-equipe-more-actions {
  color: #335180;
  font-size: 3rem;
  cursor: pointer; }
  .gestao-equipe-more-actions svg {
    display: block; }

.GestaoEquipeRow-item {
  margin-bottom: 3rem; }
  .GestaoEquipeRow-item:last-of-type {
    margin-bottom: 0; }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item {
      margin-bottom: 0; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--avatar {
      margin-right: 2rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--name {
      width: 23rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--sales, .GestaoEquipeRow-item.\--avg-time {
      width: 17rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--view, .GestaoEquipeRow-item.\--edit, .GestaoEquipeRow-item.\--remove {
      margin-left: 4rem;
      margin-right: 4rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--view {
      margin-left: auto; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--remove {
      margin-right: 0; } }
  .GestaoEquipeRow-item.\--invite {
    display: -webkit-flex;
    display: flex;
    margin: auto;
    max-width: 30rem; }
    @media only screen and (min-width: 1200px) {
      .GestaoEquipeRow-item.\--invite {
        margin-right: 0; } }
    .GestaoEquipeRow-item.\--invite .button {
      height: 3.4rem;
      margin-left: 1rem; }
    @media only screen and (min-width: 800px) {
      .GestaoEquipeRow-item.\--invite .FormGroupInput {
        width: 20rem;
        margin-left: 2rem; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.Modal-GestaoEquipe {
  font-weight: 500;
  color: #555; }

.message-error {
  width: '100%'; }
  .message-error .result__title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #335180; }

.gestao-equipe-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: .1rem solid #d8d8d8;
  padding: 1rem 2rem 2rem; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-header {
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
  .gestao-equipe-header .gestao-equipe-header-item {
    color: #37474f;
    font-size: 1.6rem; }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-header .gestao-equipe-header-item:nth-of-type(1) {
        -webkit-flex: 0 1 31rem;
                flex: 0 1 31rem; } }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-header .gestao-equipe-header-item:nth-of-type(2), .gestao-equipe-header .gestao-equipe-header-item:nth-of-type(3) {
        -webkit-flex: 0 1 17rem;
                flex: 0 1 17rem;
        text-align: center; } }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-header .gestao-equipe-header-item:nth-of-type(3) {
        margin-right: auto; } }
    @media only screen and (max-width: 1199px) {
      .gestao-equipe-header .gestao-equipe-header-item:not(.is-dropdown) {
        display: none; } }
    .gestao-equipe-header .gestao-equipe-header-item.is-dropdown {
      cursor: pointer;
      display: -webkit-flex;
      display: flex; }
    .gestao-equipe-header .gestao-equipe-header-item .dropdown-icon {
      color: #2B4F81;
      margin-left: 1.2rem; }

.form-select {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 0.5rem; }
  .form-select .checkbox-group {
    padding-left: 2rem;
    padding-right: 2rem; }

.form-search {
  width: 20rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .form-search .button {
    margin-top: 2.5rem;
    height: 2.5rem; }

@media only screen and (min-width: 600px) {
  .cpf-search {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: center;
            justify-content: center; } }

.cpf-search .cpf-search__item {
  margin-bottom: 1rem;
  width: 100%; }
  @media only screen and (min-width: 600px) {
    .cpf-search .cpf-search__item:last-of-type {
      margin-left: 2rem;
      width: 15rem; } }
  @media only screen and (min-width: 600px) {
    .cpf-search .cpf-search__item:first-of-type {
      width: 30rem; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.DetalhesVeiculoTab .mini-title {
  margin-bottom: 2rem; }

.DetalhesVeiculoTab-item {
  margin-bottom: 1.5rem; }
  @media only screen and (min-width: 600px) {
    .DetalhesVeiculoTab-item {
      margin-bottom: 3rem; } }
  .DetalhesVeiculoTab-item:last-of-type {
    margin-bottom: 0; }

.DetalhesVeiculoTab-item-title {
  color: #2f4b76;
  font-size: 1.4rem;
  font-weight: 500; }
  @media only screen and (min-width: 600px) {
    .DetalhesVeiculoTab-item-title {
      margin-bottom: 0.7rem;
      font-size: 1.3rem; } }

.DetalhesVeiculoTab-content {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.6rem;
  font-weight: 500; }
  .DetalhesVeiculoTab-content ul,
  .DetalhesVeiculoTab-content li {
    list-style: disc inside; }
  @media only screen and (min-width: 600px) {
    .DetalhesVeiculoTab-content ul {
      -webkit-column-count: 2;
              column-count: 2; } }
  @media only screen and (min-width: 800px) {
    .DetalhesVeiculoTab-content ul {
      -webkit-column-count: 3;
              column-count: 3; } }

.DetalhesVeiculoTab-listing-obrigatorio {
  margin-bottom: 2rem; }
  @media only screen and (min-width: 700px) {
    .DetalhesVeiculoTab-listing-obrigatorio {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      margin-bottom: 0; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.header-page {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px; }
  @media (min-width: 767px) {
    .header-page {
      margin-bottom: 25px; } }

.header-page-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .header-page-actions .header-page-action {
    margin-right: 20px;
    margin-bottom: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    @media (min-width: 767px) {
      .header-page-actions .header-page-action {
        margin-right: 30px;
        margin-bottom: 0; } }
    .header-page-actions .header-page-action:last-child {
      margin-right: 0; }
    .header-page-actions .header-page-action .spinner {
      margin-right: 1rem; }

.repasse-resume {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  padding: 20px; }
  .repasse-resume .repasse-resume-main-info {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-align-content: flex-start;
            align-content: flex-start; }
    @media (min-width: 900px) {
      .repasse-resume .repasse-resume-main-info {
        width: calc(100% - 21rem);
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap; } }
  .repasse-resume .repasse-image {
    width: 100%;
    margin-bottom: 1.5rem; }
    .repasse-resume .repasse-image img {
      display: block; }
    @media (min-width: 900px) {
      .repasse-resume .repasse-image {
        margin-bottom: 0;
        margin-right: 3rem;
        width: 11rem; } }
  .repasse-resume .repasse-content-info {
    margin-bottom: 2rem; }
    @media (min-width: 900px) {
      .repasse-resume .repasse-content-info {
        margin-right: 3rem;
        margin-bottom: 0;
        width: 21rem; } }
    .repasse-resume .repasse-content-info .icon-info {
      margin-bottom: 0; }
      .repasse-resume .repasse-content-info .icon-info .icon-text {
        font-size: 14px; }
  .repasse-resume .repasse-other-info {
    margin-bottom: 2rem; }
    @media (min-width: 900px) {
      .repasse-resume .repasse-other-info {
        margin-bottom: 0;
        width: 23rem;
        padding-right: 2rem; } }
    .repasse-resume .repasse-other-info .StatusLabel {
      margin-bottom: 1.5rem; }
  @media (min-width: 900px) {
    .repasse-resume .repasse-telefone-info {
      width: 20rem;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-align-items: center;
              align-items: center;
      margin-top: 3rem; } }
  .repasse-resume .repasse-telefone-info .ActionButton {
    width: 100%; }
  .repasse-resume .repasse-resume-values {
    width: 100%; }
    @media (min-width: 900px) {
      .repasse-resume .repasse-resume-values {
        width: 21rem; } }
  .repasse-resume .Seller {
    margin-bottom: 1.5rem; }

.ModalGaleria-SwiperGallery .swiper-pagination {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 1.5rem;
  line-height: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 6rem;
  margin: auto;
  padding: 0.6rem 0;
  border-radius: 3px 3px 0 0;
  left: 0;
  right: 0;
  bottom: 0; }

.ModalGaleria-SwiperGallery .swiper-button-prev,
.ModalGaleria-SwiperGallery .swiper-button-next {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 6rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-top: 0; }
  @media only screen and (min-width: 800px) {
    .ModalGaleria-SwiperGallery .swiper-button-prev,
    .ModalGaleria-SwiperGallery .swiper-button-next {
      width: 3.5rem;
      height: 7rem; } }
  .ModalGaleria-SwiperGallery .swiper-button-prev img,
  .ModalGaleria-SwiperGallery .swiper-button-next img {
    width: 1.5rem; }
    @media only screen and (min-width: 800px) {
      .ModalGaleria-SwiperGallery .swiper-button-prev img,
      .ModalGaleria-SwiperGallery .swiper-button-next img {
        width: 1.7rem; } }

.ModalGaleria-SwiperGallery .swiper-button-next {
  right: 0;
  border-radius: 5px 0 0 5px; }

.ModalGaleria-SwiperGallery .swiper-button-prev {
  left: 0;
  border-radius: 0 5px 5px 0; }

.ModalGaleria-SwiperGallery-image {
  width: 100%; }

.ModalGaleria-SwiperThumbnail {
  margin-top: 1rem; }
  .ModalGaleria-SwiperThumbnail .swiper-button-prev,
  .ModalGaleria-SwiperThumbnail .swiper-button-next {
    background-image: none;
    background-color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 101%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-top: 0; }
    @media only screen and (min-width: 800px) {
      .ModalGaleria-SwiperThumbnail .swiper-button-prev,
      .ModalGaleria-SwiperThumbnail .swiper-button-next {
        width: 4rem; } }
    .ModalGaleria-SwiperThumbnail .swiper-button-prev img,
    .ModalGaleria-SwiperThumbnail .swiper-button-next img {
      width: 1.2rem; }
      @media only screen and (min-width: 800px) {
        .ModalGaleria-SwiperThumbnail .swiper-button-prev img,
        .ModalGaleria-SwiperThumbnail .swiper-button-next img {
          width: 1.7rem; } }
  .ModalGaleria-SwiperThumbnail .swiper-button-next {
    right: 0; }
  .ModalGaleria-SwiperThumbnail .swiper-button-prev {
    left: 0; }

.ModalGaleria-SwiperThumbnail-item {
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 4px; }
  .ModalGaleria-SwiperThumbnail-item.swiper-slide-active {
    border-color: #1e88e5; }

.RepasseTab-Informacoes {
  padding: 2rem; }
  @media only screen and (min-width: 1100px) {
    .RepasseTab-Informacoes {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; } }
  .RepasseTab-Informacoes .DetalhesVeiculoTab {
    padding-bottom: 3rem;
    border-bottom: 1px solid #d8d8d8; }
    @media only screen and (min-width: 1100px) {
      .RepasseTab-Informacoes .DetalhesVeiculoTab {
        border-bottom: 0;
        padding-right: 5rem;
        padding-bottom: 0;
        width: calc(100% - 35rem); } }
  .RepasseTab-Informacoes .TurboTab {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #d8d8d8; }
    @media only screen and (min-width: 1100px) {
      .RepasseTab-Informacoes .TurboTab {
        border-bottom: 0;
        margin-top: 0;
        padding-left: 3rem;
        padding-right: 2rem;
        padding-bottom: 0;
        width: 35rem;
        border-left: 1px solid #d8d8d8; } }

.GaleriaFotosTab {
  width: 100%; }
  @media only screen and (min-width: 1100px) {
    .GaleriaFotosTab {
      margin-top: 5rem; } }
  .GaleriaFotosTab .mini-title {
    margin-bottom: 2rem; }

.GaleriaFotos-listing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

.GaleriaFotos-item {
  width: calc(100% / 2);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
  cursor: pointer; }
  @media only screen and (min-width: 600px) {
    .GaleriaFotos-item {
      width: calc(100% / 3); } }
  @media only screen and (min-width: 800px) {
    .GaleriaFotos-item {
      width: calc(100% / 5); } }
  @media only screen and (min-width: 1000px) {
    .GaleriaFotos-item {
      width: calc(100% / 7); } }
  @media only screen and (min-width: 1200px) {
    .GaleriaFotos-item {
      width: calc(100% / 10); } }

.chat-info-bar .botao-toggle {
  background-color: #fff;
  border: 0;
  color: #37474F;
  font-size: 12px;
  padding-left: 20px;
  text-transform: uppercase;
  font-weight: bold; }

.chat-info-bar .container-flex-info-chat {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 10px;
  padding: 20px; }
  @media (min-width: 1200px) {
    .chat-info-bar .container-flex-info-chat {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-end;
              align-items: flex-end; } }
  .chat-info-bar .container-flex-info-chat .foto-veiculo {
    width: 150px;
    margin-right: 30px; }
  .chat-info-bar .container-flex-info-chat .desc-veiculo {
    margin-right: 30px; }
    .chat-info-bar .container-flex-info-chat .desc-veiculo .nome-veiculo {
      color: #37474F;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px; }
    .chat-info-bar .container-flex-info-chat .desc-veiculo .detalhe-veiculo {
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 5px; }
    .chat-info-bar .container-flex-info-chat .desc-veiculo .status-veiculo {
      font-size: 14px;
      color: #37474f;
      border: 1px solid #37474f;
      text-align: center;
      padding: 5px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 4px;
      width: 180px; }
  .chat-info-bar .container-flex-info-chat .proposta-container {
    margin-top: 10px; }
    .chat-info-bar .container-flex-info-chat .proposta-container .texto-proposta {
      font-size: 14px;
      margin-bottom: 20px; }
    .chat-info-bar .container-flex-info-chat .proposta-container .vendedor-container {
      display: -webkit-flex;
      display: flex; }
      .chat-info-bar .container-flex-info-chat .proposta-container .vendedor-container .foto-vendedor {
        width: 40px;
        overflow: hidden; }
        .chat-info-bar .container-flex-info-chat .proposta-container .vendedor-container .foto-vendedor img {
          border-radius: 50%; }
      .chat-info-bar .container-flex-info-chat .proposta-container .vendedor-container .info-vendedor {
        margin-left: 15px; }
        .chat-info-bar .container-flex-info-chat .proposta-container .vendedor-container .info-vendedor .titulo-info-vendedor {
          color: #37474f;
          font-weight: bold;
          font-size: 14px; }
        .chat-info-bar .container-flex-info-chat .proposta-container .vendedor-container .info-vendedor .nome-info-vendedor {
          font-size: 14px; }
  .chat-info-bar .container-flex-info-chat .botao-anunciar {
    margin-top: 20px; }
    @media (min-width: 1200px) {
      .chat-info-bar .container-flex-info-chat .botao-anunciar {
        margin-left: auto;
        margin-top: 0; } }

.mensagem-item-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-right: auto;
  margin-bottom: 20px; }
  .mensagem-item-container.right {
    margin-left: auto;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse; }
    .mensagem-item-container.right .foto-usuario {
      margin-right: 0;
      margin-left: 10px; }
    .mensagem-item-container.right .horario-mensagem {
      margin-left: 0;
      margin-right: 5px; }
    .mensagem-item-container.right .conteudo-mensagem {
      background-color: #1E88E5;
      color: #fff;
      border-color: #1E88E5; }
  .mensagem-item-container .foto-usuario {
    width: 20px;
    margin-right: 10px;
    overflow: hidden; }
    @media (min-width: 768px) {
      .mensagem-item-container .foto-usuario {
        width: 30px; } }
    .mensagem-item-container .foto-usuario img {
      margin: 0 auto;
      height: auto;
      width: 150%;
      border-radius: 50%;
      object-fit: cover; }
  .mensagem-item-container .conteudo-mensagem {
    padding: 10px;
    background-color: #d8d8d8;
    width: 250px;
    border-radius: 6px;
    border: 1px solid #ababab;
    font-size: 14px; }
  .mensagem-item-container .horario-mensagem {
    margin-left: 5px;
    font-size: 8px; }

.container-mensagens {
  padding: 20px;
  padding-top: 30px;
  overflow: auto;
  height: 300px;
  background-color: #fff; }
  @media (min-width: 1200px) {
    .container-mensagens {
      height: 430px; } }
  .container-mensagens .tag-dia {
    text-align: center;
    font-size: 12px;
    color: #ababab;
    margin-bottom: 30px; }
  .container-mensagens .audio-item {
    width: 220px;
    border-radius: 4px;
    margin-bottom: 0;
    background-color: #f0f0f0;
    padding-right: 20px; }

.titulo {
  padding: 20px;
  border-bottom: 1px solid #d8d8d8; }
  @media (min-width: 1024px) {
    .titulo {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      padding-bottom: 35px;
      -webkit-align-items: center;
              align-items: center;
      height: 75px; } }
  .titulo .nome-usuario {
    color: #3B4A52;
    font-weight: 500;
    font-size: 26px; }
  .titulo .info {
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    -webkit-align-items: center;
            align-items: center; }
    .titulo .info .proposta {
      margin-left: 5px;
      margin-right: 35px;
      color: #3b4a52;
      font-weight: 500; }
    .titulo .info .icone {
      color: #1E88E5;
      margin-left: 20px; }

.box-chat-item {
  height: 100%; }

.input-chat-container {
  padding: 10px;
  position: relative;
  border-top: 1px solid #d8d8d8;
  margin-top: 10px; }
  .input-chat-container input {
    width: 100%;
    height: 40px;
    border-radius: 12px;
    border: 0;
    border: 1px solid #d8d8d8;
    padding-left: 20px;
    font-size: 14px;
    background-color: #d8d8d8; }
  .input-chat-container .icone-enviar {
    position: absolute;
    top: 20px;
    right: 30px;
    color: #9b9b9b;
    cursor: pointer; }
  .input-chat-container .icone-anexo {
    position: absolute;
    top: 20px;
    right: 70px;
    color: #9b9b9b;
    cursor: pointer; }

.rounded-search-input {
  position: relative;
  height: 40px;
  width: 100%; }
  .rounded-search-input input {
    width: 100%;
    height: 100%;
    border: 0;
    padding-left: 10px;
    border: 1px solid #ababab;
    border-radius: 4px; }
  .rounded-search-input .icone {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #ababab; }

.usuario-item-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px;
  width: 260px; }
  .usuario-item-container:last-child {
    border-bottom: 0; }
  .usuario-item-container.ativo {
    background-color: #efefef; }
  .usuario-item-container .foto-usuario {
    border-radius: 50%;
    overflow: hidden;
    width: 50px;
    height: 50px; }
  .usuario-item-container .info-usuario {
    margin-left: 15px; }
    .usuario-item-container .info-usuario .nome-usuario {
      position: relative;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .usuario-item-container .info-usuario .nome-usuario .texto {
        font-weight: bold;
        color: #2B4F81;
        margin-right: 10px;
        font-size: 14px; }
      .usuario-item-container .info-usuario .nome-usuario .badge {
        background-color: #D0021B;
        color: white;
        width: 2rem;
        height: 2rem;
        padding: 6px;
        border-radius: 50%;
        font-size: 0.9rem;
        padding: 12px;
        font-weight: bold;
        text-align: center;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; }
    .usuario-item-container .info-usuario .info-line {
      display: -webkit-flex;
      display: flex;
      font-size: 14px; }
      .usuario-item-container .info-usuario .info-line .icone {
        color: #1E88E5;
        margin-right: 12px;
        font-size: 14px;
        margin-left: 4px; }
        .usuario-item-container .info-usuario .info-line .icone.phone {
          margin-left: 0;
          font-size: 12px;
          margin-right: 12px;
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg); }
      .usuario-item-container .info-usuario .info-line .texto {
        font-size: 12px; }

.tab-chat-container {
  height: 100%; }
  @media (min-width: 1024px) {
    .tab-chat-container .container-flex {
      display: -webkit-flex;
      display: flex; } }
  @media (min-width: 1024px) {
    .tab-chat-container .container-flex .coluna-esquerda {
      width: 24.5%;
      border-right: 1px solid #d8d8d8;
      padding-right: 2%; } }
  @media (min-width: 1024px) {
    .tab-chat-container .container-flex .coluna-direita {
      width: 75.5%; } }
  .tab-chat-container .container-pesquisa {
    border-bottom: 1px solid #d8d8d8;
    padding: 10px;
    height: 75px; }
  .tab-chat-container .container-usuarios {
    width: 100%;
    height: 100px;
    overflow-y: auto;
    display: -webkit-flex;
    display: flex; }
    @media (min-width: 1024px) {
      .tab-chat-container .container-usuarios {
        display: block;
        height: auto;
        max-height: 500px; } }
    .tab-chat-container .container-usuarios .usuario-item {
      margin-right: 20px;
      cursor: pointer; }
  .tab-chat-container .container-empty-list {
    margin: 20px; }

.IconText {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.IconText-icon-container {
  margin-bottom: 1rem;
  height: 2.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .IconText-icon-container img,
  .IconText-icon-container svg {
    display: block; }

.IconText-text {
  color: #555;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.TurboTab-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 26rem;
  margin: auto;
  margin-top: 2rem; }
  @media only screen and (min-width: 800px) {
    .TurboTab-box {
      margin-top: 4rem; } }

.TurboTab-image {
  margin-bottom: 2rem;
  width: 4rem;
  display: block;
  -webkit-filter: saturate(0);
          filter: saturate(0); }
  @media only screen and (min-width: 800px) {
    .TurboTab-image {
      width: 6.5rem; } }

.TurboTab-text {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 500;
  color: #66696c; }

.modal-turbo-content {
  text-align: center; }
  .modal-turbo-content .texto-desc {
    font-size: 14px;
    width: 70%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px; }
  .modal-turbo-content .image-turbo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: -20px; }
  @media (min-width: 1200px) {
    .modal-turbo-content .flex-desc-container {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      width: 400px;
      margin: auto; } }
  .modal-turbo-content .flex-desc-container .desc-item {
    margin-bottom: 40px; }
    .modal-turbo-content .flex-desc-container .desc-item:last-child {
      margin-left: 0; }
    .modal-turbo-content .flex-desc-container .desc-item .titulo-desc-item {
      color: #37474f;
      font-weight: bold;
      font-size: 28px; }
    .modal-turbo-content .flex-desc-container .desc-item .conteudo-desc-item {
      font-size: 14px;
      text-align: center;
      width: 150px;
      font-weight: 500;
      color: #6c6c6c;
      margin: auto; }

.TurboReach-container {
  width: 110%;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 2rem;
  font-weight: bold;
  color: #335180;
  font-size: 22px;
  -webkit-justify-content: center;
          justify-content: center; }
  .TurboReach-container .icone {
    width: 20%;
    margin-right: 1rem; }
  .TurboReach-container .Turbo-titulo {
    font-weight: normal;
    font-size: 14px; }
  .TurboReach-container .Turbo-value {
    text-align: center; }
  .TurboReach-container .Turbo-bold {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.TurboReach-Outside {
  margin-top: 60px;
  margin-left: -30px; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
@media only screen and (min-width: 600px) {
  .InteressadoRow-listing {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

@media only screen and (min-width: 600px) and (max-width: 799px) {
  .InteressadoRow-listing .InteressadoRow {
    width: calc(100% / 2); }
    .InteressadoRow-listing .InteressadoRow:nth-of-type(odd) {
      padding-right: 2rem;
      border-right: .1rem solid #d8d8d8; }
    .InteressadoRow-listing .InteressadoRow:nth-of-type(even) {
      padding-left: 2rem; } }

@media only screen and (min-width: 800px) and (max-width: 999px) {
  .InteressadoRow-listing .InteressadoRow {
    width: calc(100% / 3);
    padding-right: 2rem;
    padding-left: 2rem;
    border-right: .1rem solid #d8d8d8; }
    .InteressadoRow-listing .InteressadoRow:nth-of-type(3n) {
      border-right: 0; } }

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  .InteressadoRow-listing .InteressadoRow {
    width: calc(100% / 5);
    padding-right: 2rem;
    padding-left: 2rem;
    border-right: .1rem solid #d8d8d8; }
    .InteressadoRow-listing .InteressadoRow:nth-of-type(5n) {
      border-right: 0; } }

.TabContentInteressados {
  padding: 1rem 2rem 0; }
  .TabContentInteressados .container-empty-list {
    margin: 20px; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.PageLogin {
  padding: 2rem 0;
  -webkit-flex: 1 1;
          flex: 1 1; }
  @media only screen and (max-width: 799px) {
    .PageLogin {
      min-height: 100vh; } }
  .PageLogin .button {
    font-size: 1.4rem; }

.PageLogin-card {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 3rem 2rem;
  max-width: 42rem;
  margin: auto; }

.PageLogin-logo {
  display: block;
  margin: auto;
  padding: 0 1rem; }

.PageLogin-step-title {
  text-align: center;
  font-weight: 500;
  font-size: 2rem;
  margin: 4rem 0 2rem;
  color: #33373b; }

.PageLogin-step-subtitle {
  color: #66696c;
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 1.4rem;
  line-height: 1.3; }

.PageLogin-form .forgot-password {
  text-align: center;
  font-size: 1.4rem;
  color: #335180;
  text-decoration: underline;
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1;
  cursor: pointer; }

.PageLogin-form .checkbox-group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.PageLogin-register-container {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  border-top: .1rem solid #ddd; }
  @media only screen and (min-width: 1200px) {
    .PageLogin-register-container {
      margin-top: 3rem;
      padding-top: 3rem; } }

.PageLogin-register-title {
  color: #33373b;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  margin: auto;
  margin-bottom: 1.5rem;
  max-width: 25rem; }
  @media only screen and (min-width: 1200px) {
    .PageLogin-register-title {
      margin-bottom: 2rem; } }

.PageLogin-resend-code {
  color: #66696c;
  font-size: 1.4rem;
  margin-top: 1.5rem;
  line-height: 1.2;
  text-align: center; }
  .PageLogin-resend-code button {
    color: #1e88e5; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.PageLogin {
  padding: 2rem 0;
  min-height: 100vh; }
  .PageLogin .button {
    font-size: 1.4rem; }

.PageLogin-card {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 3rem 2rem;
  max-width: 42rem;
  margin: auto; }

.PageLogin-logo {
  display: block;
  margin: auto;
  padding: 0 1rem; }

.PageLogin-step-title {
  text-align: center;
  font-weight: 500;
  font-size: 2rem;
  margin: 4rem 0 2rem;
  color: #33373b; }

.PageLogin-step-subtitle {
  color: #66696c;
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 1.4rem;
  line-height: 1.3; }

.PageLogin-form .forgot-password {
  text-align: center;
  font-size: 1.4rem;
  color: #335180;
  text-decoration: underline;
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1;
  cursor: pointer; }

.PageLogin-form .checkbox-group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.PageLogin-register-container {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  border-top: .1rem solid #ddd; }
  @media only screen and (min-width: 1200px) {
    .PageLogin-register-container {
      margin-top: 3rem;
      padding-top: 3rem; } }

.PageLogin-register-title {
  color: #33373b;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  margin: auto;
  margin-bottom: 1.5rem;
  max-width: 25rem; }
  @media only screen and (min-width: 1200px) {
    .PageLogin-register-title {
      margin-bottom: 2rem; } }

.PageLogin-resend-code {
  color: #66696c;
  font-size: 1.4rem;
  margin-top: 1.5rem;
  line-height: 1.2;
  text-align: center; }
  .PageLogin-resend-code button {
    color: #1e88e5; }

.Modal-forgot-password {
  text-align: center; }
  .Modal-forgot-password .Modal-content {
    margin: 2rem 0 0; }

.Modal-forgot-password__text {
  margin-bottom: 3rem;
  line-height: 1.2;
  font-size: 1.6rem;
  color: #66696c;
  font-weight: 600; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.RepasseInteressados-title {
  font-size: 1.4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline; }

.RepasseInteressados-number {
  font-size: 2rem;
  font-weight: bold;
  color: #2f4b76;
  margin-right: 0.3rem; }

.RepasseInteressados-text {
  color: #78909C;
  margin-right: 0.3rem; }

.RepasseInteressados-link {
  color: #1e88e5; }

.RepasseInteressados-item-listing {
  margin-top: 1rem;
  margin-left: 0.5rem; }

.RepasseInteressados-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.7rem; }
  .RepasseInteressados-item:last-of-type {
    margin-bottom: 0; }

.RepasseInteressados-item-number {
  background-color: #2f4b76;
  color: #fff;
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 1.2rem;
  border-radius: 50%;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  cursor: default;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.RepasseInteressados-item-value {
  font-size: 1.4rem;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.RepasseTurboStatus {
  font-size: 1.4rem;
  line-height: 1;
  min-width: 10rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.RepasseTurboStatus-image-container {
  width: 2.5rem;
  margin-bottom: 1rem; }

.RepasseTurboStatus-image {
  width: 100%;
  display: block; }

.RepasseTurboStatus-status {
  margin-bottom: 0.5rem;
  color: #78909C; }

.RepasseTurboStatus-cta {
  font-weight: bold;
  color: #1e88e5; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.GestaoRepasseHeader {
  border-bottom: .1rem solid #d8d8d8; }
  @media only screen and (min-width: 1200px) {
    .GestaoRepasseHeader {
      padding-bottom: 2rem; } }

.GestaoRepasseHeader-title {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: 1.2; }
  @media only screen and (min-width: 1200px) {
    .GestaoRepasseHeader-title {
      margin-bottom: 1rem; } }

.GestaoRepasseHeader-search-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media only screen and (min-width: 1200px) {
    .GestaoRepasseHeader-search-form {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
  @media only screen and (min-width: 1200px) {
    .GestaoRepasseHeader-search-form .form-group {
      margin-bottom: 0;
      margin-right: 2rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoRepasseHeader-search-form .form-group:last-of-type {
      margin-right: 0; } }
  .GestaoRepasseHeader-search-form .form-group > .label {
    color: #335180;
    font-size: 1.4rem; }
  @media only screen and (min-width: 1200px) {
    .GestaoRepasseHeader-search-form .form-group.\--busca, .GestaoRepasseHeader-search-form .form-group.\--usuario, .GestaoRepasseHeader-search-form .form-group.\--vendedor {
      -webkit-flex: 0 0 20.5rem;
              flex: 0 0 20.5rem;
      width: 20.5rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoRepasseHeader-search-form .form-group.\--status {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; } }
  .GestaoRepasseHeader-search-form .form-group.\--status > .label {
    width: 100%; }
  @media only screen and (min-width: 1200px) {
    .GestaoRepasseHeader-search-form .form-group.\--status .checkbox-group {
      margin-right: 2rem;
      height: 4rem;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; } }
  .GestaoRepasseHeader-search-form .form-group.\--status .checkbox-group:last-of-type {
    margin-right: 0; }
  @media only screen and (min-width: 1200px) {
    .GestaoRepasseHeader-search-form .form-group.\--button {
      width: 8rem; } }
  .GestaoRepasseHeader-search-form .form-group.\--button .button {
    width: 100%; }

.RepassesListing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .RepassesListing .result__title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #335180; }
    .RepassesListing .result__title .result__title-obs {
      color: #686a6c;
      display: block;
      font-size: 1.35rem; }

.RepassesItem {
  padding: 2rem 0;
  border-bottom: .1rem solid #d8d8d8;
  width: 100%; }
  @media only screen and (min-width: 700px) and (max-width: 1199px) {
    .RepassesItem {
      width: 50%; }
      .RepassesItem:nth-of-type(odd) {
        padding-right: 2rem;
        border-right: .1rem solid #d8d8d8; }
      .RepassesItem:nth-of-type(even) {
        padding-left: 2rem; } }
  @media only screen and (min-width: 1200px) {
    .RepassesItem {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      width: 100%; } }
  .RepassesItem:last-of-type {
    border-bottom: 0;
    padding-bottom: 0; }

.RepassesItem-image-container {
  display: block;
  margin-bottom: 2rem;
  width: 100%; }
  @media only screen and (min-width: 400px) and (max-width: 1199px) {
    .RepassesItem-image-container {
      height: 30rem; } }
  @media only screen and (min-width: 1200px) {
    .RepassesItem-image-container {
      width: 11rem;
      height: 11rem;
      margin-bottom: 0; } }

.RepassesItem-image {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.RepassesItem-info-availability {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 1200px) {
    .RepassesItem-info-availability {
      margin-bottom: 0; } }
  .RepassesItem-info-availability .StatusLabel {
    margin-top: auto; }

.RepassesItem-info-values {
  margin-top: 2rem;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 1200px) {
    .RepassesItem-info-values {
      margin-bottom: 0;
      margin-top: 0; } }

@media only screen and (max-width: 1199px) {
  .RepassesItem-info-turbo {
    margin-top: 2rem; } }

@media only screen and (min-width: 1200px) {
  .RepassesItem-info-turbo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-right: 2rem; } }

@media only screen and (min-width: 1200px) {
  .RepassesListing.\--gestao-repasses .RepassesItem {
    -webkit-justify-content: flex-start;
            justify-content: flex-start; } }

@media only screen and (min-width: 1200px) {
  .RepassesListing.\--gestao-repasses .RepassesItem-image-container {
    margin-right: 3rem; } }

@media only screen and (min-width: 1200px) {
  .RepassesListing.\--gestao-repasses .RepassesItem-info-car {
    width: 35rem;
    margin-right: 5rem; } }

@media only screen and (min-width: 1200px) {
  .RepassesListing.\--gestao-repasses .RepassesItem-info-values {
    width: 20rem;
    margin-right: 5rem; } }

@media only screen and (min-width: 1200px) {
  .RepassesListing.\--gestao-repasses .RepassesItem-info-interested {
    width: 18rem;
    margin-right: 3rem; } }

@media only screen and (min-width: 1200px) {
  .RepassesListing.\--gestao-repasses .RepassesItem-info-turbo {
    margin-left: auto; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.Favoritos-listing .result__title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #335180; }

@media only screen and (min-width: 600px) {
  .Favoritos-listing {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

.Favoritos-item {
  padding: 2rem 0;
  border-bottom: 1px solid #e8e8e8; }
  @media only screen and (max-width: 599px) {
    .Favoritos-item {
      width: calc(100% / 2); } }
  @media only screen and (min-width: 1200px) {
    .Favoritos-item {
      width: 100%;
      display: -webkit-flex;
      display: flex; } }
  .Favoritos-item:first-of-type {
    padding-top: 0; }
  .Favoritos-item:last-of-type {
    border-bottom: 0;
    padding-bottom: 0; }
  @media only screen and (min-width: 600px) and (max-width: 1199px) {
    .Favoritos-item:nth-of-type(odd) {
      padding-right: 2rem;
      border-right: 1px solid #d8d8d8; } }
  @media only screen and (min-width: 600px) and (max-width: 1199px) {
    .Favoritos-item:nth-of-type(even) {
      padding-left: 2rem; } }

@media only screen and (max-width: 1199px) {
  .Favoritos-image-container {
    margin-bottom: 2rem; } }

@media only screen and (min-width: 1200px) {
  .Favoritos-image-container {
    width: 11rem;
    height: 11rem;
    margin-right: 3rem; } }

@media only screen and (min-width: 1200px) {
  .Favoritos-image {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

@media only screen and (max-width: 1199px) {
  .Favoritos-info {
    margin-bottom: 2rem; } }

@media only screen and (min-width: 1200px) {
  .Favoritos-info {
    width: 20rem;
    margin-right: 3rem; } }

@media only screen and (max-width: 1199px) {
  .Favoritos-status {
    margin-bottom: 2rem; } }

@media only screen and (min-width: 1200px) {
  .Favoritos-status {
    margin-right: 3rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; } }

@media only screen and (min-width: 1200px) {
  .Favoritos-status .StatusLabel {
    margin-top: auto;
    margin-bottom: 1rem; } }

@media only screen and (max-width: 1199px) {
  .Favoritos-valores {
    margin-bottom: 2rem; } }

@media only screen and (min-width: 1200px) {
  .Favoritos-valores {
    margin-left: auto;
    margin-right: 10rem;
    width: 20rem; } }

@media only screen and (min-width: 1200px) {
  .Favoritos-desfavoritar {
    margin-right: 3rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; } }

.Favoritos-desfavoritar .desfavoritar-icon-container {
  color: #1e88e5;
  margin-bottom: 1.5rem;
  font-size: 2rem; }

.Favoritos-desfavoritar .desfavoritar-text {
  color: #66696c; }

@media only screen and (min-width: 1200px) {
  .Favoritos-desfavoritar .button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.ReportStatusItem {
  color: #fff;
  background-color: #335180;
  font-weight: bold;
  text-align: center;
  border-radius: .3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 1.5rem; }
  .ReportStatusItem.big .ReportStatusItem-content {
    font-size: 2.5rem; }
    @media only screen and (min-width: 700px) {
      .ReportStatusItem.big .ReportStatusItem-content {
        font-size: 3rem; } }

.ReportStatusItem-title {
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 600;
  opacity: 0.9; }

.ReportStatusItem-content {
  line-height: 1;
  font-size: 2rem;
  margin-bottom: 0.7rem; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
@media only screen and (min-width: 800px) {
  .relatorios-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

.relatorios-col {
  margin-bottom: 2rem; }
  @media only screen and (min-width: 800px) {
    .relatorios-col {
      margin-bottom: 0; } }
  @media only screen and (min-width: 800px) {
    .relatorios-col:first-of-type {
      width: calc(100% - 30rem);
      padding-right: 2rem; } }
  .relatorios-col:last-of-type {
    margin: auto; }
    @media only screen and (min-width: 800px) {
      .relatorios-col:last-of-type {
        width: calc(30rem); } }

@media only screen and (min-width: 700px) and (max-width: 799px) {
  .status-listing {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem; } }

.status-listing .ReportStatusItem {
  margin-bottom: 1rem; }
  @media only screen and (min-width: 700px) and (max-width: 799px) {
    .status-listing .ReportStatusItem {
      width: calc(100% / 3 - 1rem);
      margin-left: .5rem;
      margin-right: .5rem; } }

.global-status-title {
  font-size: 2.5rem;
  color: #335180;
  margin-bottom: 2rem;
  font-weight: 500; }

@media only screen and (min-width: 600px) {
  .global-status-listing {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem; } }

.global-status-listing .ReportStatusItem {
  margin-bottom: 1rem; }
  @media only screen and (min-width: 600px) {
    .global-status-listing .ReportStatusItem {
      width: calc(100% / 2 - 1rem);
      margin: 0 0.5rem 1rem; } }
  @media only screen and (min-width: 800px) {
    .global-status-listing .ReportStatusItem {
      width: calc(100% / 3 - 1rem); } }

@media only screen and (min-width: 1200px) {
  .global-status-listing.\--quantidades .ReportStatusItem {
    width: calc(100% / 6 - 1rem); } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.Page404 .content-container {
  max-width: 95rem; }

.Page404-card-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  padding: 2rem; }
  @media only screen and (min-width: 800px) {
    .Page404-card-content {
      padding: 4rem 2rem; } }
  @media only screen and (min-width: 1200px) {
    .Page404-card-content {
      padding: 8rem 2rem; } }

.Page404-image {
  display: block;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 800px) {
    .Page404-image {
      margin-bottom: 3rem; } }

.Page404-title {
  color: #37474F;
  margin-bottom: 2rem;
  font-size: 4rem;
  font-weight: 600; }

.Page404-subtitle {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: 1.4; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.PageNaoAutorizado .content-container {
  max-width: 95rem; }

.PageNaoAutorizado-card-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  padding: 2rem; }
  @media only screen and (min-width: 800px) {
    .PageNaoAutorizado-card-content {
      padding: 4rem 2rem; } }
  @media only screen and (min-width: 1200px) {
    .PageNaoAutorizado-card-content {
      padding: 8rem 2rem; } }

.PageNaoAutorizado-image {
  display: block;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 800px) {
    .PageNaoAutorizado-image {
      margin-bottom: 3rem; } }

.PageNaoAutorizado-title {
  color: #37474F;
  margin-bottom: 2rem;
  font-size: 4rem;
  font-weight: 600; }

.PageNaoAutorizado-subtitle {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: 1.4; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
@media only screen and (min-width: 800px) {
  .PageCadastroRepasse .CardConteudo {
    padding: 4rem; } }

@media only screen and (min-width: 1200px) {
  .CadastroRepasse-box {
    display: -webkit-flex;
    display: flex; } }

.CadastroRepasse-progress {
  color: #335180;
  font-weight: 600;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #d8d8d8; }
  @media only screen and (max-width: 1199px) {
    .CadastroRepasse-progress {
      padding-bottom: 5rem; } }
  @media only screen and (min-width: 1200px) {
    .CadastroRepasse-progress {
      border-bottom: 0;
      border-right: 1px solid #d8d8d8;
      padding-top: 4rem;
      padding-bottom: 0;
      padding-right: 4rem;
      margin-bottom: 0;
      width: 30rem; } }

@media only screen and (min-width: 1200px) {
  .CadastroRepasse-content {
    padding: 2rem 4rem;
    width: calc(100% - 30rem); } }

.CadastroRepasse-title {
  color: #335180;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 4rem; }

.CadastroRepasse-subtitle {
  color: #66696c;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 600;
  margin-top: 4rem;
  margin-bottom: 3rem; }

.CadastroRepasse-navigation {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media only screen and (min-width: 800px) {
    .CadastroRepasse-navigation {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
  .CadastroRepasse-navigation .button {
    width: 100%;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 800px) {
      .CadastroRepasse-navigation .button {
        width: auto;
        margin-bottom: 0; } }
    .CadastroRepasse-navigation .button:last-of-type {
      margin-bottom: 0; }
  .CadastroRepasse-navigation .navigation-icon {
    margin: 0 1rem; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.CadastroRepasse-image-upload-big .image-upload-label {
  color: #335180;
  border: 2px dashed #335180;
  border-radius: 7px;
  padding: 1rem;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 32rem;
  margin: auto;
  line-height: 1.2;
  cursor: pointer; }
  @media only screen and (min-width: 800px) {
    .CadastroRepasse-image-upload-big .image-upload-label {
      padding: 4rem 2rem; } }
  .CadastroRepasse-image-upload-big .image-upload-label:hover {
    border-style: solid; }

.CadastroRepasse-image-upload-big .image-upload-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.CadastroRepasse-image-upload-big .image-upload-icon {
  width: 3rem;
  margin-bottom: 2rem; }

.CadastroRepasse-image-upload-big .image-upload-title {
  font-size: 1.6rem;
  margin-bottom: 1.5rem; }

.CadastroRepasse-image-upload-big .image-upload-subtitle {
  font-size: 1.4rem; }

@media only screen and (min-width: 600px) {
  .CadastroRepasse-options-list {
    -webkit-columns: 2;
            columns: 2; } }

@media only screen and (min-width: 800px) {
  .CadastroRepasse-options-list {
    -webkit-columns: 3;
            columns: 3; } }

.CadastroRepasse-form-container .label {
  font-size: 1.4rem;
  color: #335180; }

.LogoFipe {
  width: 3rem;
  object-fit: contain;
  margin-left: .5rem;
  margin-top: 0.5rem;
  margin-bottom: -2rem; }

.PorcentagemFipe {
  margin-top: 2rem; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
@media only screen and (min-width: 800px) {
  .CadastroRepasse-VehicleSelection {
    max-width: 80rem;
    margin: auto;
    padding: 6rem 0 8rem; } }

@media only screen and (min-width: 1200px) {
  .CadastroRepasse-VehicleSelection {
    padding: 8rem 0 10rem; } }

@media only screen and (min-width: 800px) {
  .VehicleSelection__listing {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; } }

.VehicleSelection__item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 6rem; }
  .VehicleSelection__item:last-of-type {
    margin-bottom: 0;
    border-right: 0; }
  @media only screen and (min-width: 800px) {
    .VehicleSelection__item {
      width: calc(100% / 3);
      margin-bottom: 0;
      border-right: 2px solid #ececec;
      padding: 0 3rem; } }

.VehicleSelection__image-container {
  width: 10rem;
  margin-bottom: 2rem; }

.VehicleSelection__image {
  height: 100%;
  width: 100%;
  object-fit: contain; }

.VehicleSelection__description {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 1.5rem; }

.VehicleSelection__action-container {
  margin-top: auto; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.CadastroRepasse-gallery-listing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -0.8rem;
  margin-right: -0.8rem; }

.CadastroRepasse-gallery-item {
  border-radius: 6px;
  margin: 0.8rem;
  width: calc(100% / 2 - (0.8rem * 2));
  position: relative;
  display: -webkit-flex;
  display: flex;
  overflow: visible; }
  .CadastroRepasse-gallery-item:last-of-type .CadastroRepasse-gallery-item-content-container {
    border: 1px solid #1e88e5;
    background-color: #d2e7fa; }
  @media only screen and (min-width: 600px) {
    .CadastroRepasse-gallery-item {
      width: calc(100% / 5 - (0.8rem * 2)); } }
  @media only screen and (min-width: 800px) {
    .CadastroRepasse-gallery-item {
      width: calc(100% / 7 - (0.8rem * 2)); } }
  .CadastroRepasse-gallery-item.is-active .CadastroRepasse-gallery-item-content-container {
    border: 1px solid #1e88e5;
    background-color: #d2e7fa; }

.CadastroRepasse-gallery-item-content-container {
  border: 1px solid #d8d8d8;
  overflow: hidden;
  border-radius: 6px;
  transition: .2s;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer; }
  .CadastroRepasse-gallery-item-content-container:hover {
    border: 1px solid #1e88e5;
    background-color: #d2e7fa; }

.CadastroRepasse-gallery-item-photo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.CadastroRepasse-gallery-add-photo {
  width: 2.5rem; }

.CadastroRepasse-gallery-item-close {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0;
  color: #fff;
  background-color: #f4516c;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  border: 2px solid #f4516c; }
  .CadastroRepasse-gallery-item-close:hover {
    background-color: #fff;
    color: #f4516c; }
  .CadastroRepasse-gallery-item-close svg {
    display: block; }

.CadastroRepasse-gallery-input {
  position: absolute;
  height: 0.1px;
  width: 0.1px;
  left: -9999px; }

.observacao {
  color: #686a6c;
  display: block;
  font-size: 1.35rem; }

.CadastroRepasse-Confirmation .summary__title {
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
  margin: 2rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.CadastroRepasse-Confirmation .summary__title-icon {
  margin-left: 1rem;
  color: #1e88e5;
  cursor: pointer; }

.CadastroRepasse-Confirmation .summary-header {
  margin-bottom: 2rem; }
  @media (min-width: 700px) {
    .CadastroRepasse-Confirmation .summary-header {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 4rem; } }

.CadastroRepasse-Confirmation .summary-header__image-container {
  width: 100%;
  margin-bottom: 2rem; }
  @media (min-width: 700px) {
    .CadastroRepasse-Confirmation .summary-header__image-container {
      max-width: 15rem;
      margin-right: 3rem;
      margin-bottom: 0; } }

.CadastroRepasse-Confirmation .summary-header__info-container {
  margin-bottom: 4rem; }
  @media (min-width: 700px) {
    .CadastroRepasse-Confirmation .summary-header__info-container {
      margin-bottom: 0; } }

.CadastroRepasse-Confirmation .RepasseInfo {
  margin-bottom: 2rem; }

.CadastroRepasse-Confirmation .summary__gallery-listing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -0.8rem;
  margin-right: -0.8rem; }

.CadastroRepasse-Confirmation .summary__gallery-item {
  border-radius: 6px;
  overflow: hidden;
  margin: 0.8rem;
  width: calc(100% / 2 - (0.8rem * 2));
  position: relative;
  display: -webkit-flex;
  display: flex;
  cursor: pointer; }
  @media (min-width: 600px) {
    .CadastroRepasse-Confirmation .summary__gallery-item {
      width: calc(100% / 5 - (0.8rem * 2)); } }
  @media (min-width: 800px) {
    .CadastroRepasse-Confirmation .summary__gallery-item {
      width: calc(100% / 7 - (0.8rem * 2)); } }

.CadastroRepasse-gallery-listing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -0.8rem;
  margin-right: -0.8rem; }

.CadastroProgressBar {
  font-weight: 500; }
  @media (max-width: 1199px) {
    .CadastroProgressBar {
      width: 100%;
      position: relative;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center; } }

@media (max-width: 1199px) {
  .CadastroProgressBar-item {
    position: relative;
    text-align: center;
    width: 15rem;
    height: 2rem;
    display: -webkit-flex;
    display: flex; }
    .CadastroProgressBar-item::after, .CadastroProgressBar-item::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      border-radius: 50%;
      z-index: 1; }
    .CadastroProgressBar-item::before {
      width: 2.2rem;
      height: 2.2rem;
      border: .5rem solid #fff;
      background-color: #ccc; }
    .CadastroProgressBar-item::after {
      width: 2.2rem;
      height: 2.2rem;
      border: .2rem solid transparent; }
    .CadastroProgressBar-item .CadastroProgressBar-item-text {
      display: none; } }

@media (min-width: 1200px) {
  .CadastroProgressBar-item {
    position: relative;
    margin-bottom: 4rem;
    color: #ccc; }
    .CadastroProgressBar-item::after, .CadastroProgressBar-item::before {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      border-radius: 50%;
      z-index: 1; }
    .CadastroProgressBar-item::before {
      width: 2.2rem;
      height: 2.2rem;
      border: .5rem solid #fff;
      background-color: #ccc; }
    .CadastroProgressBar-item::after {
      width: 2.2rem;
      height: 2.2rem;
      border: .2rem solid transparent; } }

@media (max-width: 1199px) {
  .CadastroProgressBar-item.past {
    color: #335180; }
    .CadastroProgressBar-item.past::before {
      background-color: #1e88e5; }
    .CadastroProgressBar-item.past .CadastroProgressBar-item-bar {
      background-color: #1e88e5; } }

@media (min-width: 1200px) {
  .CadastroProgressBar-item.past {
    color: #335180; }
    .CadastroProgressBar-item.past::before {
      background-color: #1e88e5; }
    .CadastroProgressBar-item.past .CadastroProgressBar-item-bar {
      background-color: #1e88e5; } }

.CadastroProgressBar-item.current {
  color: #335180; }
  .CadastroProgressBar-item.current::before {
    background-color: #1e88e5; }
  .CadastroProgressBar-item.current::after {
    border-color: #1e88e5; }

@media (max-width: 1199px) {
  .CadastroProgressBar-item-text {
    display: none; } }

@media (min-width: 1200px) {
  .CadastroProgressBar-item-text {
    font-size: 1.6rem;
    font-weight: 500;
    text-align: right;
    padding-right: 4rem; } }

@media (max-width: 1199px) {
  .CadastroProgressBar-item-bar {
    height: .4rem;
    width: 100%;
    background-color: #ccc;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(calc(-50% + 1px));
            transform: translateY(calc(-50% + 1px)); } }

@media (min-width: 1200px) {
  .CadastroProgressBar-item-bar {
    width: .4rem;
    height: 5rem;
    background-color: #ccc;
    position: absolute;
    right: 0.9rem;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }

@media (max-width: 1199px) {
  .CadastroProgressBar-item.current + .CadastroProgressBar-standalone-text {
    display: block; } }

.CadastroProgressBar-standalone-text {
  display: none; }
  @media (max-width: 1199px) {
    .CadastroProgressBar-standalone-text {
      position: absolute;
      top: 3rem; } }

@media (max-width: 1199px) {
  .CadastroProgressBar-item:nth-last-of-type(2) {
    width: 2.5rem; } }

.CadastroProgressBar-item:nth-last-of-type(2) .CadastroProgressBar-item-bar {
  display: none !important; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
@media only screen and (min-width: 1200px) {
  .PerfilCompra-header {
    border-bottom: 1px solid #d8d8d8;
    display: -webkit-flex;
    display: flex;
    padding: 0 1.5rem 1.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #666; } }

@media only screen and (max-width: 1199px) {
  .PerfilCompra-header-item {
    display: none; } }

.PerfilCompra-header-item:nth-of-type(1) {
  width: 20rem;
  margin-right: 2rem; }

.PerfilCompra-header-item:nth-of-type(2) {
  width: 25rem;
  margin-right: 2rem;
  text-align: center; }

.PerfilCompra-header-item:nth-of-type(3) {
  width: 20rem;
  margin-right: 2rem;
  text-align: center; }

.PerfilCompra-header-item:nth-of-type(4) {
  width: 20rem;
  margin-right: 2rem;
  text-align: center; }

@media only screen and (min-width: 600px) {
  .PerfilCompra-listing {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

.PerfilCompra-row {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #d8d8d8; }
  @media only screen and (min-width: 600px) and (max-width: 899px) {
    .PerfilCompra-row {
      width: calc(100% / 2); } }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .PerfilCompra-row {
      width: calc(100% / 3);
      border-right: 1px solid #d8d8d8;
      padding: 3rem 2rem; } }
  @media only screen and (min-width: 1200px) {
    .PerfilCompra-row {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      width: 100%;
      padding: 1.5rem; } }
  @media only screen and (min-width: 1200px) {
    .PerfilCompra-row:last-of-type {
      border-bottom: 0; } }
  @media only screen and (min-width: 600px) and (max-width: 899px) {
    .PerfilCompra-row:nth-of-type(odd) {
      border-right: 1px solid #d8d8d8;
      padding-right: 2rem; } }
  @media only screen and (min-width: 600px) and (max-width: 899px) {
    .PerfilCompra-row:nth-of-type(even) {
      padding-left: 2rem; } }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .PerfilCompra-row:nth-of-type(3n) {
      border-right: 0; } }

.PerfilCompra-item {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 1200px) {
    .PerfilCompra-item {
      margin-bottom: 0; } }
  .PerfilCompra-item:last-of-type {
    margin-bottom: 0; }
  .PerfilCompra-item.\--title {
    color: #335180; }
    @media only screen and (min-width: 1200px) {
      .PerfilCompra-item.\--title {
        width: 20rem;
        margin-right: 2rem; } }
  @media only screen and (min-width: 1200px) {
    .PerfilCompra-item.\--region {
      width: 25rem;
      margin-right: 2rem;
      text-align: center; } }
  .PerfilCompra-item.\--type > span {
    display: block; }
  @media only screen and (min-width: 1200px) {
    .PerfilCompra-item.\--type {
      width: 20rem;
      margin-right: 2rem;
      text-align: center; } }
  @media only screen and (min-width: 1200px) {
    .PerfilCompra-item.\--year {
      width: 20rem;
      margin-right: 2rem;
      text-align: center; } }
  .PerfilCompra-item.\--actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 3rem; }
    @media only screen and (min-width: 1200px) {
      .PerfilCompra-item.\--actions {
        margin-top: 0;
        margin-left: auto; } }
    .PerfilCompra-item.\--actions .\--edit {
      margin-right: 2rem; }
    .PerfilCompra-item.\--actions .\--delete {
      margin-left: 2rem; }

.PerfilCompra-item-caption {
  font-size: 1.5rem;
  color: #888;
  margin-bottom: 0.5rem; }
  @media only screen and (min-width: 1200px) {
    .PerfilCompra-item-caption {
      display: none; } }

.Modal-PerfilCompra {
  font-weight: 500;
  color: #555; }
  .Modal-PerfilCompra .Modal-content {
    padding: 2rem; }

.remove__title {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 2rem; }

.remove__title-confirm {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 3rem; }

@media only screen and (min-width: 700px) {
  .seletor-veiculo-listing {
    display: -webkit-flex;
    display: flex;
    margin-left: -1rem;
    margin-right: -1rem; } }

.seletor-veiculo-listing.is-invalid .seletor-veiculo__item {
  border-color: #f4516c; }

.seletor-veiculo__item {
  display: block;
  position: relative;
  border: 1px solid #0f94d3;
  border-radius: 7px;
  padding: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 1.5rem;
  cursor: pointer; }
  @media only screen and (min-width: 700px) {
    .seletor-veiculo__item {
      margin: 0 1rem;
      width: calc(100% / 3); } }
  .seletor-veiculo__item .checkbox-box {
    position: absolute !important;
    top: 1rem !important;
    right: 0.5rem !important; }

.seletor-veiculo__icon {
  width: 5rem; }

.seletor-veiculo__texto {
  font-size: 1.5rem;
  line-height: 1.2;
  text-align: center;
  margin-top: 1rem; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.reveal {
  opacity: 1;
  visibility: visible;
  transition: .2s; }
  .reveal.hide {
    opacity: 0;
    visibility: hidden; }

.MeuPerfil-avatar-block {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 800px) {
    .MeuPerfil-avatar-block {
      -webkit-flex-direction: row;
              flex-direction: row; } }
  .MeuPerfil-avatar-block .MeuPerfil-name {
    font-weight: 600;
    color: #335180 !important;
    font-size: 1.8rem; }
    @media only screen and (max-width: 799px) {
      .MeuPerfil-avatar-block .MeuPerfil-name {
        text-align: center; } }
    @media only screen and (min-width: 800px) {
      .MeuPerfil-avatar-block .MeuPerfil-name {
        max-width: 40rem; } }

.MeuPerfil-avatar-container {
  height: 10rem;
  width: 10rem;
  border: 2px solid #335180;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem; }
  @media only screen and (min-width: 800px) {
    .MeuPerfil-avatar-container {
      margin-right: 2rem;
      margin-bottom: 0; } }

.MeuPerfil-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #e8e8e8; }

.MeuPerfil-avatar-actions {
  padding-bottom: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  @media only screen and (min-width: 800px) {
    .MeuPerfil-avatar-actions {
      padding-bottom: 3rem;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; } }

.MeuPerfil-avatar-action {
  margin-bottom: 1rem;
  text-align: center;
  margin-right: 2rem; }
  @media only screen and (min-width: 800px) {
    .MeuPerfil-avatar-action {
      margin-bottom: 0; } }
  .MeuPerfil-avatar-action:last-of-type {
    margin-bottom: 0; }
  @media only screen and (min-width: 800px) {
    .MeuPerfil-avatar-action .SmallAction {
      -webkit-flex-direction: row;
              flex-direction: row; } }
  @media only screen and (min-width: 800px) {
    .MeuPerfil-avatar-action .SmallAction-title {
      margin-right: 1rem;
      margin-bottom: 0; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.SmallAction {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-align-items: center;
          align-items: center;
  text-align: center; }
  .SmallAction:hover .SmallAction-title {
    text-decoration: underline; }

.SmallAction-title {
  font-size: 1.4rem;
  font-weight: 500;
  color: #335180;
  margin-top: 0.5rem; }

.SmallAction-icon {
  color: #335180;
  display: block; }

.container-page-cadastro {
  background-image: url(/static/media/bg-default.cdb4fc7d.jpg);
  background-size: cover;
  min-height: 80vh;
  padding-top: 70px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .container-page-cadastro .card-cadastro {
    background-color: #fff;
    width: 90%;
    min-height: 70vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px;
    margin-bottom: 70px; }
    .container-page-cadastro .card-cadastro .logo-topo {
      margin-left: -30px;
      width: 150px; }
      @media (min-width: 1200px) {
        .container-page-cadastro .card-cadastro .logo-topo {
          width: auto; } }
    .container-page-cadastro .card-cadastro .container-conteudo-cadastro {
      width: 100%;
      margin-top: 30px; }
      @media (min-width: 1200px) {
        .container-page-cadastro .card-cadastro .container-conteudo-cadastro {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          -webkit-flex: 1 1;
                  flex: 1 1;
          margin-top: 70px; } }
      .container-page-cadastro .card-cadastro .container-conteudo-cadastro .coluna-esquerda {
        margin-bottom: 60px; }
        @media (min-width: 1200px) {
          .container-page-cadastro .card-cadastro .container-conteudo-cadastro .coluna-esquerda {
            width: 20%;
            border-right: 1px solid #d8d8d8;
            padding-right: 30px;
            margin-bottom: 30px; } }
        .container-page-cadastro .card-cadastro .container-conteudo-cadastro .coluna-esquerda .link-login {
          font-size: 12px;
          text-align: right;
          display: none; }
          @media (min-width: 1200px) {
            .container-page-cadastro .card-cadastro .container-conteudo-cadastro .coluna-esquerda .link-login {
              display: block; } }
      .container-page-cadastro .card-cadastro .container-conteudo-cadastro .coluna-direita {
        margin-bottom: 30px; }
        @media (min-width: 1200px) {
          .container-page-cadastro .card-cadastro .container-conteudo-cadastro .coluna-direita {
            width: 80%;
            padding-left: 30px; } }
        .container-page-cadastro .card-cadastro .container-conteudo-cadastro .coluna-direita .titulo-cadastro {
          font-size: 22px;
          font-weight: 500; }

.cadastro-usuario-page-um .subtitulo-pagina {
  margin-bottom: 40px;
  font-size: 14px;
  margin-top: 10px; }

.cadastro-usuario-page-um .container-tipo-perfil {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .cadastro-usuario-page-um .container-tipo-perfil .foto {
    width: 200px;
    height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #37474f; }
    .cadastro-usuario-page-um .container-tipo-perfil .foto img {
      display: block;
      height: 100%;
      object-fit: cover; }
  .cadastro-usuario-page-um .container-tipo-perfil .select-perfil {
    width: 300px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px; }
    .cadastro-usuario-page-um .container-tipo-perfil .select-perfil select {
      width: 100%;
      height: 40px; }

.botao-continuar-cadastro {
  background-color: #37474f;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: white;
  width: 300px;
  text-align: center;
  font-size: 14px; }
  .botao-continuar-cadastro:hover {
    opacity: 0.8; }

.cadastro-usuario-dois {
  width: 100%; }
  @media (min-width: 1200px) {
    .cadastro-usuario-dois {
      width: 80%; } }
  .cadastro-usuario-dois .area-cadastro {
    margin-bottom: 60px; }
  .cadastro-usuario-dois .subtitulo {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 10px;
    letter-spacing: 2px; }
  .cadastro-usuario-dois .texto-desc {
    font-size: 14px;
    margin-bottom: 10px; }
  @media (min-width: 1200px) {
    .cadastro-usuario-dois .input-linha {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
  @media (min-width: 1200px) {
    .cadastro-usuario-dois .input-linha .input-item {
      width: 48%; } }
  @media (min-width: 1200px) {
    .cadastro-usuario-dois .input-linha.logradouro .input-item {
      width: 28%; } }
  @media (min-width: 1200px) {
    .cadastro-usuario-dois .input-linha.logradouro .input-item:first-child {
      width: 68%; } }
  .cadastro-usuario-dois .label-checkbox {
    font-size: 14px; }
    .cadastro-usuario-dois .label-checkbox .link-termos-uso {
      color: #37474f;
      font-weight: 500;
      text-decoration: underline; }
  .cadastro-usuario-dois .botoes-container {
    margin-top: 30px; }
    @media (min-width: 1200px) {
      .cadastro-usuario-dois .botoes-container {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center;
        height: 45px; } }
    .cadastro-usuario-dois .botoes-container .botao-item {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 20px;
      height: 30px; }

.alterar-foto-cadastro {
  margin-top: 20px;
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .alterar-foto-cadastro .foto {
    overflow: hidden;
    position: relative;
    width: 100px;
    height: 100px;
    border: 2px solid get-color(primary);
    border-radius: 50%;
    margin-right: 40px; }
    @media (min-width: 1200px) {
      .alterar-foto-cadastro .foto {
        margin-right: 50px; } }
    .alterar-foto-cadastro .foto img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #e8e8e8; }
  @media (min-width: 1200px) {
    .alterar-foto-cadastro .container-botoes {
      display: -webkit-flex;
      display: flex; } }
  @media (max-width: 1199px) {
    .alterar-foto-cadastro .container-botoes {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-start;
              align-items: flex-start; } }
  .alterar-foto-cadastro .container-botoes .botao-item {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #37474f;
    cursor: pointer; }
    @media (min-width: 1200px) {
      .alterar-foto-cadastro .container-botoes .botao-item {
        margin-right: 50px; } }
    @media (max-width: 1199px) {
      .alterar-foto-cadastro .container-botoes .botao-item {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse; } }
    .alterar-foto-cadastro .container-botoes .botao-item .icone {
      margin-left: 10px;
      font-size: 16px; }
      @media (max-width: 1199px) {
        .alterar-foto-cadastro .container-botoes .botao-item .icone {
          margin-right: 10px; } }
      .alterar-foto-cadastro .container-botoes .botao-item .icone.icone-mudar {
        color: #0F94D3; }
      .alterar-foto-cadastro .container-botoes .botao-item .icone.icone-lixo {
        color: #D0021B; }

.plano-cadastro-desktop {
  display: none; }
  @media (min-width: 1200px) {
    .plano-cadastro-desktop {
      display: -webkit-flex;
      display: flex;
      width: 100%; } }
  .plano-cadastro-desktop .coluna-titulos {
    width: 25%; }
    .plano-cadastro-desktop .coluna-titulos .area-titulo {
      padding: 20px;
      border-bottom: 1px solid #d8d8d8; }
      .plano-cadastro-desktop .coluna-titulos .area-titulo:last-child {
        border: 0; }
      .plano-cadastro-desktop .coluna-titulos .area-titulo .titulo-item {
        font-size: 14px;
        margin-bottom: 10px; }
        .plano-cadastro-desktop .coluna-titulos .area-titulo .titulo-item .tag-mes {
          font-size: 10px; }
        .plano-cadastro-desktop .coluna-titulos .area-titulo .titulo-item:last-child {
          margin-bottom: 0; }

.plano-cadastro-desktop-item {
  width: 18.75%;
  text-align: center; }
  .plano-cadastro-desktop-item .area-info {
    padding: 20px;
    border-bottom: 1px solid #d8d8d8; }
    .plano-cadastro-desktop-item .area-info:last-child {
      border: 0; }
    .plano-cadastro-desktop-item .area-info .info-item {
      font-size: 14px;
      margin-bottom: 10px; }
      .plano-cadastro-desktop-item .area-info .info-item:last-child {
        margin-bottom: 0; }
  .plano-cadastro-desktop-item .nome-plano {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px; }
  .plano-cadastro-desktop-item .valor-plano {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px; }
    .plano-cadastro-desktop-item .valor-plano .tag-mes {
      font-size: 14px; }

.plano-cadastro-mobile {
  display: -webkit-flex;
  display: flex;
  overflow-x: auto; }
  @media (min-width: 1200px) {
    .plano-cadastro-mobile {
      display: none; } }
  .plano-cadastro-mobile .item-cadastro {
    width: 100%;
    min-width: 240px;
    margin-bottom: 20px;
    margin-right: 20px; }

.plano-cadastro-mobile-item {
  width: 100%;
  text-align: center;
  padding: 10px;
  border: 1px solid #37474f;
  border-radius: 6px; }
  .plano-cadastro-mobile-item .titulo-plano {
    color: #37474f;
    font-weight: 600;
    font-size: 14px; }
  .plano-cadastro-mobile-item .valor-plano {
    font-size: 22px;
    font-weight: bold;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 10px; }
    .plano-cadastro-mobile-item .valor-plano .tag-mes {
      font-size: 14px; }
  .plano-cadastro-mobile-item .desc-item {
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 10px;
    text-align: left; }
    .plano-cadastro-mobile-item .desc-item .titulo-item {
      font-weight: bold; }
    .plano-cadastro-mobile-item .desc-item .span-consulta {
      font-size: 8px; }

.cadastro-usuario-tres .subtitulo {
  font-weight: 500;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
  letter-spacing: 2px; }

.cadastro-usuario-tres .botoes-container {
  margin-top: 30px; }
  @media (min-width: 1200px) {
    .cadastro-usuario-tres .botoes-container {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      height: 45px; } }
  .cadastro-usuario-tres .botoes-container .botao-item {
    margin-bottom: 20px;
    height: 30px; }

.cadastro-usuario-quatro .area-cadastro {
  margin-bottom: 60px; }
  .cadastro-usuario-quatro .area-cadastro .info-item {
    margin-bottom: 15px; }
    @media (min-width: 1200px) {
      .cadastro-usuario-quatro .area-cadastro .info-item {
        width: 24%;
        margin-right: 1%; } }
    .cadastro-usuario-quatro .area-cadastro .info-item .info-titulo {
      color: #456997;
      font-size: 12px; }
    .cadastro-usuario-quatro .area-cadastro .info-item .info-conteudo {
      font-size: 14px; }

.cadastro-usuario-quatro .icon-pencil {
  color: #0F94D3;
  font-size: 16px;
  margin-left: 10px; }

.cadastro-usuario-quatro .subtitulo {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
  letter-spacing: 2px; }

.cadastro-usuario-quatro .topo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .cadastro-usuario-quatro .topo .foto {
    overflow: hidden;
    position: relative;
    width: 80px;
    height: 80px;
    border: 2px solid get-color(primary);
    margin-right: 10px; }
    .cadastro-usuario-quatro .topo .foto img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #e8e8e8; }
  .cadastro-usuario-quatro .topo .info-lateral .tipo {
    font-size: 12px; }
  .cadastro-usuario-quatro .topo .info-lateral .nome {
    font-weight: 500;
    font-size: 18px; }

.cadastro-usuario-quatro .linha-info {
  margin-top: 20px;
  margin-bottom: 20px; }
  @media (min-width: 1200px) {
    .cadastro-usuario-quatro .linha-info {
      width: 70%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; } }

.descricao-plano-container .titulo-plano {
  color: #37474f;
  font-weight: 600;
  font-size: 22px; }

.descricao-plano-container .descricao-plano {
  font-size: 14px; }

.descricao-plano-container .destaque-bold {
  font-weight: bold; }

.descricao-plano-container .container-botao {
  text-align: center; }

.container-finish-cadastro {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .container-finish-cadastro .foto {
    margin-bottom: 20px; }
  .container-finish-cadastro .subtitulo-cadastro {
    margin-bottom: 30px; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.ExtratoDash {
  background-image: linear-gradient(to right, #36bbf6, #0e85e8);
  color: rgba(255, 255, 255, 0.8);
  margin: 2rem -2rem; }

.ExtratoDash-container {
  max-width: 110rem;
  margin: auto;
  padding: 3rem 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media only screen and (max-width: 699px) {
    .ExtratoDash-container {
      -webkit-align-items: center;
              align-items: center; } }
  @media only screen and (min-width: 700px) {
    .ExtratoDash-container {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-align-items: baseline;
              align-items: baseline; } }
  @media only screen and (min-width: 1000px) {
    .ExtratoDash-container {
      padding: 2.5rem 2rem;
      -webkit-justify-content: center;
              justify-content: center; } }

.ExtratoDash-item {
  text-align: center;
  margin-bottom: 2rem; }
  .ExtratoDash-item:last-of-type {
    margin-bottom: 0; }
  @media only screen and (min-width: 700px) {
    .ExtratoDash-item {
      width: calc(100% / 3);
      padding: 0 2rem; } }
  @media only screen and (min-width: 1000px) {
    .ExtratoDash-item {
      max-width: 20rem;
      width: calc(100% / 5);
      margin-bottom: 0; } }

.ExtratoDash-value {
  font-size: 2.8rem;
  font-weight: bold; }

.ExtratoDash-name {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.3; }

.ExtratoRow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem; }
  @media only screen and (min-width: 800px) {
    .ExtratoRow {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 1.2rem;
      padding: 0 1rem 1.2rem; } }

.ExtratoRow-item {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1rem; }
  @media only screen and (min-width: 800px) {
    .ExtratoRow-item {
      margin-bottom: 0; } }
  .ExtratoRow-item:last-of-type {
    margin-bottom: 0; }
  @media only screen and (min-width: 800px) {
    .ExtratoRow-item:nth-of-type(1) {
      width: 15rem;
      margin-right: 2rem; } }
  @media only screen and (min-width: 800px) {
    .ExtratoRow-item:nth-of-type(2) {
      width: 30rem;
      margin-right: 2rem; } }
  @media only screen and (min-width: 800px) {
    .ExtratoRow-item:nth-of-type(3) {
      margin-left: auto; } }

.ExtratoRow-name {
  line-height: 1.2; }

.ExtratoRow-link {
  color: #335180;
  font-size: 1.4rem;
  line-height: 1.2;
  cursor: pointer; }

.ExtratoHeader {
  display: -webkit-flex;
  display: flex;
  padding: 0 1rem;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: #777; }
  @media only screen and (max-width: 799px) {
    .ExtratoHeader {
      display: none; } }

@media only screen and (min-width: 800px) {
  .ExtratoHeader-item:nth-of-type(1) {
    width: 15rem;
    margin-right: 2rem; } }

@media only screen and (min-width: 800px) {
  .ExtratoHeader-item:nth-of-type(2) {
    width: 30rem;
    margin-right: 2rem; } }

@media only screen and (min-width: 800px) {
  .ExtratoHeader-item:nth-of-type(3) {
    margin-left: auto; } }

.ExtratoResumo {
  margin-top: 3rem; }
  @media only screen and (min-width: 800px) {
    .ExtratoResumo {
      padding: 0 2rem;
      margin-top: 4rem; } }

.ExtratoResumo-item {
  font-weight: 600;
  line-height: 1.2;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 1.5rem; }
  .ExtratoResumo-item .ExtratoResumo-value {
    -webkit-flex: none;
            flex: none;
    margin-left: 2rem; }

.select-date {
  position: relative; }
  .select-date .select-date__select {
    font-size: 1.6rem;
    font-weight: 500;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 0;
    background-color: transparent;
    padding-right: 3rem;
    cursor: pointer; }
  .select-date .select-date__icon {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 1.5rem;
    pointer-events: none; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.PageLogin {
  padding: 2rem 0;
  -webkit-flex: 1 1;
          flex: 1 1; }
  @media only screen and (max-width: 799px) {
    .PageLogin {
      min-height: 100vh; } }
  .PageLogin .button {
    font-size: 1.4rem; }

.PageLogin-card {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 3rem 2rem;
  max-width: 42rem;
  margin: auto; }

.PageLogin-logo {
  display: block;
  margin: auto;
  padding: 0 1rem; }

.PageLogin-step-title {
  text-align: center;
  font-weight: 500;
  font-size: 2rem;
  margin: 4rem 0 2rem;
  color: #33373b; }

.PageLogin-step-subtitle {
  color: #66696c;
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 1.4rem;
  line-height: 1.3; }

.PageLogin-form .forgot-password {
  text-align: center;
  font-size: 1.4rem;
  color: #335180;
  text-decoration: underline;
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1;
  cursor: pointer; }

.PageLogin-form .checkbox-group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.PageLogin-register-container {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  border-top: .1rem solid #ddd; }
  @media only screen and (min-width: 1200px) {
    .PageLogin-register-container {
      margin-top: 3rem;
      padding-top: 3rem; } }

.PageLogin-register-title {
  color: #33373b;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  margin: auto;
  margin-bottom: 1.5rem;
  max-width: 25rem; }
  @media only screen and (min-width: 1200px) {
    .PageLogin-register-title {
      margin-bottom: 2rem; } }

.PageLogin-resend-code {
  color: #66696c;
  font-size: 1.4rem;
  margin-top: 1.5rem;
  line-height: 1.2;
  text-align: center; }
  .PageLogin-resend-code button {
    color: #1e88e5; }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
@media only screen and (min-width: 1200px) {
  .EstoqueContainer {
    display: -webkit-flex;
    display: flex; } }

.EstoqueContainer-Seller-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  line-height: 1.2;
  padding: 4rem 0; }
  @media only screen and (min-width: 1200px) {
    .EstoqueContainer-Seller-info {
      width: 30rem;
      margin-right: 3rem;
      border-right: 1px solid #eee; } }
  .EstoqueContainer-Seller-info .Seller-image {
    width: 7rem;
    height: 7rem;
    margin-bottom: 2rem; }
  .EstoqueContainer-Seller-info .Seller-name {
    color: #333;
    margin-top: 1rem;
    font-size: 1.4rem;
    margin-bottom: 0.7rem; }
  .EstoqueContainer-Seller-info .Seller-address {
    color: #335180;
    font-size: 1.4rem;
    margin-bottom: 0.7rem; }
  .EstoqueContainer-Seller-info .Seller-repasses {
    font-size: 1.4rem;
    color: rgba(51, 81, 128, 0.6); }
    .EstoqueContainer-Seller-info .Seller-repasses span {
      color: #335180;
      font-weight: bold; }

@media only screen and (min-width: 1200px) {
  .EstoqueContainer-right {
    width: calc(100% - 30rem); } }

@media only screen and (min-width: 1200px) {
  .EstoqueContainer-right .RepassesItem-image-container {
    margin-right: 3rem; } }

@media only screen and (min-width: 1200px) {
  .EstoqueContainer-right .RepassesItem-info-car {
    margin-right: auto;
    width: 40rem; } }

@media only screen and (min-width: 1200px) {
  .EstoqueContainer-right .RepassesItem-info-values {
    width: 20rem; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.GestaoEquipeRow {
  border-bottom: .1rem solid #d8d8d8;
  margin: 0;
  padding: 4rem 0; }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin: 0;
      padding: 1.5rem; } }
  .GestaoEquipeRow:first-of-type {
    margin-top: 0;
    padding-top: 1rem; }
  .GestaoEquipeRow:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 1rem; }

.gestao-equipe-avatar-container {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  -webkit-flex: none;
          flex: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: rgba(51, 81, 128, 0.1); }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-avatar-container {
      margin: 0;
      height: 6rem;
      width: 6rem; } }

.gestao-equipe-avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block; }

.gestao-equipe-initials {
  font-weight: bold;
  color: #335180;
  line-height: 1;
  font-size: 2.8rem; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-initials {
      font-size: 2.3rem; } }

.gestao-equipe-name-container {
  text-align: center;
  color: #335180; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-name-container {
      text-align: left; } }

.gestao-equipe-name {
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 1;
  margin-bottom: 0.4rem; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-name {
      font-size: 1.8rem; } }

.gestao-equipe-job-title {
  color: #78909C;
  font-size: 1.5rem;
  line-height: 1; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-job-title {
      font-size: 1.4rem; } }

.gestao-equipe-phones {
  color: #78909C;
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 10px; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-phones {
      font-size: 1.4rem; } }

.gestao-equipe-number {
  text-align: center; }
  .gestao-equipe-number .number {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .gestao-equipe-number .number-variation {
    width: 1.3rem;
    margin-left: 1rem; }
  .gestao-equipe-number .text {
    font-size: 1.6rem;
    line-height: 1;
    margin-top: 1rem; }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-number .text {
        display: none; } }

.gestao-equipe-icon-text {
  color: #37474f;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-icon-text {
      margin: 0 2rem; } }
  .gestao-equipe-icon-text:first-of-type {
    margin-left: 0; }
  .gestao-equipe-icon-text:last-of-type {
    margin-right: 0;
    margin-bottom: 0; }
  .gestao-equipe-icon-text:hover .text-container {
    color: #0e85e8; }
  .gestao-equipe-icon-text .icon-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1.2rem; }
    .gestao-equipe-icon-text .icon-container .text {
      color: #0e85e8;
      margin-right: 0.8rem;
      font-size: 2rem;
      font-weight: 500;
      line-height: 1; }
    .gestao-equipe-icon-text .icon-container .icon {
      height: 2rem; }
  .gestao-equipe-icon-text .text-container {
    line-height: 1;
    font-size: 1.6rem;
    text-align: center; }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-icon-text .text-container {
        font-size: 1.4rem; } }

.gestao-equipe-more-actions-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.gestao-equipe-more-actions {
  color: #335180;
  font-size: 3rem;
  cursor: pointer; }
  .gestao-equipe-more-actions svg {
    display: block; }

.GestaoEquipeRow-item {
  margin-bottom: 3rem; }
  .GestaoEquipeRow-item:last-of-type {
    margin-bottom: 0; }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item {
      margin-bottom: 0; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--avatar {
      margin-right: 2rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--name {
      width: 23rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--sales, .GestaoEquipeRow-item.\--avg-time {
      width: 17rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--view, .GestaoEquipeRow-item.\--edit, .GestaoEquipeRow-item.\--remove {
      margin-left: 4rem;
      margin-right: 4rem; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--view {
      margin-left: auto; } }
  @media only screen and (min-width: 1200px) {
    .GestaoEquipeRow-item.\--remove {
      margin-right: 0; } }

@media only screen and (min-width: 1200px) {
  .GestaoEquipeRow.is-lite .GestaoEquipeRow-item.\--name {
    width: 20rem; } }

@media only screen and (min-width: 1200px) {
  .GestaoEquipeRow.is-lite .GestaoEquipeRow-item.\--sales, .GestaoEquipeRow.is-lite .GestaoEquipeRow-item.\--avg-time {
    width: 15rem; } }

@media only screen and (min-width: 1200px) {
  .GestaoEquipeRow.is-lite .GestaoEquipeRow-item.\--actions {
    margin-left: auto;
    margin-right: 1rem; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.Modal-GestaoEquipe {
  font-weight: 500;
  color: #555; }

.message-error {
  width: '100%'; }
  .message-error .result__title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #335180; }

.gestao-equipe-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: .1rem solid #d8d8d8;
  padding: 1rem 2rem 2rem; }
  @media only screen and (min-width: 1200px) {
    .gestao-equipe-header {
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
  .gestao-equipe-header .gestao-equipe-header-item {
    color: #37474f;
    font-size: 1.6rem; }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-header .gestao-equipe-header-item:nth-of-type(1) {
        -webkit-flex: 0 1 31rem;
                flex: 0 1 31rem; } }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-header .gestao-equipe-header-item:nth-of-type(2), .gestao-equipe-header .gestao-equipe-header-item:nth-of-type(3) {
        -webkit-flex: 0 1 17rem;
                flex: 0 1 17rem;
        text-align: center; } }
    @media only screen and (min-width: 1200px) {
      .gestao-equipe-header .gestao-equipe-header-item:nth-of-type(3) {
        margin-right: auto; } }
    @media only screen and (max-width: 1199px) {
      .gestao-equipe-header .gestao-equipe-header-item:not(.is-dropdown) {
        display: none; } }
    .gestao-equipe-header .gestao-equipe-header-item.is-dropdown {
      cursor: pointer;
      display: -webkit-flex;
      display: flex; }
    .gestao-equipe-header .gestao-equipe-header-item .dropdown-icon {
      color: #2B4F81;
      margin-left: 1.2rem; }

.form-select {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 0.5rem; }
  .form-select .checkbox-group {
    padding-left: 2rem;
    padding-right: 2rem; }

.form-search {
  width: 20rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .form-search .button {
    margin-top: 2.5rem;
    height: 2.5rem; }

.convite-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  @media only screen and (min-width: 1200px) {
    .convite-container {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      width: 100%;
      -webkit-align-items: center;
              align-items: center; } }

.convite-buttons-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  @media only screen and (max-width: 1199px) {
    .convite-buttons-container {
      margin-top: 2rem; } }

/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
.convite-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  @media only screen and (min-width: 1200px) {
    .convite-container {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      width: 100%;
      -webkit-align-items: center;
              align-items: center; } }

.convite-buttons-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  @media only screen and (max-width: 1199px) {
    .convite-buttons-container {
      margin-top: 2rem; } }

.convite-action-title {
  padding: 2rem;
  text-align: center; }

.message-empty-list {
  width: '100%'; }
  .message-empty-list .empty-list-title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
    color: #335180; }

