/**
 * Fontes customizadas
 */
/**
 * Fontes de sistema (para fallback)
 */
/**
 * Font stack completo
 */
@import url("https://fonts.googleapis.com/css?family=Teko:500");
@import url(~react-id-swiper/src/styles/css/swiper.css);
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

ul, li {
  list-style-type: none; }

html {
  font-family: sans-serif;
  font-size: 62.5%;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

*, *:before, *:after {
  box-sizing: border-box; }

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

body {
  padding: 0;
  font-size: 1.6rem; }

input,
textarea,
select,
a,
button {
  outline: none; }
  input:focus,
  textarea:focus,
  select:focus,
  a:focus,
  button:focus {
    outline: none; }

a {
  text-decoration: none; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0; }

img {
  display: block;
  max-width: 100%; }

body {
  margin: 0;
  color: #33373b;
  font-family: Avenir Next, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.45;
  overflow-x: hidden; }

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.eot");
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.woff2") format("woff2"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.woff") format("woff"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.ttf") format("truetype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Regular.svg#Avenir Next") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.eot");
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.woff2") format("woff2"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.woff") format("woff"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.ttf") format("truetype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Medium.svg#Avenir Next") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.eot");
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.eot?#iefix") format("embedded-opentype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.woff2") format("woff2"), url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.woff") format("woff"), url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.ttf") format("truetype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-DemiBold.svg#Avenir Next") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.eot");
  src: url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.eot?#iefix") format("embedded-opentype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.woff2") format("woff2"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.woff") format("woff"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.ttf") format("truetype"), url("/fonts/avenir-next/hinted-subset-AvenirNext-Heavy.svg#Avenir Next") format("svg");
  font-weight: 900;
  font-style: normal; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  line-height: 1.2; }

h1 {
  font-size: 7rem; }

h2 {
  font-size: 5rem; }

h3 {
  font-size: 3.5rem; }

h4 {
  font-size: 2.3rem; }

h5 {
  font-size: 1.8rem; }

h6 {
  font-size: 1.6rem; }

p {
  line-height: 1.5; }

code {
  background-color: #e6e6e6;
  padding: 1px 3px; }

.mini-title {
  display: flex;
  color: #2f4b76;
  font-weight: 600;
  line-height: 1.2;
  font-size: 1.8rem; }
  .mini-title .mini-title-icon {
    margin-right: 1rem;
    height: 2.2rem;
    width: auto; }

.button {
  display: inline-block;
  padding: 1rem 1.4rem;
  appearance: none;
  line-height: 1;
  font-weight: 500;
  border: 0.1rem solid transparent;
  border-radius: 0.4rem;
  background-color: transparent;
  transition: 0.2s;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-size: 1.5rem; }
  .button.clean {
    padding: 0; }

.button.light {
  color: #111;
  background-color: #fff; }
  .button.light:hover {
    background-color: #e6e6e6; }
  .button.light:active, .button.light:focus {
    background-color: #cccccc;
    outline: 0; }
  .button.light.is-active {
    background-color: #cccccc; }
  .button.light[disabled],
  .button.light .is-disabled {
    background-color: white;
    color: #585858;
    cursor: not-allowed; }

.button.dark {
  color: #fff;
  background-color: #333; }
  .button.dark:hover {
    background-color: #2e2e2e; }
  .button.dark:active, .button.dark:focus {
    background-color: #292929;
    outline: 0; }
  .button.dark.is-active {
    background-color: #292929; }
  .button.dark[disabled],
  .button.dark .is-disabled {
    background-color: #707070;
    color: white;
    cursor: not-allowed; }

.button.grey {
  color: #333;
  background-color: #ddd; }
  .button.grey:hover {
    background-color: #c7c7c7; }
  .button.grey:active, .button.grey:focus {
    background-color: #b1b1b1;
    outline: 0; }
  .button.grey.is-active {
    background-color: #b1b1b1; }
  .button.grey[disabled],
  .button.grey .is-disabled {
    background-color: #e7e7e7;
    color: #707070;
    cursor: not-allowed; }

.button.primary {
  color: #fff;
  background-color: #335180; }
  .button.primary:hover {
    background-color: #2e4973; }
  .button.primary:active, .button.primary:focus {
    background-color: #294166;
    outline: 0; }
  .button.primary.is-active {
    background-color: #294166; }
  .button.primary[disabled],
  .button.primary .is-disabled {
    background-color: #7085a6;
    color: white;
    cursor: not-allowed; }

.button.secondary {
  color: #fff;
  background-color: #c63f43; }
  .button.secondary:hover {
    background-color: #b2393c; }
  .button.secondary:active, .button.secondary:focus {
    background-color: #9e3236;
    outline: 0; }
  .button.secondary.is-active {
    background-color: #9e3236; }
  .button.secondary[disabled],
  .button.secondary .is-disabled {
    background-color: #d7797b;
    color: white;
    cursor: not-allowed; }

.button.blue {
  color: #fff;
  background-color: #1e88e5; }
  .button.blue:hover {
    background-color: #1b7ace; }
  .button.blue:active, .button.blue:focus {
    background-color: #186db7;
    outline: 0; }
  .button.blue.is-active {
    background-color: #186db7; }
  .button.blue[disabled],
  .button.blue .is-disabled {
    background-color: #62aced;
    color: white;
    cursor: not-allowed; }

.button.info {
  color: #111;
  background-color: #36a3f7; }
  .button.info:hover {
    background-color: #3193de; }
  .button.info:active, .button.info:focus {
    background-color: #2b82c6;
    outline: 0; }
  .button.info.is-active {
    background-color: #2b82c6; }
  .button.info[disabled],
  .button.info .is-disabled {
    background-color: #72bff9;
    color: #585858;
    cursor: not-allowed; }

.button.success {
  color: #111;
  background-color: #34bfa3; }
  .button.success:hover {
    background-color: #2fac93; }
  .button.success:active, .button.success:focus {
    background-color: #2a9982;
    outline: 0; }
  .button.success.is-active {
    background-color: #2a9982; }
  .button.success[disabled],
  .button.success .is-disabled {
    background-color: #71d2bf;
    color: #585858;
    cursor: not-allowed; }

.button.warning {
  color: #111;
  background-color: #ffb822; }
  .button.warning:hover {
    background-color: #e6a61f; }
  .button.warning:active, .button.warning:focus {
    background-color: #cc931b;
    outline: 0; }
  .button.warning.is-active {
    background-color: #cc931b; }
  .button.warning[disabled],
  .button.warning .is-disabled {
    background-color: #ffcd64;
    color: #585858;
    cursor: not-allowed; }

.button.danger {
  color: #111;
  background-color: #f4516c; }
  .button.danger:hover {
    background-color: #dc4961; }
  .button.danger:active, .button.danger:focus {
    background-color: #c34156;
    outline: 0; }
  .button.danger.is-active {
    background-color: #c34156; }
  .button.danger[disabled],
  .button.danger .is-disabled {
    background-color: #f78598;
    color: #585858;
    cursor: not-allowed; }

.button.outline {
  color: #111;
  background-color: transparent;
  border: 0.1rem solid #111; }
  .button.outline:hover {
    background-color: #111;
    color: #fff; }
  .button.outline:active, .button.outline:focus {
    color: #fff;
    border-color: #0f0f0f;
    background-color: #0f0f0f;
    outline: 0; }
  .button.outline.button-active {
    background-color: rgba(0, 0, 0, 0.15); }
  .button.outline[disabled],
  .button.outline .is-disabled {
    background-color: rgba(255, 255, 255, 0.5);
    color: #585858; }
  .button.outline.light {
    color: #fff;
    background-color: transparent;
    border: 0.1rem solid #fff; }
    .button.outline.light:hover {
      background-color: #fff;
      color: #111; }
    .button.outline.light:active, .button.outline.light:focus {
      color: #fff;
      border-color: #e6e6e6;
      background-color: #e6e6e6;
      outline: 0; }
    .button.outline.light.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.light[disabled],
    .button.outline.light .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: white; }
  .button.outline.dark {
    color: #333;
    background-color: transparent;
    border: 0.1rem solid #333; }
    .button.outline.dark:hover {
      background-color: #333;
      color: #fff; }
    .button.outline.dark:active, .button.outline.dark:focus {
      color: #fff;
      border-color: #2e2e2e;
      background-color: #2e2e2e;
      outline: 0; }
    .button.outline.dark.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.dark[disabled],
    .button.outline.dark .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #707070; }
  .button.outline.primary {
    color: #335180;
    background-color: transparent;
    border: 0.1rem solid #335180; }
    .button.outline.primary:hover {
      background-color: #335180;
      color: #fff; }
    .button.outline.primary:active, .button.outline.primary:focus {
      color: #fff;
      border-color: #2e4973;
      background-color: #2e4973;
      outline: 0; }
    .button.outline.primary.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.primary[disabled],
    .button.outline.primary .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #7085a6; }
  .button.outline.blue {
    color: #1e88e5;
    background-color: transparent;
    border: 0.1rem solid #1e88e5; }
    .button.outline.blue:hover {
      background-color: #1e88e5;
      color: #fff; }
    .button.outline.blue:active, .button.outline.blue:focus {
      color: #fff;
      border-color: #1b7ace;
      background-color: #1b7ace;
      outline: 0; }
    .button.outline.blue.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.blue[disabled],
    .button.outline.blue .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #62aced; }
  .button.outline.secondary {
    color: #c63f43;
    background-color: transparent;
    border: 0.1rem solid #c63f43; }
    .button.outline.secondary:hover {
      background-color: #c63f43;
      color: #fff; }
    .button.outline.secondary:active, .button.outline.secondary:focus {
      color: #fff;
      border-color: #b2393c;
      background-color: #b2393c;
      outline: 0; }
    .button.outline.secondary.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.secondary[disabled],
    .button.outline.secondary .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #d7797b; }
  .button.outline.info {
    color: #36a3f7;
    background-color: transparent;
    border: 0.1rem solid #36a3f7; }
    .button.outline.info:hover {
      background-color: #36a3f7;
      color: #fff; }
    .button.outline.info:active, .button.outline.info:focus {
      color: #fff;
      border-color: #3193de;
      background-color: #3193de;
      outline: 0; }
    .button.outline.info.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.info[disabled],
    .button.outline.info .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #72bff9; }
  .button.outline.success {
    color: #34bfa3;
    background-color: transparent;
    border: 0.1rem solid #34bfa3; }
    .button.outline.success:hover {
      background-color: #34bfa3;
      color: #fff; }
    .button.outline.success:active, .button.outline.success:focus {
      color: #fff;
      border-color: #2fac93;
      background-color: #2fac93;
      outline: 0; }
    .button.outline.success.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.success[disabled],
    .button.outline.success .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #71d2bf; }
  .button.outline.warning {
    color: #ffb822;
    background-color: transparent;
    border: 0.1rem solid #ffb822; }
    .button.outline.warning:hover {
      background-color: #ffb822;
      color: #fff; }
    .button.outline.warning:active, .button.outline.warning:focus {
      color: #fff;
      border-color: #e6a61f;
      background-color: #e6a61f;
      outline: 0; }
    .button.outline.warning.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.warning[disabled],
    .button.outline.warning .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #ffcd64; }
  .button.outline.danger {
    color: #f4516c;
    background-color: transparent;
    border: 0.1rem solid #f4516c; }
    .button.outline.danger:hover {
      background-color: #f4516c;
      color: #fff; }
    .button.outline.danger:active, .button.outline.danger:focus {
      color: #fff;
      border-color: #dc4961;
      background-color: #dc4961;
      outline: 0; }
    .button.outline.danger.button-active {
      background-color: rgba(0, 0, 0, 0.15); }
    .button.outline.danger[disabled],
    .button.outline.danger .is-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      color: #f78598; }

.button.small {
  font-size: strip-unit(1.5rem)-0.3rem;
  padding: 0.7rem 0.8rem; }

.button.smaller {
  font-size: 1.2rem;
  padding: 0.7rem 0.8rem; }

.button.big {
  font-size: strip-unit(1.5rem)0.2rem;
  padding: 1.4rem 1.8rem;
  border-radius: 0.5rem; }

.button.block {
  display: block;
  width: 100%; }
  .button.block + .button.block {
    margin-top: 0.5rem; }

.button.uppercase {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.07em; }

.button-group {
  display: flex; }
  .button-group .button {
    border-radius: 0; }
    .button-group .button:first-of-type {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem; }
    .button-group .button:last-of-type {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem; }
  .button-group .button.outline {
    border-right-width: 0; }
    .button-group .button.outline:last-of-type {
      border-right-width: 0.1rem; }

.button-group-vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .button-group-vertical .button {
    width: 100%;
    border-radius: 0; }
    .button-group-vertical .button:first-of-type {
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem; }
    .button-group-vertical .button:last-of-type {
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem; }
  .button-group-vertical .button.outline {
    border-bottom-width: 0; }
    .button-group-vertical .button.outline:last-of-type {
      border-bottom-width: 0.1rem; }

.chart-header {
  margin-bottom: 3rem; }

.chart-title {
  color: #335180;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.2; }
  .chart-title + .chart-subtitle {
    margin-top: 0.5rem; }

.chart-subtitle {
  color: rgba(51, 81, 128, 0.7);
  font-size: 1.6rem;
  line-height: 1.2; }

.chart-total {
  color: #335180;
  font-weight: bold;
  font-size: 3rem; }

.chart-container {
  position: relative; }

/* Base for label styling */
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: -9999px; }

[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label,
[type='checkbox']:not(:checked) + .checkbox-box,
[type='checkbox']:checked + .checkbox-box {
  position: relative;
  padding-left: 2.4rem;
  cursor: pointer; }

/* checkbox aspect */
[type='checkbox']:not(:checked) + label::before,
[type='checkbox']:checked + label::before,
[type='checkbox']:not(:checked) + .checkbox-box::before,
[type='checkbox']:checked + .checkbox-box::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  transition: 0.2s; }

/* checked mark aspect */
[type='checkbox']:not(:checked) + label::after,
[type='checkbox']:checked + label::after,
[type='checkbox']:not(:checked) + .checkbox-box::after,
[type='checkbox']:checked + .checkbox-box::after {
  content: '';
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.2rem;
  background-color: rgba(30, 136, 229, 0.8);
  transition: all 0.2s; }

/* checked mark aspect changes */
[type='checkbox']:not(:checked) + label::after,
[type='checkbox']:not(:checked) + .checkbox-box::after {
  opacity: 0;
  transform: scale(0.6); }

[type='checkbox']:checked + label::after,
[type='checkbox']:checked + .checkbox-box::after {
  opacity: 1;
  transform: scale(1); }

[type='checkbox']:checked + label::before,
[type='checkbox']:checked + .checkbox-box::before {
  border: 2px solid rgba(30, 136, 229, 0.6); }

/* disabled checkbox */
[type='checkbox']:disabled:not(:checked) + label::before,
[type='checkbox']:disabled:checked + label::before,
[type='checkbox']:disabled:not(:checked) + .checkbox-box::before,
[type='checkbox']:disabled:checked + .checkbox-box::before {
  border-color: #bbb;
  background-color: #ddd; }

[type='checkbox']:disabled:checked + label::after,
[type='checkbox']:disabled:checked + .checkbox-box::after {
  color: #999; }

[type='checkbox']:disabled + label,
[type='checkbox']:disabled + .checkbox-box {
  color: #aaa; }

/* accessibility */
[type='checkbox']:checked:focus + label::before,
[type='checkbox']:not(:checked):focus + label::before,
[type='checkbox']:checked:focus + .checkbox-box::before,
[type='checkbox']:not(:checked):focus + .checkbox-box::before {
  border: 2px solid rgba(30, 136, 229, 0.6);
  box-shadow: 0 0 0 3px rgba(30, 136, 229, 0.2); }

/* hover style just for information */
[type='checkbox'] + label:hover:before,
[type='checkbox'] + .checkbox-box:hover:before {
  border: 2px solid #1e88e5 !important; }

.CheckboxGroup {
  margin-bottom: 1.2rem;
  color: #66696c;
  font-size: 1.4rem;
  font-weight: 500; }
  .CheckboxGroup .label {
    line-height: 1.8rem; }

.page-container {
  background-color: #eee;
  padding: 40px 0;
  flex: 1 0 auto;
  height: 100%; }

.content-container {
  max-width: 1200px;
  padding: 0 20px;
  margin: auto; }

.l-form-wrapper--top {
  margin-top: 2rem; }

.form-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .form-wrapper .form-section-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-row {
      flex-wrap: nowrap; } }

.form-row--reverse {
  flex-direction: row-reverse; }
  @media only screen and (min-width: 750px) {
    .form-row--reverse {
      flex-direction: unset; } }

.form-col {
  width: 100%;
  flex-shrink: 1 !important;
  flex-grow: 1 !important; }
  @media only screen and (min-width: 750px) {
    .form-col {
      padding-left: 7px;
      padding-right: 7px;
      flex-shrink: 0;
      flex-grow: 0; } }
  .form-col:first-of-type {
    padding-left: 0; }
  .form-col:last-of-type {
    padding-right: 0; }

[class^='form-col']:first-of-type {
  padding-left: 0; }

.form-col--1 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--1 {
      padding-left: 7px;
      padding-right: 7px;
      flex-shrink: 0;
      flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--1 {
      width: calc((1 / 12) * 100%);
      flex-basis: calc((1 / 12) * 100%); } }

.form-col--2 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--2 {
      padding-left: 7px;
      padding-right: 7px;
      flex-shrink: 0;
      flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--2 {
      width: calc((2 / 12) * 100%);
      flex-basis: calc((2 / 12) * 100%); } }

.form-col--3 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--3 {
      padding-left: 7px;
      padding-right: 7px;
      flex-shrink: 0;
      flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--3 {
      width: calc((3 / 12) * 100%);
      flex-basis: calc((3 / 12) * 100%); } }

.form-col--4 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--4 {
      padding-left: 7px;
      padding-right: 7px;
      flex-shrink: 0;
      flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--4 {
      width: calc((4 / 12) * 100%);
      flex-basis: calc((4 / 12) * 100%); } }

.form-col--5 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--5 {
      padding-left: 7px;
      padding-right: 7px;
      flex-shrink: 0;
      flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--5 {
      width: calc((5 / 12) * 100%);
      flex-basis: calc((5 / 12) * 100%); } }

.form-col--6 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--6 {
      padding-left: 7px;
      padding-right: 7px;
      flex-shrink: 0;
      flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--6 {
      width: calc((6 / 12) * 100%);
      flex-basis: calc((6 / 12) * 100%); } }

.form-col--7 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--7 {
      padding-left: 7px;
      padding-right: 7px;
      flex-shrink: 0;
      flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--7 {
      width: calc((7 / 12) * 100%);
      flex-basis: calc((7 / 12) * 100%); } }

.form-col--8 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--8 {
      padding-left: 7px;
      padding-right: 7px;
      flex-shrink: 0;
      flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--8 {
      width: calc((8 / 12) * 100%);
      flex-basis: calc((8 / 12) * 100%); } }

.form-col--10 {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .form-col--10 {
      padding-left: 7px;
      padding-right: 7px;
      flex-shrink: 0;
      flex-grow: 0; } }
  @media only screen and (min-width: 750px) {
    .form-col--10 {
      width: calc((10 / 12) * 100%);
      flex-basis: calc((10 / 12) * 100%); } }

.form-validation {
  display: none;
  color: inherit;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-top: 0.5rem; }
  .form-validation.is-visible {
    display: block; }

.form-validation-danger {
  display: none;
  color: #f4516c;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-top: 0.5rem; }
  .form-validation-danger.is-visible {
    display: block; }

.form-validation-warning {
  display: none;
  color: #ffb822;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-top: 0.5rem; }
  .form-validation-warning.is-visible {
    display: block; }

.form-group.is-invalid .label {
  color: #f4516c; }

.form-group.is-invalid .form-control {
  border-color: #f4516c; }

.label {
  font-size: 1.6rem;
  line-height: 1;
  display: inline-block;
  margin-bottom: 0.8rem; }
  .label.small {
    font-size: 1.4rem; }

.form-instructions {
  display: block;
  margin-top: 0.5rem;
  color: #888; }

.form-control::placeholder {
  color: #999; }

.form-control {
  display: block;
  color: inherit;
  background-color: #fff;
  appearance: none;
  border: 1px solid #ddd;
  font-weight: 500;
  border-radius: 0.4rem;
  padding: 1rem;
  font-size: 1.6rem;
  line-height: 1.2;
  width: 100%;
  min-height: 3rem;
  transition: 0.2s; }
  .form-control:hover {
    border-color: rgba(51, 81, 128, 0.5); }
  .form-control:focus {
    border-color: #335180;
    outline: none; }
  .form-control[disabled], .form-control[readonly] {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid transparent;
    padding-left: 0 !important;
    pointer-events: none;
    touch-action: none; }
  .form-control + .form-control {
    margin-top: 1rem; }

textarea.form-control {
  height: unset;
  resize: vertical;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1.4; }

select.form-control {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAMAAAALbFwWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAM1BMVEUAAAAvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8AAAAr6/H9AAAAD3RSTlMAMgQ3Evete0Ba/I1+WIMOmTnpAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAADNJREFUCNdFyMcNACAMBMElZ9x/twgDwp/bMRjLOQc+xKMkGUpVJWn7pbqt6q+3fsOYOgszbAE1j++tNQAAAABJRU5ErkJggg==");
  background-position: right 1rem center;
  background-repeat: no-repeat; }

select.select-arrow-light {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAABGdBTUEAALGPC/xhBQAAAJFJREFUGBljYACCa9euGf3//58FxMYFrl69ag6SYwQyhP79+/cUyN6mo6MTzsjI+Add0+XLl6uBBrYA5RzBckBNUZcuXfoDlFiLbhNIMVDu/5UrV3pRDMKmCZtiRmRdIE1///5dBLR6I9Cmi0C5RiYmpj6gU4th6lA0gARhmoBMZnTFME0YNNC9EUB3N2NIAAUANU5evYYF+U4AAAAASUVORK5CYII="); }

select.select-arrow-dark {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAMAAAALbFwWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAM1BMVEUAAAAvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8AAAAr6/H9AAAAD3RSTlMAMgQ3Evete0Ba/I1+WIMOmTnpAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAADNJREFUCNdFyMcNACAMBMElZ9x/twgDwp/bMRjLOQc+xKMkGUpVJWn7pbqt6q+3fsOYOgszbAE1j++tNQAAAABJRU5ErkJggg=="); }

.form-control.small {
  padding: 0.8rem;
  font-size: 1.4rem;
  border-radius: 0.3rem; }

.form-control.big {
  padding: 1.2rem;
  font-size: 1.8rem;
  border-radius: 0.5rem; }

.form-group {
  position: relative;
  margin-bottom: 2rem;
  width: 100%; }
  .form-group.no-margin {
    margin-bottom: 0; }

.input-group {
  display: flex;
  width: 100%; }

.input-group-append {
  display: flex;
  align-items: center;
  justify-content: center; }

.input-icon {
  position: relative;
  width: 100%; }
  .input-icon.left .input-icon__icon-container {
    left: 0; }
  .input-icon.left .form-control {
    padding-left: 4rem; }
  .input-icon.right .input-icon__icon-container {
    right: 0; }
  .input-icon.right .form-control {
    padding-right: 4rem; }
    @media only screen and (min-width: 800px) {
      .input-icon.right .form-control {
        padding-right: 3.8rem; } }
  .input-icon .input-icon__icon-container {
    position: absolute;
    height: 100%;
    text-align: center;
    top: 0;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #66696c;
    font-size: 1.6rem;
    font-weight: 500;
    cursor: default; }

.input-group {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 0.4rem; }
  .input-group input.form-control {
    border-radius: 0;
    border: 0; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-content {
  padding-left: 1rem;
  padding-right: 1rem; }

.hidden-input {
  position: absolute;
  height: 0.1px;
  width: 0.1px;
  left: -9999px; }

.master-page-section {
  margin-bottom: 4rem; }

.master-page-title {
  font-size: 2.2rem;
  margin-bottom: 2.5rem; }

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  overflow: auto;
  transition: .2s;
  z-index: 30;
  opacity: 0;
  visibility: hidden; }
  .Modal.is-active {
    opacity: 1;
    visibility: visible; }
  .Modal.small .Modal-header {
    padding-bottom: 1.5rem; }
  .Modal.small .Modal-title {
    font-size: 1.8rem; }
  .Modal.small .Modal-close {
    width: 1.8rem; }
  .Modal.small .Modal-box {
    max-width: 40rem; }

.Modal .Modal-box {
  transition: .2s;
  transform: translate3d(0, -3rem, 0); }

.Modal.is-active .Modal-box {
  transform: translate3d(0, 0, 0); }

.Modal-bg {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 31; }

.Modal-box {
  background-color: #fff;
  position: relative;
  z-index: 32;
  padding: 2rem;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  max-width: 82rem;
  width: 100%; }

.Modal-header {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (min-width: 700px) {
    .Modal-header {
      padding-bottom: 2rem; } }

.Modal-title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2; }
  @media only screen and (min-width: 700px) {
    .Modal-title {
      font-size: 2.4rem; } }

.Modal-close {
  cursor: pointer;
  width: 1.8rem; }
  @media only screen and (min-width: 700px) {
    .Modal-close {
      width: 2rem; } }

.Modal-forgot-password,
.Modal-confirmation {
  text-align: center; }
  .Modal-forgot-password .Modal-content,
  .Modal-confirmation .Modal-content {
    margin: 2rem 0 0; }

.Modal-forgot-password__text,
.Modal-confirmation__text-container {
  margin-bottom: 3rem;
  line-height: 1.2;
  font-size: 1.6rem;
  color: #66696c;
  font-weight: 600; }

.PageTitle {
  color: #335180;
  font-size: 3rem;
  font-weight: 500; }
  @media only screen and (min-width: 800px) {
    .PageTitle {
      font-size: 3.5rem; } }

/* Base for label styling */
[type='radio']:not(:checked),
[type='radio']:checked {
  position: absolute;
  left: -9999px; }

[type='radio']:not(:checked) + label,
[type='radio']:checked + label {
  position: relative;
  padding-left: 2.4rem;
  cursor: pointer; }

/* checkbox aspect */
[type='radio']:not(:checked) + label::before,
[type='radio']:checked + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 50%;
  transition: 0.2s; }

/* checked mark aspect */
[type='radio']:not(:checked) + label::after,
[type='radio']:checked + label::after {
  content: '';
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgba(30, 136, 229, 0.8);
  transition: all 0.2s; }

/* checked mark aspect changes */
[type='radio']:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0.6); }

[type='radio']:checked + label::after {
  opacity: 1;
  transform: scale(1); }

[type='radio']:checked + label::before {
  border: 2px solid rgba(30, 136, 229, 0.6); }

/* disabled checkbox */
[type='radio']:disabled:not(:checked) + label::before,
[type='radio']:disabled:checked + label::before {
  border-color: #bbb;
  background-color: #ddd; }

[type='radio']:disabled:checked + label::after {
  color: #999; }

[type='radio']:disabled + label {
  color: #aaa; }

/* accessibility */
[type='radio']:checked:focus + label::before,
[type='radio']:not(:checked):focus + label::before {
  border: 2px solid rgba(30, 136, 229, 0.6);
  box-shadow: 0 0 0 3px rgba(30, 136, 229, 0.2); }

/* hover style just for information */
[type='radio'] + label:hover:before {
  border: 2px solid #1e88e5 !important; }

.RadioGroup {
  margin-bottom: 1.2rem;
  color: #66696c;
  font-size: 1.4rem;
  font-weight: 500; }
  .RadioGroup .label {
    line-height: 1.8rem; }

.RepasseTab .react-tabs__tab {
  border-radius: 0;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1;
  padding: 1.5rem 1.8rem !important;
  display: inline-flex !important;
  align-items: center; }

.RepasseTab .react-tabs__tab.selected {
  border-radius: 0;
  border-top: .4rem solid #d0021b;
  border-left: .1rem solid #ddd;
  border-right: .1rem solid #ddd;
  background-color: #fff; }

.RepasseTab .react-tabs__tab-list {
  border: .1rem solid #ddd !important;
  border-top: 0 !important;
  background-color: #ebebeb;
  margin-left: -.1rem !important;
  margin-right: -.1rem !important;
  box-shadow: inset 0 1px 0 #ddd; }

.react-tabs__tab.filtro-custom {
  width: 100%;
  text-align: center;
  font-size: 1.8rem; }
  .react-tabs__tab.filtro-custom.selected {
    background-color: #1e88e5;
    color: #fff; }

.responsive-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 !important;
  overflow: hidden; }
  .responsive-embed::before {
    content: "";
    display: block;
    padding-bottom: 100%; }

.responsive-embed-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border: 0;
  object-fit: cover; }

.Site {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.Site-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; }

@media only screen and (min-width: 800px) {
  .TitleWSelect {
    display: flex; } }

@media only screen and (min-width: 800px) {
  .TitleWSelect-title-container {
    width: calc(100% - 30rem); } }

@media only screen and (min-width: 800px) {
  .TitleWSelect-select-container {
    width: 30rem; } }

.padding {
  padding: 2rem; }
  .padding-vertical {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    .padding-vertical--smaller {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; }
    .padding-vertical--small {
      padding-top: 1rem;
      padding-bottom: 1rem; }
    .padding-vertical--big {
      padding-top: 4rem;
      padding-bottom: 4rem; }
    .padding-vertical--bigger {
      padding-top: 5rem;
      padding-bottom: 5rem; }
  .padding-horizontal {
    padding-left: 2rem;
    padding-right: 2rem; }
    .padding-horizontal--smaller {
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
    .padding-horizontal--small {
      padding-left: 1rem;
      padding-right: 1rem; }
    .padding-horizontal--big {
      padding-left: 4rem;
      padding-right: 4rem; }
    .padding-horizontal--bigger {
      padding-left: 5rem;
      padding-right: 5rem; }
  .padding-top {
    padding-top: 2rem; }
    .padding-top--smaller {
      padding-top: 0.5rem; }
    .padding-top--small {
      padding-top: 1rem; }
    .padding-top--big {
      padding-top: 4rem; }
    .padding-top--bigger {
      padding-top: 5rem; }
  .padding-bottom {
    padding-bottom: 2rem; }
    .padding-bottom--smaller {
      padding-bottom: 0.5rem; }
    .padding-bottom--small {
      padding-bottom: 1rem; }
    .padding-bottom--big {
      padding-bottom: 4rem; }
    .padding-bottom--bigger {
      padding-bottom: 5rem; }
  .padding-left {
    padding-left: 2rem; }
    .padding-left--smaller {
      padding-left: 0.5rem; }
    .padding-left--small {
      padding-left: 1rem; }
    .padding-left--big {
      padding-left: 4rem; }
    .padding-left--bigger {
      padding-left: 5rem; }
  .padding-right {
    padding-right: 2rem; }
    .padding-right--smaller {
      padding-right: 0.5rem; }
    .padding-right--small {
      padding-right: 1rem; }
    .padding-right--big {
      padding-right: 4rem; }
    .padding-right--bigger {
      padding-right: 5rem; }

.margin {
  margin: 2rem; }
  .margin-vertical {
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .margin-vertical--smaller {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; }
    .margin-vertical--small {
      margin-top: 1rem;
      margin-bottom: 1rem; }
    .margin-vertical--big {
      margin-top: 4rem;
      margin-bottom: 4rem; }
    .margin-vertical--bigger {
      margin-top: 5rem;
      margin-bottom: 5rem; }
  .margin-horizontal {
    margin-left: 2rem;
    margin-right: 2rem; }
    .margin-horizontal--smaller {
      margin-left: 0.5rem;
      margin-right: 0.5rem; }
    .margin-horizontal--small {
      margin-left: 1rem;
      margin-right: 1rem; }
    .margin-horizontal--big {
      margin-left: 4rem;
      margin-right: 4rem; }
    .margin-horizontal--bigger {
      margin-left: 5rem;
      margin-right: 5rem; }
  .margin-top {
    margin-top: 2rem; }
    .margin-top--smaller {
      margin-top: 0.5rem; }
    .margin-top--small {
      margin-top: 1rem; }
    .margin-top--big {
      margin-top: 4rem; }
    .margin-top--bigger {
      margin-top: 5rem; }
  .margin-bottom {
    margin-bottom: 2rem; }
    .margin-bottom--smaller {
      margin-bottom: 0.5rem; }
    .margin-bottom--small {
      margin-bottom: 1rem; }
    .margin-bottom--big {
      margin-bottom: 4rem; }
    .margin-bottom--bigger {
      margin-bottom: 5rem; }
  .margin-left {
    margin-left: 2rem; }
    .margin-left--smaller {
      margin-left: 0.5rem; }
    .margin-left--small {
      margin-left: 1rem; }
    .margin-left--big {
      margin-left: 4rem; }
    .margin-left--bigger {
      margin-left: 5rem; }
  .margin-right {
    margin-right: 2rem; }
    .margin-right--smaller {
      margin-right: 0.5rem; }
    .margin-right--small {
      margin-right: 1rem; }
    .margin-right--big {
      margin-right: 4rem; }
    .margin-right--bigger {
      margin-right: 5rem; }

.no-margin {
  margin: 0; }

.no-margin-vertical {
  margin-top: 0;
  margin-bottom: 0; }

.no-margin-horizontal {
  margin-left: 0;
  margin-right: 0; }

.no-margin-top {
  margin-top: 0; }

.no-margin-bottom {
  margin-bottom: 0; }

.no-margin-left {
  margin-left: 0; }

.no-margin-right {
  margin-right: 0; }

.no-padding {
  padding: 0; }

.no-padding-vertical {
  padding-top: 0;
  padding-bottom: 0; }

.no-padding-horizontal {
  padding-left: 0;
  padding-right: 0; }

.no-padding-top {
  padding-top: 0; }

.no-padding-bottom {
  padding-bottom: 0; }

.no-padding-left {
  padding-left: 0; }

.no-padding-right {
  padding-right: 0; }
