@import '../../tooling.sass';

.Favoritos-listing {
    .result__title {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        color: get-color(primary); }

    @include media(600px) {
        display: flex;
        flex-wrap: wrap; } }

.Favoritos-item {
    padding: 2rem 0;
    border-bottom: 1px solid #e8e8e8;

    @include media(600px, max) {
        width: calc(100% / 2); }

    @include media(1200px) {
        width: 100%;
        display: flex; }

    &:first-of-type {
        padding-top: 0; }

    &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0; }

    &:nth-of-type(odd) {
        @include media-range(600px, 1200px) {
            padding-right: 2rem;
            border-right: 1px solid #d8d8d8; } }

    &:nth-of-type(even) {
        @include media-range(600px, 1200px) {
            padding-left: 2rem; } } }

.Favoritos-image-container {
    @include media(1200px, max) {
        margin-bottom: 2rem; }

    @include media(1200px) {
        width: 11rem;
        height: 11rem;
        margin-right: 3rem; } }

.Favoritos-image {
    @include media(1200px) {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.Favoritos-info {
    @include media(1200px, max) {
        margin-bottom: 2rem; }

    @include media(1200px) {
        width: 20rem;
        margin-right: 3rem; } }


.Favoritos-status {
    @include media(1200px, max) {
        margin-bottom: 2rem; }

    @include media(1200px) {
        margin-right: 3rem;
        display: flex;
        flex-direction: column; }

    .StatusLabel {
        @include media(1200px) {
            margin-top: auto;
            margin-bottom: 1rem; } } }

.Favoritos-valores {
    @include media(1200px, max) {
        margin-bottom: 2rem; }

    @include media(1200px) {
        margin-left: auto;
        margin-right: 10rem;
        width: 20rem; } }

.Favoritos-desfavoritar {
    @include media(1200px) {
        margin-right: 3rem;

        display: flex;
        justify-content: center;
        align-items: center; }

    .desfavoritar-icon-container {
        color: get-color(blue);
        margin-bottom: 1.5rem;
        font-size: 2rem; }

    .desfavoritar-text {
        color: get-color(text-muted); }

    .button {
        @include media(1200px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; } } }
